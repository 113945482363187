var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sm-select",
    _vm._b(
      {
        ref: "select",
        staticClass: "select-container_tags",
        attrs: { value: _vm.value, preserveSearch: false },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event)
          },
          open: function($event) {
            return _vm.$emit("open")
          },
          close: function($event) {
            return _vm.$emit("close")
          }
        },
        nativeOn: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.delegateClick($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "option",
            fn: function(ref) {
              var option = ref.option
              return [
                option.id === -1
                  ? [
                      option.mode === "results"
                        ? _c(
                            "span",
                            {
                              staticClass: "loading-results create-with-name",
                              on: {
                                "!click": function($event) {
                                  return _vm.createTagWithName($event)
                                }
                              }
                            },
                            [
                              _vm._v("\n                Создать тег "),
                              _c("b", [_vm._v(_vm._s(_vm.searchTerm))])
                            ]
                          )
                        : _c("span", { staticClass: "loading-results" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  !_vm.isCreatingWithName
                                    ? _vm.__(
                                        "old-main.events-of-the-period.tag-adding-dialog.no-tag-found-item"
                                      )
                                    : ""
                                ) +
                                "\n                " +
                                _vm._s(
                                  _vm.__(
                                    "old-main.events-of-the-period.tag-adding-dialog.select-color-label"
                                  )
                                ) +
                                "\n            "
                            )
                          ])
                    ]
                  : option.isColor
                    ? _c("div", { staticClass: "color-wrapper" }, [
                        _c("span", { class: [option.tagClass] }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml-10" }, [
                          _vm._v(_vm._s(option.name))
                        ])
                      ])
                    : _c("div", { staticClass: "result-wrapper" }, [
                        _c("span", { class: [option.tagClass] }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "title" },
                          [
                            _c(
                              "highlight",
                              {
                                attrs: {
                                  queries: [_vm.searchTerm],
                                  "highlight-class": "mark"
                                }
                              },
                              [_vm._v(_vm._s(option.name))]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.allowCreate
                          ? _c("div", { staticClass: "actions" }, [
                              _c("i", {
                                staticClass: "star",
                                class: [
                                  option.isFavourite === true
                                    ? "selected"
                                    : "unselected"
                                ],
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.onTogglingCheckFavourite(option)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "refCount" }, [
                                _vm._v(_vm._s(option.refCount))
                              ])
                            ])
                          : _vm._e()
                      ])
              ]
            }
          },
          {
            key: "tag",
            fn: function(ref) {
              var option = ref.option
              var remove = ref.remove
              return [
                _c("span", { staticClass: "multiselect__tag" }, [
                  _c("span", { class: [option.tagClass] }, [
                    _vm._v(_vm._s(option.name))
                  ]),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "multiselect__tag-icon",
                    on: {
                      click: function($event) {
                        return remove(option)
                      }
                    }
                  })
                ])
              ]
            }
          }
        ])
      },
      "sm-select",
      _vm.options,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f8cacd68", { render: render, staticRenderFns: staticRenderFns })
  }
}