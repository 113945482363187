import hello from 'src/js/lib/hello.js';
import {User} from 'src/js/services/userService.js';

function getAuthority() {
    if (location.hostname !== 'localhost') {
        return location.origin;
    }
    return `${
        /(http|https)/.test(sm.authData.authority) ? sm.authData.authority : location.protocol + sm.authData.authority
    }`;
}

function createSearchGrantRegex(str) {
    const result = str.replace('.', '\\.').replace('*', '.*');
    return new RegExp(`^${result}$`);
}

function canReadMainMenuItem(grants, userGrants) {
    for (const grant of grants) {
        const search = createSearchGrantRegex(grant);
        const result = userGrants.some((userGrant) => userGrant.match(search));
        if (result) return true;
    }
    return false;
}

var config = {
    authority: getAuthority(),
    client_id: `${sm.authData.client_id === 'smon-ui-tneft' ? 'smon-ui-box' : sm.authData.client_id}`,
    redirect_uri: `${location.origin}/callback.html`,
    response_type: 'id_token token',
    scope: 'openid profile read write',
    post_logout_redirect_uri: `${location.origin}/index.html`
};

var openIdConfig = {};

function getNonce() {
    var guidHolder = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx';
    var hex = '0123456789abcdef';
    var r = 0;
    var guidResponse = '';
    for (var i = 0; i < guidHolder.length; i++) {
        if (guidHolder[i] !== '-' && guidHolder[i] !== '4') {
            // each x and y needs to be random
            r = (Math.random() * 16) | 0;
        }

        if (guidHolder[i] === 'x') {
            guidResponse += hex[r];
        } else if (guidHolder[i] === 'y') {
            // clock-seq-and-reserved first hex is filtered and remaining hex values are random
            r &= 0x3; // bit and with 0011 to set pos 2 to zero ?0??
            r |= 0x8; // set pos 3 to 1 as 1???
            guidResponse += hex[r];
        } else {
            guidResponse += guidHolder[i];
        }
    }
    return guidResponse;
}

function createProvider() {
    hello.init({
        sm: {
            name: 'sm',
            oauth: {
                version: 2,
                auth: openIdConfig.login,
                grant: openIdConfig.token,
                response_type: config.response_type
            },
            login(p) {
                p.timeout = 3000;

                p.qs.nonce = getNonce();
                p.qs.redirect_uri = encodeURIComponent(config.redirect_uri);
                p.qs.scope = encodeURIComponent(config.scope);
                p.qs.state.real_redirect_uri = location.href;
            }
        }
    });

    hello.init({
        sm: config.client_id
    });

    return hello('sm');
}

function hardcodeLoginPage() {
    openIdConfig.login = `${config.authority}/connect/authorize`;
    var provider = createProvider();
    provider.login({
        display: 'page'
    });
}

export default class Auth {
    constructor(options) {
        if (Auth._instance) {
            return Auth._instance;
        }
        Auth._instance = this;
    }

    init() {
        const self = this;
        return new $.Deferred(function(dfd) {
            $.ajax({
                url: `${config.authority}/.well-known/openid-configuration`
            })
                .done(function(data) {
                    self.prepareOpenIdConfig(data);
                    self.provider = createProvider();
                    self.initSession(dfd);
                    self.enableActualizeSession();
                })
                .fail(function() {
                    hardcodeLoginPage();
                });
        }).then(function(data) {
            sm.systemUser = new SystemUser({
                lang: sm.systemUser.lang,
                fullName: data.fullName,
                id: +data.sub,
                photo: data.picture
            });
            Vue.set(Vue.prototype.sm, 'systemUser', sm.systemUser);
            return sm.systemUser.fetchGrants();
        });
    }

    enableActualizeSession() {
        this.timerId = setInterval(() => this.actualizeSession(), 10000);
    }

    disableActualizeSession() {
        clearInterval(this.timerId);
    }

    prepareOpenIdConfig(data) {
        openIdConfig.userinfo = data.userinfo_endpoint;
        openIdConfig.logout = data.end_session_endpoint;
        openIdConfig.login = data.authorization_endpoint;
        openIdConfig.token = data.token_endpoint;
    }

    initSession(dfd) {
        var self = this;
        this.errorsInSequence = 0;
        if (this.provider.getAuthResponse() != null) {
            this.actualizeSession().done(function() {
                $.ajax({
                    url: openIdConfig.userinfo
                })
                    .done(function(user) {
                        dfd.resolve(user);
                    })
                    .fail(function() {
                        self.provider.login({
                            display: 'page'
                        });
                    });
            });
        } else {
            this.provider.login({
                display: 'page'
            });
        }
    }

    isSessionActual() {
        var session = this.provider.getAuthResponse();
        var result = false;
        if (session && session.expires * 1000 - 60000 > Date.now()) {
            result = true;
        }
        return result;
    }

    actualizeSession(isForce) {
        var self = this;
        var isSessionActual = this.isSessionActual();

        var args = [].slice.call(arguments, 0);
        return $
            .when(
                isForce === true || isSessionActual === false
                    ? this.provider.login({
                          display: 'none',
                          force: true
                      })
                    : true
            )
            .done(function() {
                self.errorsInSequence = 0;

                var response = self.provider.getAuthResponse();
                sm.token = response.access_token;
            })
            .fail(function(response) {
                var max = 3;
                self.errorsInSequence++;
                console.warn('error actualizeSession try to retry', self.errorsInSequence, arguments);

                // provider.login({display: 'none'}) когда клиенту выдается форма авторизации, не триггерит ни done, ни fail,
                // monkey patch hello.all.js на timeout исправляет ситуацию
                if (response.error.code === 'timeout' || self.errorsInSequence > max) {
                    return self.provider.login({
                        display: 'page'
                    });
                }

                if (self.errorsInSequence === max) {
                    self.disableActualizeSession();
                    return self.createAuthPopup().then(
                        function() {
                            self.actualizeSession(...args);
                            self.enableActualizeSession();
                        },
                        function() {
                            // иногда при закрытии пользователем попапа авторизации попадаем сюда
                            self.provider.login({
                                display: 'page'
                            });
                        }
                    );
                }

                sm.utils.showWarning({
                    message: `${
                        self.errorsInSequence === 1
                            ? 'Сессия истекла, автоматически запрошена новая'
                            : 'Не удалось получить сессию, попытка еще раз'
                    }`
                });
                return self.actualizeSession(...args);
            });
    }

    createAuthPopup() {
        var self = this;
        sm.utils.showError({
            message: 'Сессия истекла, пожалуйста, авторизуйтесь'
        });
        $('body').append(
            '<div class="hellojs-auth-overlay fancybox-overlay fancybox-overlay-fixed display-block"></div>'
        );
        $('.i-am-new').on('click.createAuthPopup', () => false);

        $(window).on('unload.hellojsPopup', function() {
            // при выгрузке окна закрываем окно попапа, если окно попапа еще не закрыто
            if (window.hellojsLoginPopup) {
                window.hellojsLoginPopup.close();
            }
        });
        return this.showAuthPopup();
    }

    showAuthPopup() {
        var self = this;
        return this.provider
            .login({
                display: 'popup'
            })
            .then(
                function() {
                    $('.hellojs-auth-overlay').remove();
                    $.noty.closeAll();
                    $(window).off('.hellojsPopup');
                    $('.i-am-new').off('click.createAuthPopup');
                },
                function() {
                    self.provider.login({
                        display: 'page'
                    });
                }
            );
    }
}

// @see http://stackoverflow.com/questions/11793430/retry-a-jquery-ajax-request-which-has-callbacks-attached-to-its-deferred
$.ajaxPrefilter(function(options, originalOptions, jqXHR) {
    if (_.get(originalOptions, 'headers.Authorization') == null) {
        options.headers = {};
        $.extend(options.headers, originalOptions.headers, {
            Authorization: `Bearer ${sm.token}`
        });
    }

    var dfd = $.Deferred();
    jqXHR.done(dfd.resolve).fail(function() {
        var args = Array.prototype.slice.call(arguments);
        if (jqXHR.status === 401) {
            if (Auth._instance && Auth._instance.provider) {
                if (window.hellojsLoginPopup == null) {
                    if (options.unauthorizedRetry === true) {
                        hardcodeLoginPage();
                    }
                    Auth._instance.disableActualizeSession();
                    return Auth._instance.actualizeSession(true).done(function() {
                        Auth._instance.enableActualizeSession();
                        var newOpts = $.extend({}, originalOptions, {
                            unauthorizedRetry: true
                        });
                        $.ajax(newOpts).then(dfd.resolve, dfd.reject);
                    });
                }
            }
        } else if (jqXHR.status === 400 && jqXHR.responseJSON) {
            var keys = _.keys(jqXHR.responseJSON.bodyFields);
            if (keys.length) {
                sm.utils.showError(jqXHR.responseJSON.bodyFields[keys[0]][0]);
            } else if (jqXHR.responseJSON.message) {
                sm.utils.showError(jqXHR.responseJSON.message);
            }
        } else if (jqXHR.status === 403) {
            sm.utils.showError(__('forbidden'));
        } else if (jqXHR.status >= 500) {
            sm.utils.showError(__('serverInternalError'));
        }
        dfd.rejectWith(jqXHR, args);
    });

    return dfd.promise(jqXHR);
});

class SystemUser extends User {
    constructor() {
        super(...arguments);
        var self = this;
        _.bindAll(self, 'onLogout');

        var descriptor = {
            data: _.merge(this, {packets: []}),
            methods: {
                fetchGrants() {
                    return $
                        .ajax(`api/webui/pl/v1/user-grants/users/${this.id}/packets`)
                        .then((packets) => (this.packets = packets));
                },

                hasGrant(name, wgId, adminGrant = 'pl.admins.user-entities-write') {
                    if (name === 'pl.admins.*') {
                        const userGrants = this.packets.map((x) => x.grants).reduce((arr, val) => arr.concat(val), []);
                        return canReadMainMenuItem([name], userGrants);
                    }

                    var filterAndFlatten = (name) => {
                        return _.flatten(
                            _.map(
                                _.filter(this.packets, (packet) => {
                                    return _.includes(packet.grants, name);
                                }),
                                'owners'
                            )
                        );
                    };

                    if (
                        filterAndFlatten('cloud-management.grants-meta.write').length ||
                        (adminGrant && adminGrant !== '' && filterAndFlatten(adminGrant).length)
                    ) {
                        return true;
                    }
                    var result = !!_.find(filterAndFlatten(name), function(owner) {
                        if (!wgId) {
                            return true;
                        }
                        return owner.workGroupId === wgId || _.includes(owner.openWorkGroups, wgId);
                    });
                    if (!result && _.endsWith(name, 'read')) {
                        return this.hasGrant(name.replace(/read$/, 'write'), wgId);
                    }
                    return result;
                },
                hasGrantForConnector(grant, connector) {
                    if (!connector.grants.length) {
                        return false;
                    }
                    var grants = _.reduce(
                        'basic-read read write'.split(' '),
                        (memo, val, index) => {
                            memo[val] = index;
                            return memo;
                        },
                        {}
                    );
                    return grants[grant] <= grants[connector.grants[0]];
                }
            }
        };
        var vm = new Vue(descriptor);
        _.each(_.keys(descriptor.methods), (name) => {
            this[name] = vm[name];
        });
    }

    onLogout() {
        var provider = hello('sm');
        var session = provider.getAuthResponse();
        if (!session) {
            return;
        }
        provider
            .logout({
                force: true
            })
            .then(function() {
                sm.utils.deleteCookie('sm_auth'); // FMONQ-169 to logout new front
                window.location = `${openIdConfig.logout}?id_token_hint=${session.id_token}&post_logout_redirect_uri=${
                    config.post_logout_redirect_uri
                }`;
                window.sessionStorage.clear();
            });
    }
}
