var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "select-container",
      class: {
        "select-container—plural-label":
          "pluralLabelMinimumSelectionLength" in _vm.$options.propsData
      }
    },
    [
      _c(
        "multiselect",
        _vm._b(
          {
            ref: "multiselect",
            attrs: {
              value: _vm.translatedValue,
              options: _vm.orderedData,
              disabled: _vm.disabled
            },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event)
              },
              open: function($event) {
                return _vm.$emit("open")
              },
              close: function($event) {
                return _vm.$emit("close")
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "selection",
                  fn: function(ref) {
                    var values = ref.values
                    var search = ref.search
                    var isOpen = ref.isOpen
                    return [
                      values.length > _vm.pluralLabelMinimumSelectionLength &&
                      !isOpen
                        ? _c("span", { staticClass: "multiselect__single" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.sm.utils.pluralize(
                                    values.length,
                                    _vm.selectionPluralCases || {
                                      nom: "выбранный элемент",
                                      gen: "выбранных элемента",
                                      plu: "выбранных элементов"
                                    }
                                  )
                                ) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "option",
                  fn: function(props) {
                    return [_vm._t("option", null, null, props)]
                  }
                },
                {
                  key: "singleLabel",
                  fn: function(props) {
                    return [_vm._t("singleLabel", null, null, props)]
                  }
                },
                {
                  key: "tag",
                  fn: function(props) {
                    return [_vm._t("tag", null, null, props)]
                  }
                }
              ],
              null,
              true
            )
          },
          "multiselect",
          _vm.config,
          false
        ),
        [
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _c("span", { attrs: { slot: "noOptions" }, slot: "noOptions" }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.__(
                    "old-main.events-of-the-period.tag-adding-dialog.no-tag-found-item"
                  )
                ) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.__(
                    "old-main.events-of-the-period.tag-adding-dialog.no-tag-found-item"
                  )
                ) +
                "\n        "
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4c289ea8", { render: render, staticRenderFns: staticRenderFns })
  }
}