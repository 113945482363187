<template>
    <div class="select-container" :class="{'select-container—plural-label': 'pluralLabelMinimumSelectionLength' in $options.propsData}">
        <multiselect ref="multiselect" :value="translatedValue" @input="$emit('input', $event)" @open="$emit('open')" @close="$emit('close')" :options="orderedData" v-bind="config" :disabled="disabled">
            <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span class="multiselect__single" v-if="(values.length > pluralLabelMinimumSelectionLength) && !isOpen">
                    {{
                        sm.utils.pluralize(values.length, selectionPluralCases || {
                                    nom: 'выбранный элемент',
                                    gen: 'выбранных элемента',
                                    plu: 'выбранных элементов'
                                })
                    }}
                </span>
            </template>
            <template slot="option" slot-scope="props">
                <slot name="option" v-bind="props"/>
            </template>
            <template slot="singleLabel" slot-scope="props">
                <slot name="singleLabel" v-bind="props"/>
            </template>

            <!-- TODO при открытом списке не получается удалить через крестик -->
            <template slot="tag" slot-scope="props">
                <slot name="tag" v-bind="props"/>
            </template>

            <span slot="noOptions">
                {{ __('old-main.events-of-the-period.tag-adding-dialog.no-tag-found-item') }}
            </span>
            <span slot="noResult">
                {{ __('old-main.events-of-the-period.tag-adding-dialog.no-tag-found-item') }}
            </span>
        </multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

function immediateSearch(term) {
    if (!this.ajax) {
        return;
    }
    var diff = term.length - this.minimumInputLength;
    if (diff < 0) {
        this.orderedData = [
            {
                [this.dataId]: -1,
                [this.dataText]: `Пожалуйста, введите еще хотя бы ${sm.utils.pluralize(-diff, {
                    nom: 'символ',
                    gen: 'символа',
                    plu: 'символов'
                })}`,
                $isDisabled: true
            }
        ];
        return;
    }
    this.config.loading = true;
    return (_.isFunction(this.ajax.transport)
        ? this.ajax.transport({searchTerm: term})
        : $.ajax({
              ...this.ajax,
              url: _.isFunction(this.ajax.url) ? this.ajax.url() : this.ajax.url,
              data: (this.ajax.data ||
                  ((params) => ({
                      search: params.term
                  })))({term})
          })
    )
        .always(() => {
            this.config.loading = false;
        })
        .then(
            (data) => {
                this.orderedData = (this.ajax.processResults || _.identity)(data, {term});
                this.refreshOrderedData();
                if (!this.ajax.cache === true) {
                    this.bindSearchOnOpenOnce();
                }
            },
            () => {
                this.bindSearchOnOpenOnce();
            }
        );
}

export default Vue.component('SmSelect', {
    components: {
        Multiselect
    },
    props: {
        /* eslint-disable vue/require-prop-types */
        value: {},
        /* eslint-enable vue/require-prop-types */
        data: {
            type: Array
        },
        multiple: {
            type: Boolean,
            default: true
        },
        searchable: {
            type: Boolean,
            default: true
        },
        dataId: {
            type: String,
            default: 'id'
        },
        dataText: {
            type: String,
            default: 'name'
        },
        placeholder: {
            type: String,
            default: ''
        },
        selectionPluralCases: {
            type: Object
        },
        pluralLabelMinimumSelectionLength: {
            type: Number,
            default: Math.Infinity
        },
        ajax: {
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false
        },
        allowEmpty: {
            type: Boolean
        },
        hideSelected: {
            type: Boolean
        },
        minimumInputLength: {
            type: Number
        },
        label: {
            type: String,
            default: null
        },
        preserveSearch: {
            type: Boolean,
            default: true
        },
        clearOnSelect: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            orderedData: [],
            config: {
                multiple: this.multiple,
                'allow-empty': 'allowEmpty' in this.$options.propsData ? this.allowEmpty : this.multiple,
                'close-on-select': !this.multiple,
                'clear-on-select': this.clearOnSelect,
                'show-labels': this.multiple,
                'preserve-search': this.preserveSearch,
                placeholder: this.placeholder,
                'preselect-first': false,
                'track-by': this.dataId,
                label: this.dataText,
                searchable: this.searchable,
                hideSelected: this.hideSelected,
                loading: false,
                'internal-search': !this.ajax,
                selectedLabel: 'Выбран',
                selectLabel: 'Выбрать',
                selectGroupLabel: 'Выбрать',
                deselectLabel: 'Удалить',
                deselectGroupLabel: 'Удалить'
            }
        };
    },

    computed: {
        translatedValue() {
            if (sm.systemUser.lang.get() === 'ru') return this.value;
            if (this.value && this.value.name === 'По умолчанию')
                return {...this.value, name: __('common.ci.type.Default')};
            return this.value;
        }
    },

    watch: {
        data: {
            handler(val) {
                this.orderedData = val ? _.cloneDeep(val) : [];
            },
            immediate: true
        }
    },
    mounted() {
        var select = this.$children[0];
        if (this.ajax) {
            // при первом открытии не генерится search-change
            this.bindSearchOnOpenOnce();

            select.$on('search-change', this.search);
        } else {
            if (this.multiple) {
                select.$on('open', this.refreshOrderedData);
            }
        }
        select.$on('input', () => {
            this.$nextTick(this.refreshOrderedData);
        });
    },
    destroyed() {
        if (this.ajax) {
            this.$children[0].$off('search-change', this.search);
        }
    },
    methods: {
        clearAjaxData() {
            if (this.ajax) {
                this.orderedData = [];
                this.bindSearchOnOpenOnce();
            }
        },
        bindSearchOnOpenOnce() {
            var select = this.$children[0];
            select.$once('open', () => {
                this.immediateSearch('');
            });
        },

        // @see https://github.com/shentao/vue-multiselect/issues/618
        repositionDropDown() {
            // var { top, height } = this.$el.getBoundingClientRect();
            // var ref = this.$children[0];
            // ref.$refs.list.style.width = `${this.$el.clientWidth}px`;
            // ref.$refs.list.style.position = 'fixed';
            // ref.$refs.list.style.bottom = 'auto';
            // ref.$refs.list.style.top = `${top + height}px`;
        },

        // самописный флаг isService для элемента необходим в случае, когда элемент надо отобразить в начале списка,
        // например, как подсказку "Создать тег"
        refreshOrderedData() {
            var texts = _.map(this.translatedValue, this.dataText);
            this.orderedData = _.orderBy(
                this.orderedData,
                ['isService', (elem) => _.includes(texts, elem[this.dataText])],
                ['asc', 'desc']
            );
        },

        clearSearch() {
            this.$refs.multiselect.search = '';
        },

        close() {
            this.$refs.multiselect.deactivate();
        },

        immediateSearch,
        search: _.debounce(immediateSearch, sm.constants.onTypingTimeout)
    }
});
</script>
<style lang="less">
.material-icons {
    display: inline-block;
    white-space: nowrap;
    letter-spacing: normal;
    text-transform: none;
    word-wrap: normal;
    font-family: 'Material Icons', sans-serif;
    font-size: 18px; /* Preferred icon size */
    font-weight: 400;
    font-style: normal;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;

    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.clearable {
    .select-container {
        .multiselect__tags {
            border-radius: 3px 0 0 3px;
        }

        & + .input-group-addon {
            border-radius: 0 3px 3px 0;
            height: 36px;
            margin-left: 0;
        }
    }
}

.select-container {
    position: relative;
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;

    .multiselect {
        min-height: 36px;
        order: 2;

        &.multiselect--active {
            .multiselect__tags {
                border-color: #2196f3;
            }

            &:not(.multiselect--above) {
                .multiselect__tags {
                    border-botom-left-radius: 0;
                    border-botom-right-radius: 0;
                }
            }

            &.multiselect--above {
                .multiselect__tags {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            }

            & + .select-container__label {
                color: #2196f3;
            }

            .multiselect__input {
                z-index: 1;
                min-height: 34px;
            }

            .multiselect__select {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }

    .multiselect__select {
        height: 36px;
        top: 50%;
        transform: translateY(-50%);

        &:before {
            .material-icons;
            content: 'keyboard_arrow_down';
            border: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin: 0;
            z-index: 1;
        }
    }

    .multiselect__tag-icon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &:after {
            color: rgba(0, 0, 0, 0.25);
        }

        &:hover {
            background-color: transparent;

            &:after {
                color: rgba(0, 0, 0, 0.7);
            }
        }
    }

    .multiselect__input {
        margin-bottom: 0;
        font-size: 13px;
        padding-left: 12px;
        width: calc(100% ~'-' 32px) !important;
        z-index: -1;
        background-color: transparent;
        align-self: center;
    }

    .multiselect__tags {
        padding: 0;
        padding-right: 32px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #d7dce8;
        border-radius: 3px;
        min-height: 36px;
        padding-left: 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .multiselect__tags-wrap {
        padding-left: 3px;
        padding-top: 3px;
        padding-bottom: 0;
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        max-height: 90px;
        overflow: auto;
    }

    .multiselect--disabled {
        .multiselect__tags-wrap {
            max-height: unset;
        }
    }

    .manage-triggers & .multiselect--disabled {
        .multiselect__tags-wrap {
            max-height: 90px;
        }
    }

    .multiselect__tag {
        position: relative;
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        color: #6b748b;
        background-color: #eff1f6;
        border-radius: 3px;
        margin-right: 3px;
        margin-top: 0;
        margin-bottom: 0;
        padding: 7px 12px;
        padding-right: 24px;
        line-height: 13px;
        font-size: 13px;
        margin-bottom: 3px;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        & > span {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .multiselect__placeholder {
        align-self: center;
        margin-bottom: 0;
        padding-top: 0;
        white-space: nowrap;
        font-size: 13px;
    }

    .multiselect__element {
        white-space: normal;
        word-break: break-word;

        & > span {
            white-space: normal;
            line-height: 1;
        }
    }

    .multiselect--disabled {
        background-color: transparent;
        .multiselect__select {
            display: none;
        }
        .multiselect__tags {
            background-color: #fafafa;
            padding-right: 3px;
        }

        .multiselect__single {
            background-color: transparent;
        }
        .multiselect__tag-icon {
            display: none;
        }
        .multiselect__tag {
            padding-right: 12px;
        }

        .multiselect__input {
            opacity: 0;
        }
    }

    .multiselect__single {
        align-self: center;
        padding-bottom: 0;
        padding-left: 12px;
        white-space: nowrap;
        height: 100%;
        max-width: 100%;
        flex-grow: 0;
        overflow: hidden;
        text-overflow: ellipsis;

        & > span {
            white-space: nowrap;
            max-width: 100%;
            flex-grow: 0;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .multiselect__single,
    .multiselect__option {
        font-size: 13px;
        margin-bottom: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .material-icons {
            padding-right: 4px;
        }
    }

    .multiselect__option {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        line-height: 16px;
        font-size: 13px;
        font-weight: 400;

        &::after {
            // background: #f3f3f3;
            font-size: 10px;
            padding-left: 12px;
        }

        &:not(:hover):not(.multiselect__option--highlight) {
            &::after {
                background: #f3f3f3;
            }
        }

        &.multiselect__option--highlight {
            &::after {
                font-size: 10px;
            }
        }
    }

    .multiselect__placeholder {
        padding-left: 12px;
    }

    .multiselect__option--highlight:after {
        font-size: 12px;
    }

    .multiselect--active,
    .multiselect__content-wrapper {
        z-index: 999;
    }

    .multiselect--above .multiselect__content-wrapper {
        border: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.1);
        max-height: 152px !important;
    }

    .multiselect__spinner {
        right: 1px;
        top: 1px;
        width: 42px;
        height: 34px;
        z-index: 2;

        .datatable-header__control & {
            height: 40px;
            background: transparent;
        }
    }

    .multiselect--disabled .multiselect__current,
    edit-action-rule-form__main .multiselect--disabled .multiselect__select {
        background-color: transparent;
    }

    .config-item-type-icon,
    [class^='icon-'],
    [class*=' icon-'] {
        font-size: 15px;
        margin-right: 4px;
        line-height: 13px;
    }
}

.select-container__label {
    position: relative;
    color: #9ea1a8;
    padding-right: 8px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 0;
    order: 1;
}
</style>
