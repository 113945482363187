var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("table", {
        ref: "table",
        staticClass: "table table-hover",
        class: [_vm.cls]
      }),
      _vm._v(" "),
      _c(
        "div",
        { ref: "headerSlotWrapper", staticClass: "header hidden" },
        [_vm._t("header")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "footerSlotWrapper", staticClass: "footer hidden" },
        [_vm._t("footer")],
        2
      ),
      _vm._v(" "),
      _c(
        "portal",
        { attrs: { to: _vm.getStdBtnUid("export") } },
        [
          _c(
            "sm-datatable-btn",
            {
              staticClass: "dropdown",
              attrs: { type: { cls: "download" }, disabled: _vm.loading }
            },
            [
              _c(
                "sm-dropdown",
                {
                  attrs: {
                    slot: "custom-content",
                    "external-parent": true,
                    title: _vm.__("old-main.events-of-the-period.export-button")
                  },
                  slot: "custom-content"
                },
                [
                  _c("ul", [
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "export-proxy-btn",
                          attrs: { href: "#", "data-type": "csv" }
                        },
                        [_vm._v("CSV")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "export-proxy-btn",
                          attrs: { href: "#", "data-type": "excel" }
                        },
                        [_vm._v("Excel")]
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("portal", { attrs: { to: _vm.getStdBtnUid("shortPaging") } }, [
        _vm.pages >= 2
          ? _c(
              "div",
              {
                staticClass: "table-paging table-paging_short",
                attrs: { disabled: _vm.loading }
              },
              [
                _c(
                  "a",
                  {
                    staticClass:
                      "table-paging__button table-paging__button_previous",
                    class: { "table-paging__button_available": _vm.page > 0 },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.page--
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("navigate_before")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    staticClass: "table-paging__switch-page",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.loadPageUsingShortPaging($event)
                      }
                    }
                  },
                  [
                    _c("sm-number", {
                      ref: "shortPagingInput",
                      attrs: {
                        value: _vm.page + 1,
                        options: { minimumValue: 1, maximumValue: _vm.pages }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "table-paging__text" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.__("common.lists.pagination.out-of-label")) +
                      " " +
                      _vm._s(_vm.pages)
                  )
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "table-paging__button table-paging__button_next",
                    class: {
                      "table-paging__button_available": _vm.page < _vm.pages - 1
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.page++
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("navigate_next")
                    ])
                  ]
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("portal", { attrs: { to: _vm.getStdBtnUid("paging") } }, [
        _vm.pages >= 2
          ? _c(
              "div",
              {
                staticClass: "table-paging dataTables_paginate paging_simple",
                attrs: { disabled: _vm.loading }
              },
              [
                _c(
                  "a",
                  {
                    staticClass:
                      "table-paging__button table-paging__button_previous",
                    class: { "table-paging__button_available": _vm.page > 0 },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.page--
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("navigate_before")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "table-paging__text" }, [
                  _vm._v(_vm._s(_vm.__("common.lists.pagination.pages-label")))
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    staticClass: "switch-page",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.loadPageUsingFooterPaging($event)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "input-group input-group-xs" },
                      [
                        _c("sm-number", {
                          ref: "footerPagingInput",
                          staticClass: "current-page form-control",
                          attrs: {
                            value: _vm.page + 1,
                            options: {
                              minimumValue: 1,
                              maximumValue: _vm.pages
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group-btn" }, [
                          _c(
                            "button",
                            {
                              staticClass: "footer-paging__btn",
                              attrs: {
                                type: "submit",
                                title: _vm.__(
                                  "common.lists.pagination.enter-page-button"
                                )
                              }
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("arrow_forward")
                              ])
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "table-paging__text" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.__("common.lists.pagination.out-of-label")) +
                      " " +
                      _vm._s(_vm.pages)
                  )
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "table-paging__button table-paging__button_next",
                    class: {
                      "table-paging__button_available": _vm.page < _vm.pages - 1
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.page++
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("navigate_next")
                    ])
                  ]
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("portal", { attrs: { to: _vm.getStdBtnUid("length") } }, [
        _vm.length && _vm.opts.paging
          ? _c(
              "div",
              {
                staticClass: "datatable-footer__length",
                attrs: { disabled: _vm.loading }
              },
              [
                _c(
                  "label",
                  {
                    class: { "no-length-menu": !_vm.isAnyLengthMenuItemVisible }
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.__(
                              "common.lists.pagination.element-count-label"
                            )
                          ) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _vm.isAnyLengthMenuItemVisible
                      ? _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.length,
                                expression: "length"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { size: _vm.opts.lengthMenu[0].length },
                            on: {
                              change: [
                                function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.length = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function($event) {
                                  _vm.dt.page.len(_vm.length).draw()
                                }
                              ]
                            }
                          },
                          _vm._l(_vm.opts.lengthMenu[0], function(
                            lengthElem,
                            index
                          ) {
                            return _c(
                              "option",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isLengthMenuItemVisible(
                                      lengthElem
                                    ),
                                    expression:
                                      "isLengthMenuItemVisible(lengthElem)"
                                  }
                                ],
                                domProps: { value: lengthElem }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.opts.lengthMenu[1][index]) +
                                    "\n                    "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "table-paging__text" }, [
                  _vm.isAnyLengthMenuItemVisible
                    ? _c("span", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.__("common.lists.pagination.out-of-label")
                            ) +
                            "\n                "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.recordsDisplay) +
                      " " +
                      _vm._s(
                        _vm.recordsDisplay === _vm.recordsTotal
                          ? ""
                          : _vm
                              .__("common.lists.pagination.filtered-out-label")
                              .replace(" )", " " + _vm.recordsTotal + ")")
                      ) +
                      "\n            "
                  )
                ])
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3812c968", { render: render, staticRenderFns: staticRenderFns })
  }
}