<template>
    <sm-select
        class="select-container_tags"
        ref="select"
        :value="value"
        @input="$emit('input', $event)"
        @open="$emit('open')"
        @close="$emit('close')"
        v-bind="options"
        @click.native.stop="delegateClick"
        :preserveSearch="false"
        >
        <template slot="option" slot-scope="{option}">
            <template v-if="option.id === -1">
                <span v-if="option.mode === 'results'" class="loading-results create-with-name" @click.capture="createTagWithName">
                    Создать тег <b>{{ searchTerm }}</b>
                </span>
                <span v-else class="loading-results">
                    {{ !isCreatingWithName ? __('old-main.events-of-the-period.tag-adding-dialog.no-tag-found-item') : '' }}
                    {{ __('old-main.events-of-the-period.tag-adding-dialog.select-color-label') }}
                </span>
            </template>
            <div class="color-wrapper" v-else-if="option.isColor">
                <span :class="[option.tagClass]"/>
                <span class="ml-10">{{ option.name }}</span>
            </div>
            <div class="result-wrapper" v-else>
                <span :class="[option.tagClass]"/>
                <span class="title">
                    <highlight :queries="[searchTerm]" highlight-class="mark">{{ option.name }}</highlight>
                </span>
                <div class="actions" v-if="allowCreate">
                    <i class="star" :class="[option.isFavourite === true ? 'selected' : 'unselected']" @click.stop="onTogglingCheckFavourite(option)"></i>
                    <span class="refCount">{{ option.refCount }}</span>
                </div>
            </div>
        </template>
        <template slot="tag" slot-scope="{ option, remove }">
            <span class="multiselect__tag">
                <span :class="[option.tagClass]">{{ option.name }}</span>
                <i class="multiselect__tag-icon" @click="remove(option)"/>
            </span>
        </template>
    </sm-select>
</template>

<script>
import {tagService, EventTagService, UserFavouriteTagService, Tag} from 'src/js/services/eventTagService.js';
import TextHighlight from 'vue-text-highlight';

export default {
    components: {
        highlight: TextHighlight
    },
    props: {
        event: {
            type: Object
        },
        value: {
            type: Array,
            required: true
        },
        slaTemplate: {
            type: Object
        },
        searchParams: {
            type: Object
        },
        allowCreate: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String
        },
        filterTags: {
            type: Function,
            default: _.identity
        }
    },
    data() {
        return {
            userFavouriteTagService: new UserFavouriteTagService({userId: sm.systemUser.id}),
            eventTagService: new EventTagService(),
            options: this.getSelectOpts(),
            searchTerm: ''
        };
    },
    created() {
        _.each(this.value, (tag, index) => {
            if (!(tag instanceof Tag)) {
                this.value[index] = tagService.make(tag);
            }
        });
    },
    mounted() {
        this.$nextTick(this.addEventHandlers);
    },
    methods: {
        delegateClick(e) {
            if (sm.utils.childOrSelfIs(e.target, '.create-with-name')) {
                this.createTagWithName();
            }
        },

        getSelect() {
            return this.$refs.select.$children[0];
        },

        createTagWithName() {
            if (!this.allowCreate) {
                return;
            }
            this.isCreatingWithName = true;
            this.isManualReloading = true;
            this.$refs.select.immediateSearch(this.searchTerm);
            this.isManualReloading = false;
        },

        onTogglingCheckFavourite(tag) {
            if (!tag.isFavourite) {
                this.userFavouriteTagService
                    .create(tag)
                    .done(() => {
                        sm.utils.showSuccess(`Тег <b>${tag.name}</b> добавлен в избранные`);
                        tag.isFavourite = true;
                    })
                    .fail(function() {
                        sm.utils.showWarning(__('old-main.events-of-the-period.tag-adding-dialog.favorite-failed-msg'));
                    });
            } else {
                this.userFavouriteTagService
                    .delete(tag)
                    .done(() => {
                        sm.utils.showSuccess(`Тег <b>${tag.name}</b> удален из избранных`);
                        tag.isFavourite = false;
                    })
                    .fail(function() {
                        sm.utils.showWarning(
                            __('old-main.events-of-the-period.tag-adding-dialog.unfavorite-failed-msg')
                        );
                    });
            }
        },

        getSelectOpts() {
            return {
                multiple: true,
                ajax: {
                    transport: (params) => {
                        var isCreatingWithName = false;
                        if (this.isManualReloading) {
                            isCreatingWithName = true;
                        } else {
                            if (this.searchTerm && this.allowCreate) {
                                isCreatingWithName = this.isCreatingWithName;
                            } else {
                                this.isCreatingWithName = false;
                            }
                        }
                        return this.fetchPage({
                            search: params.searchTerm,
                            isCreatingWithName,
                            allowCreate: this.allowCreate
                        }).then(this.filterTags);
                    },
                    processResults: ({data, mode}) => {
                        if (mode === 'colors' || (this.searchTerm && this.allowCreate)) {
                            data.unshift({
                                $isDisabled: true,
                                isService: true,
                                id: -1,
                                mode
                            });
                        }
                        return data;
                    }
                },
                placeholder: this.placeholder,
                pluralLabelMinimumSelectionLength: 100
            };
        },

        fetchPage(params) {
            var colorResponse = {
                mode: 'colors',
                data: _.map(tagService.getColors(), (color) => {
                    color.name = params.search;
                    color.isColor = true;
                    var model = tagService.make(color);
                    model.name = color.colorTitle;
                    return model;
                })
            };
            _.defaults(params, {page: 1, perPage: -1, search: ''});

            if (params.isCreatingWithName) {
                return $.Deferred((dfd) => {
                    dfd.resolve(colorResponse);
                });
            }
            return tagService
                .fetch({
                    templateId: this.slaTemplate ? this.slaTemplate.id : null,
                    searchParams: this.searchParams,
                    ...params
                })
                .then(function(data) {
                    if (!data.length && params.allowCreate) {
                        return colorResponse;
                    }
                    return {mode: 'results', data};
                });
        },

        addEventHandlers() {
            var el = this.getSelect();
            el.$on('search-change', (term) => {
                this.searchTerm = term;
            });
            el.$on('close', () => {
                this.isCreatingWithName = false;
            });
            el.$on('select', (elem) => {
                if (elem.isColor === true) {
                    elem.name = this.searchTerm;
                }
                this.$refs.select.close();
                this.$refs.select.clearSearch();
                if (_.get(this.event, 'id')) {
                    this.eventTagService.create(this.event.id, elem).then(() => {
                        this.isCreatingWithName = false;
                    });
                } else {
                    this.isCreatingWithName = false;
                }
            });
            el.$on('remove', (elem) => {
                if (_.get(this.event, 'id')) {
                    this.eventTagService.delete(this.event.id, elem);
                }
            });
        }
    }
};
</script>

<style lang="less">
.select2_tags {
    .select2-selection__choice {
        &,
        .select2-selection--multiple & {
            position: relative;
            padding: 0;
            background-color: transparent;
            max-width: 100%;
        }
    }

    .result-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .multiselect__element {
        &[aria-disabled='true'] {
            padding-left: 12px;
        }

        .color-wrapper {
            padding: 7px 12px;
        }

        .result-wrapper {
            .tag {
                // margin-top: 9px;
                // margin-left: 12px;
            }
        }

        .title {
            display: inline-flex;
            flex-shrink: 1;
            flex-grow: 0;
            text-align: left;
            padding: 0 12px 0 10px;

            white-space: normal;
            min-width: 0;
            width: 100%;
            font-size: 13px;
        }

        .actions {
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: flex-end;
            // float: right;
            // width: 50px;
            padding-right: 8px;
            text-align: left;

            .refCount {
                display: block;
                padding-left: 8px;
                width: 20px;
                text-align: right;
                font-size: 1.2rem;
            }
        }

        .loading-results {
            position: relative;
            top: -7px;
            display: block;
            margin-bottom: -7px;
            padding-top: 7px;
            padding-right: 12px;
            padding-bottom: 7px;
            white-space: normal;
        }

        .create-with-name {
            cursor: pointer;
            color: #333333;
        }

        .tag {
            position: relative;
            display: inline-flex;
            flex-shrink: 0;
            width: 16px;
            height: 16px;
            // margin-top: 2px;
            padding: 0;
            vertical-align: middle;
            border-radius: 50%;
        }
    }

    .star {
        position: relative;
        // top: -1px;
        display: inline-block;
        min-width: 1em;
        text-align: center;
        vertical-align: middle;
        text-transform: none;
        color: #fab600;
        font-family: 'icomoon', sans-serif;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        font-variant: normal;
        line-height: 1;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding-right: 8px;
    }

    .select2-results > .select2-results__options {
        max-height: 324px;
    }

    .multiselect__content {
        max-width: 100%;
    }
}
</style>
