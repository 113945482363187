<template>
    <span :class="{'input-container_required' : (!!label && required)}" class="input-container">
        <label class="input-container__inner" :for="forId">
            <span class="input-container__label">{{ label }}</span>
            <template v-if="!slotTriggerExtraClick()">
                <slot/>
            </template>
        </label>
        <template v-if="slotTriggerExtraClick()">
            <slot/>
        </template>
        <template v-if="validatable">
            <span v-show="errors.has(name) && childCmp.inited !== false" class="input-container__error">{{ errors.first(name) }}</span>
        </template>
    </span>
</template>

<script>
export default Vue.component('SmLabeledNode', {
    props: {
        label: {
            type: String
        },
        forId: {
            type: String
        }
    },
    data() {
        return {
            validatable: false,
            required: false,
            name: '',
            errors: null,
            childCmp: {
                inited: false
            }
        };
    },
    mounted() {
        this.ensureValidation();
    },
    updated() {
        this.ensureValidation();
    },
    methods: {
        ensureValidation() {
            this.$nextTick(function() {
                if (!this.isStandardNodeFirstChildDefaultSlot()) {
                    if (!this.forId) {
                        console.error('нужен forId для корректной обертки в label', this.$el);
                        throw 'нужен forId для корректной обертки в label';
                    }
                    if (!$(`#${this.forId}`).length) {
                        console.error(`нужен элемент с id ${this.forId}`, this.$el);
                        throw `нужен элемент с id ${this.forId}`;
                    }
                }
                var $node = $(this.$el)
                    .find('[data-vv-id]')
                    .eq(0);
                if ($node.length) {
                    var errorsCtx = this;
                    while (errorsCtx && !errorsCtx.errors && this.$parent) {
                        errorsCtx = errorsCtx.$parent;
                    }
                    if (errorsCtx.errors) {
                        this.errors = errorsCtx.errors;
                        if (!$node.attr('name')) {
                            console.error('для валидации нужно имя', this.$el);
                            throw 'для валидации нужно имя';
                        }
                        this.name = $node.attr('name');
                        this.validatable = true;
                    }
                    if ($node[0].__vue__ && $node[0].__vue__.$children[0]) {
                        this.childCmp = $node[0].__vue__.$children[0];
                    } else {
                        if (!_.isEqual(this.childCmp, {inited: true})) {
                            this.childCmp = {
                                inited: true
                            };
                        }
                    }
                    if ($node.attr('aria-required') === 'true') {
                        this.required = true;
                    }
                    if ($node.attr('name') && this.slotTriggerExtraClick()) {
                        console.debug('проставляется id, равный name, для for в label');
                        $node.attr('id', $node.attr('name'));
                    }
                } else {
                    this.required = false;
                    this.validatable = false;
                }
            });
        },
        isStandardNodeFirstChildDefaultSlot() {
            var slot = this.$slots.default[0];
            return !_.get(slot, 'componentOptions');
        },
        slotTriggerExtraClick() {
            if (!this.$el) {
                return false;
            }
            return !this.isStandardNodeFirstChildDefaultSlot();
        }
    }
});
</script>
