var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.rerenderKey && _vm.isValidValue()
    ? _c("vue-autonumeric", {
        ref: "v",
        attrs: { value: _vm.value, options: _vm.opts },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event)
          }
        }
      })
    : _c("span")
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d1075316", { render: render, staticRenderFns: staticRenderFns })
  }
}