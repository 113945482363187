var localCounter = 10000;

sm.Service = class Service extends sm.Module {
    constructor(params) {
        super(...arguments);
        if (params && params.singleton) {
            this.singleton = params.singleton;
            if (this.constructor._instance) {
                return this.constructor._instance;
            }
            this.constructor._instance = this;
        }
        this.Model = this.Model || sm.Model;
        this.setUrlParams(params);
        this.models = [];
        this.types = {};
        this.urls = {};
        this.uuid = _.uniqueId('service');
        if (params) {
            this.isLocal = params.isLocal;
        }
        _.bindAll(this, 'make');
    }

    fetch(isLocal) {
        var self = this;
        this.isLocal = this.isLocal == null ? !!isLocal : this.isLocal;

        if (this.url === this.prevUrl && this.loaded === true) {
            return this.dfd;
        }
        this.prevUrl = this.url;

        if (this.dfd && this.dfd.state() === 'pending') {
            return this.dfd;
        }
        if (this.isLocal === true) {
            if (_.isFunction(this.fetchLocal) === false) {
                throw 'выполните .addModels до вызова .fetch или определите .fetchLocal';
            }
            this.dfd = this.fetchLocal();
        } else {
            this.dfd = $.ajax({
                type: this.types.fetch || 'GET',
                url: this.urls.fetch || this.url
            });
        }
        this.dfd
            .done(function(data) {
                self.models = [];
                self._processData(data);
                self.loaded = true;
            })
            .fail(function() {
                console.warn('service fetch fails', arguments);
                self.loaded = true;
            });
        return this.dfd;
    }

    _processData(data) {
        return this.models.push(..._.map(this.parse(data), this.make));
    }

    create(data) {
        var self = this;
        return (this.isLocal
            ? new $.Deferred(function(dfd) {
                  data.id = localCounter++;
                  dfd.resolve(data);
              })
            : $.ajax(
                  _.merge(
                      {
                          url: self.urls.create || `${self.url}`,
                          type: self.types.create || 'POST',
                          data
                      },
                      this.createOpts
                  )
              )
        )
            .done(function(data) {
                self.models.push(new self.Model(data));
            })
            .fail(function() {
                console.warn('service create fails', arguments);
            });
    }

    update(source, opts) {
        var self = this;
        var id = source.id;

        /* eslint-disable no-empty */
        try {
            id = JSON.parse(source).id;
        } catch (e) {}
        /* eslint-enable no-empty */

        return (this.isLocal
            ? new $.Deferred(function(dfd) {
                  dfd.resolve(source);
              })
            : $.ajax(
                  _.merge(
                      {
                          url: self.urls.update || `${self.url}/${id}`,
                          type: self.types.update || 'PUT',
                          data: source
                      },
                      this.updateOpts
                  )
              )
        )
            .done(function(data) {
                if (opts && opts.setResponseFromRequest === true) {
                    data = source;
                }
                var elem = self.getById(data.id);
                _.each(new self.Model(data), function(propValue, propName) {
                    elem[propName] = propValue;
                });
            })
            .fail(function() {
                console.warn('service update fails', arguments);
            });
    }

    delete(id) {
        var self = this;
        if (this.deleteXhr) {
            return this.deleteXhr;
        }
        this.deleteXhr = this.isLocal
            ? new $.Deferred(function() {
                  this.resolve();
              })
            : $.ajax({
                  url: self.urls.delete || `${self.url}/${id}`,
                  type: self.types.delete || 'DELETE'
              });
        return this.deleteXhr
            .done(function() {
                _.remove(self.models, self.getById(id));
            })
            .fail(function() {
                console.warn('service delete fails', arguments);
            })
            .always(function() {
                self.deleteXhr = null;
            });
    }

    parse(data) {
        return data;
    }

    getById(id) {
        return _.find(this.models, {id});
    }

    setUrlParams(params) {
        this.prevUrl = this.url;
        this.url = this.makeUrl(params);
        if (this.prevUrl !== this.url) {
            this.loaded = false;
            if (this.dfd && this.dfd.abort) {
                this.dfd.abort();
            }
        }
    }

    createNew() {
        return new this.Model({});
    }

    make(data) {
        return new this.Model(data);
    }

    destroy() {
        if (this.dfd && this.dfd.abort) {
            this.dfd.abort();
        }
        this.off(`${this.uuid}.modelUpdate`);
        if (this.singleton) {
            this.constructor._instance = null;
        }
    }
};

sm.Model = class {
    constructor(data) {
        _.merge(this, data);
    }
};
