import VeeValidate from 'vee-validate';
import {mergeData} from 'vue-functional-data-merge';
import PortalVue from 'portal-vue';
import isURL from 'validator/lib/isURL';

if (sm.systemUser.lang.get() !== 'en') {
    VeeValidate.Validator.localize('ar', require(`vee-validate/dist/locale/${sm.systemUser.lang.get()}.js`).default);
}

require('src/js/page.vue');
require('src/js/widgets/sidebar/sidebar.vue');
require('src/js/widgets/navbar.vue');

var requireComponents = require.context('./components', true, /.*\/(?!.*\.spec\.js$).*\.(js|vue)$/);
_.each(requireComponents.keys(), requireComponents);
var requireDirectives = require.context('./directives', true, /.*\/(?!.*\.spec\.js$).*\.(js|vue)$/);
_.each(requireDirectives.keys(), requireDirectives);

require('src/js/widgets/rsmSelect.vue');
require('src/js/widgets/ciTypeSelector.vue');

Vue.set(Vue.prototype, '_', _);
Vue.set(Vue.prototype, 'console', console);
Vue.set(Vue.prototype, '$global', global);
Vue.set(Vue.prototype, 'sm', {
    customer: sm.customer,
    utils: sm.utils
});

/**
 * Как пользоваться:
 *
 * в любой компонент передаем prop - "dbg"
 * ну а внутри компонента:
 *
 *   if (this.dbg) {
 *     debugger;
 *   }
 *
 */
Vue.mixin({
    props: {
        dbg: {
            type: Boolean,
            default: false
        }
    }
});

/* eslint-disable no-debugger */
Vue.set(Vue.prototype, '$debugger', () => {
    debugger;
});
/* eslint-enable no-debugger */

Vue.set(Vue.prototype, '$', $);

Vue.createInstance = function(cfg, propsData = {}) {
    var Class = Vue.extend(cfg);
    return new Class({
        propsData
    });
};
Vue.cloneNodeMergeData = function(createElement, vnode, data) {
    return createElement(vnode.tag, vnode.data ? mergeData(vnode.data, data) : data, vnode.children);
};

Vue.use(VeeValidate, {
    locale: sm.systemUser.lang.get(),
    inject: false
});
VeeValidate.Validator.dictionary.merge({
    ru: {
        messages: {
            required: () => __('common.alerts.fill-required-field'),
            email: () => __('pleaseEnterEmail'),
            min: function(field, ref) {
                return __('pleaseEnterMinCountSymbols', {count: ref[0]});
            }
        }
    }
});

VeeValidate.Validator.extend('url', {
    getMessage: (field) => __('common.alerts.enter-valid-url'),
    validate: (value) =>
        isURL(value, {
            require_protocol: true
        })
});
VeeValidate.Validator.extend('simpleUrl', {
    getMessage: (field) => __('common.alerts.enter-valid-url'),
    validate: sm.utils.isURL
});
VeeValidate.Validator.extend('isFloat', {
    getMessage: () => 'Пожалуйста, введите число',
    validate: (value) => !isNaN(parseFloat(value))
});

Vue.use(PortalVue);

import VueVirtualScroller from 'vue-virtual-scroller';
Vue.use(VueVirtualScroller);

import {Promised} from 'vue-promised';
Vue.component('Promised', Promised);
