<template>
    <input v-if="!$slots.default" class="time-bounds-limit">
    <div v-else>
        <slot/>
    </div>
</template>

<script>
// Обертка над @see https://jqueryui.com/datepicker/
export default Vue.component('SmDatepicker', {
    props: {
        options: {
            type: Object
        },
        value: {
            type: Date
        }
    },
    watch: {
        'options.minDate'(value) {
            $(this.$refs.input).datepicker('option', 'minDate', value);
        },
        'options.maxDate'(value) {
            $(this.$refs.input).datepicker('option', 'maxDate', value);
        },
        value() {
            this.initDatepicker();
        }
    },
    mounted() {
        var $input = $(this.$el).is('input') ? $(this.$el) : $(this.$el).find('input');
        this.$refs.input = $input[0];
        this.initDatepicker();
        this.$emit('ready', $input);
    },
    beforeDestroy() {
        $(this.$el)
            .datepicker('hide')
            .datepicker('destroy');
    },
    methods: {
        initDatepicker() {
            var vm = this;
            if (!this.options.dateFormat) {
                this.options.dateFormat = sm.constants.formatPatternjQueryUI;
            }
            $(this.$refs.input)
                .datepicker('destroy')
                .datepicker({
                    ...this.options,
                    changeYear: true,
                    yearRange: '-2:+0',
                    changeMonth: true,
                    onSelect() {
                        vm.$emit('input', $(vm.$refs.input).datepicker('getDate'));
                    }
                })
                .datepicker('setDate', this.value);
        }
    }
});
</script>
