var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      ref: "label",
      staticClass: "fancytree-checkbox-wrapper vue-fancytree-checkbox-wrapper",
      class: {
        "fancytree-selected": _vm.value,
        "fancytree-partsel": !_vm.value && _vm.indeterminate
      },
      on: {
        "!click": function($event) {
          return _vm.ensureValueAndSingleClick($event)
        }
      }
    },
    [
      _c("span", { staticClass: "fancytree-checkbox" }, [
        _c("input", {
          ref: "checkbox",
          attrs: { type: "checkbox" },
          domProps: { checked: _vm.value }
        })
      ]),
      _vm._v(" "),
      _c("span", { domProps: { innerHTML: _vm._s(_vm.label) } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4744d4ef", { render: render, staticRenderFns: staticRenderFns })
  }
}