var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "vue-switchery-wrapper control-label",
      class: [
        "switchery-" + _vm.size,
        {
          "checkbox-inline": _vm.nodesCount <= 2,
          "checkbox-switchery": _vm.nodesCount <= 2,
          "switchery-double": _vm.nodesCount > 2
        }
      ],
      on: {
        "!click": function($event) {
          return _vm.ensureValueAndSingleClick($event)
        }
      }
    },
    [
      _vm._t("default", [_c("input", { attrs: { type: "checkbox" } })]),
      _vm._v(" "),
      _vm.nodesCount < 2 ? _c("span", [_vm._v(" ")]) : _vm._e(),
      _vm._v("\n    " + _vm._s(_vm.label) + "\n")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3f4a8ebc", { render: render, staticRenderFns: staticRenderFns })
  }
}