var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "input-container",
      class: { "input-container_required": !!_vm.label && _vm.required }
    },
    [
      _c(
        "label",
        { staticClass: "input-container__inner", attrs: { for: _vm.forId } },
        [
          _c("span", { staticClass: "input-container__label" }, [
            _vm._v(_vm._s(_vm.label))
          ]),
          _vm._v(" "),
          !_vm.slotTriggerExtraClick() ? [_vm._t("default")] : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.slotTriggerExtraClick() ? [_vm._t("default")] : _vm._e(),
      _vm._v(" "),
      _vm.validatable
        ? [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.errors.has(_vm.name) && _vm.childCmp.inited !== false,
                    expression: "errors.has(name) && childCmp.inited !== false"
                  }
                ],
                staticClass: "input-container__error"
              },
              [_vm._v(_vm._s(_vm.errors.first(_vm.name)))]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-392bc6b9", { render: render, staticRenderFns: staticRenderFns })
  }
}