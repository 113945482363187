var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sm-tooltip", class: ["sm-tooltip_position-" + _vm.dir] },
    [
      _c(
        "div",
        { staticClass: "sm-tooltip__trigger" },
        [
          _vm.$slots.title
            ? _vm._t("title")
            : _c("i", { staticClass: "material-icons" }, [
                _vm._v("help_outline")
              ])
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "sm-tooltip__content-wrapper" }, [
        _c(
          "div",
          { staticClass: "sm-tooltip__content" },
          [_vm._t("default")],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-60a9d687", { render: render, staticRenderFns: staticRenderFns })
  }
}