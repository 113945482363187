class PriorityService extends sm.Service {
    constructor() {
        super({
            singleton: true
        });
        this.isLocal = true;
    }

    makeUrl() {}

    getById(priority, includeSpecialValues) {
        return PriorityService.getById(priority, includeSpecialValues);
    }

    static getById(priority, includeSpecialValues) {
        var result = sm.constants.priorities.getById(priority);
        if (!result && includeSpecialValues) {
            result = specialValues[priority];
        }
        return result;
    }

    fetchLocal() {
        if (!this.dfd) {
            this.dfd = new $.Deferred();
            this.dfd.resolve(_.map([1, 2, 3, 4], (id) => this.getById(id)));
        }
        return this.dfd;
    }

    getDurationUnits() {
        throw new Error('Метод должен быть перегружен в src/js/services/i18n.js');
    }
}

var specialValues = {
    '-1': {
        id: -1,
        color: 'transparent',
        shortDescription: 'Игнорировать',
        description: 'Игнорировать',
        name: `Игнорировать`
    }
};

sm.PriorityService = PriorityService;
