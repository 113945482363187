var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.data.dateEnd
    ? _c(
        "span",
        {
          staticClass: "label duration-label_wrapper blink-opacity",
          class: [_vm.cls]
        },
        [
          _c("span", { staticClass: "duration-label" }, [
            _vm._v(_vm._s(_vm.durationStr))
          ])
        ]
      )
    : _c("span", [_vm._v(_vm._s(_vm.durationStr))])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-40031252", { render: render, staticRenderFns: staticRenderFns })
  }
}