<template>
    <json-viewer
        :value="innerModel"
        v-bind="attrs"
        v-on="listeners"
        @input="innerModel = $event"
        />
</template>

<script>
import JsonViewer from 'vue-json-viewer/ssr';
import 'vue-json-viewer/style.css';

export default Vue.component('SmJsonViewer', {
    components: {JsonViewer},
    inheritAttrs: false,
    data() {
        return {
            innerModel: this.$attrs.value,
            defaults: {
                expandDepth: 1,
                copyable: false,
                boxed: false,
                sort: false
            }
        };
    },
    computed: {
        attrs() {
            return _.omit(_.assign({}, this.defaults, this.$attrs), ['value']);
        },
        listeners() {
            return _.omit(this.$listeners, ['input']);
        }
    },
    watch: {
        '$attrs.value': {
            immediate: true,
            handler() {
                this.innerModel = _.cloneDeep(this.$attrs.value);
            }
        },
        innerModel() {
            if (!_.isEqual(this.innerModel, this.$attrs.value)) {
                this.$emit('input', this.innerModel);
            }
        }
    }
});
</script>

<style lang="scss" scoped>
/deep/ .jv-toggle {
    position: relative;
    top: 40%;
    transition: none;

    &.open {
        transform: rotate(90deg);
    }
}
</style>
