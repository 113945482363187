var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "summernote w-summernote",
    attrs: { disabled: _vm.disabled },
    domProps: { innerHTML: _vm._s(_vm.value) }
  })
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e147b566", { render: render, staticRenderFns: staticRenderFns })
  }
}