<template>
    <vue-autonumeric ref="v" v-if="rerenderKey && isValidValue()" :value="value" @input="$emit('input', $event)" :options="opts"/>
    <span v-else/>
</template>

<script>
require('AutoNumeric');
import VueAutonumeric from 'vue-autonumeric';

Vue.component('VueAutonumeric', VueAutonumeric);

export default Vue.component('SmNumber', {
    props: {
        /* eslint-disable vue/require-prop-types */
        value: {
            validator(value) {
                return _.isNumber(value) || _.isString(value) || null;
            }
        },
        options: {
            type: Object
        }
        /* eslint-enable vue/require-prop-types */
    },
    components: {
        VueAutonumeric
    },
    data() {
        return {
            rerenderKey: 1
        };
    },
    computed: {
        opts() {
            return _.extend(
                {
                    minimumValue: 0,
                    decimalPlaces: 0,
                    emptyInputBehavior: 'null',
                    digitGroupSeparator: ' ',
                    onInvalidPaste: 'clamp'
                },
                this.options
            );
        }
    },
    created() {
        this.ensureValidValue();
    },
    methods: {
        ensureValidValue() {
            if (!this.options) {
                return;
            }
            if (this.value < this.options.minimumValue) {
                this.$emit('input', this.options.minimumValue);
            } else if (this.value > this.options.maximumValue) {
                this.$emit('input', this.options.maximumValue);
            }
        },
        isValidValue() {
            if (!this.options) {
                return true;
            }
            var valInRawInput = this.$refs.v ? +$(this.$refs.v.$el).val() : this.value;

            // когда меняются одновременно value, minimumValue и maximumValue (например, при работе с пейджингом
            // и фильтрами в datatables), autoNumeric может генерить ошибку про выход value за пределы minimumValue
            // и maximumValue в связи с временным рассинхроном $(this.$refs.v.$el).val() и this.value
            if (valInRawInput !== this.value) {
                this.rerenderKey++;
                return false;
            }

            return (
                this.value >= _.get(this.options, 'minimumValue', -Infinity) &&
                this.value <= _.get(this.options, 'maximumValue', Infinity)
            );
        }
    }
});
</script>
