var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sm-tree" },
    [
      _vm.filterInput
        ? _c("sm-search-input", {
            attrs: { disabled: !_vm.treeData.length },
            model: {
              value: _vm.filterTerm,
              callback: function($$v) {
                _vm.filterTerm = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "filterTerm"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default", [_c("div")])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-df741d6c", { render: render, staticRenderFns: staticRenderFns })
  }
}