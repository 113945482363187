import {ClipboardJS} from 'clipboard/dist/clipboard.js';

(function() {
    var name = 'sm-copy-clipboard';

    function parseData(binding) {
        var res = {};
        if (_.isObject(binding.value)) {
            res = binding.value;
        } else {
            res.value = binding.value;
        }
        return res;
    }
    Vue.directive(name, {
        bind(el, binding) {
            $(el).data(_.camelCase(name), parseData(binding));
            new ClipboardJS(el, {
                text() {
                    return $(el).data(_.camelCase(name)).value;
                }
            }).on('success', function(e) {
                var data = $(el).data(_.camelCase(name));
                sm.utils.showSuccess({
                    message: data.message
                        ? `${data.message}: <b>${e.text}</b>`
                        : __('common.alerts.id-copied').replace('{id}', `<b>${e.text}</b>`),
                    textWasCopied: true
                });
                e.clearSelection();
            });
        },
        componentUpdated(el, binding) {
            $(el).data(_.camelCase(name), parseData(binding));
        }
    });
})();
