var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "label priority-label_wrapper", class: [_vm.priorityCls] },
    [
      _c("span", { staticClass: "priority-label" }, [
        _vm._v(_vm._s(_vm.priorityTitle))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-704c22b4", { render: render, staticRenderFns: staticRenderFns })
  }
}