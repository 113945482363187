<template>
    <div
        v-if="menuItem"
        :id="getId()"
        :class="{
        'menu__item--opened': menuItem.type === 'Menu' && showMenu(),
        'menu_item--one-link':
            menuItem.type === 'Menu' &&
            menuItem.items &&
            menuItem.items.length === 1,
        'menu_item--many-link':
            menuItem.type === 'Menu' && menuItem.items && menuItem.items.length > 1,
        }"
        class="menu__item"

        @mouseenter="mouseEnter"
        @mouseleave="mouseLeave"
        >
        <a
            v-if="menuItem.type === 'Label'"

            class="menu__link menu__link--label"
            tabindex="-1"
            @click.exact.left="
                $event.preventDefault();
                handle();
            ">

            <div class="menu__info">
                <div
                    v-if="isUserProfile()"
                    class="menu__user menu-user">
                    <span
                        v-if="menuItem.image"
                        class="menu__icon">
                        <img
                            :src="menuItem.image"
                            class="menu__image"
                            width="32"
                            height="32"
                            >
                    </span>
                    <div
                    v-if="!menuItem.image"
                    class="menu-user__icon menu-user__icon--default" >
                        <span class="menu-user__text">
                            {{ getImageText() }}
                        </span>
                    </div>
                </div>
                <span
                    v-if="!isUserProfile()"
                    class="menu__icon">
                    <img
                        :src="getMenuIcon()"
                        class="menu__image"
                        width="32"
                        height="32"
                        >
                </span>
                <span
                    :title="menuItem.title"
                    class="menu__text">
                    <span class="menu__title">
                        {{ __(menuItem.title) }}
                    </span>
                    <span
                        v-if="menuItem.description"
                        class="menu__description">
                        {{ __(menuItem.description) }}
                    </span>
                </span>
            </div>
        </a>

        <a
        v-if="menuItem.type === 'Menu' || menuItem.type === 'Link'"
        :class="getClassesWithActive()"
        :href="menuItem.url"
        class="menu__link"
        @click.exact.left="
            $event.preventDefault();
            handle();
        "
        @focus="focus()"
        @focusout="focusOut()"
        >
            <div class="menu__info">
               <div
                    v-if="isUserProfile()"
                    class="menu__user menu-user">
                    <span
                        v-if="menuItem.image"
                        class="menu__icon">
                        <img
                            :src="menuItem.image"
                            class="menu__image"
                            width="32"
                            height="32"
                            >
                    </span>
                    <div
                        v-if="!menuItem.image"
                        class="menu-user__icon menu-user__icon--default" >
                        <span class="menu-user__text">
                            {{ getImageText() }}
                        </span>
                    </div>
                </div>
                <span
                    v-if="!isUserProfile()"
                    class="menu__icon">
                    <img
                        :src="getMenuIcon()"
                        class="menu__image"
                        width="32"
                        height="32"
                        >
                </span>
                <span
                    :title="menuItem.title"
                    class="menu__text">
                    <span class="menu__title" >
                        {{ __(menuItem.title) }}
                    </span>
                    <span
                        v-if="menuItem.description"
                        class="menu__description">
                        {{ __(menuItem.description) }}
                    </span>
                </span>
            </div>
        </a>

        <SmSidebarMenu
        v-if="showMenu() && menuItem.type === 'Menu'"
        :parent="menuItem"
        :items="menuItem.items"
        :expandOnMouseEvents="false"
        :menuExpanded="true"
        @onMenuInit="menuInit()"
        @onMouseItemClick="handle()"
        @onExecuteScript="executeScript"
        />

        <a
            v-if="menuItem.type === 'Script' || menuItem.type === 'Func'"
            :class="menuItem.classes"
            href="#"
            class="menu__link"
            @click="
                $event.preventDefault();
                handle();
            "
            @focus="focus()"
            @focusout="focusOut()"
            >
            <div class="menu__info">
                <span
                    v-if="menuItem.image"
                    class="menu__icon">
                    <img
                        :src="menuItem.image"
                        class="menu__image"
                        width="32"
                        height="32" >
                </span>
                <span
                    v-if="!menuItem.image"
                    class="menu__icon">
                    <img
                    :src="getMenuIcon()"
                    class="menu__image"
                    width="32"
                    height="32"
                    >
                </span>
                <span class="menu__text">
                    <span class="menu__title">
                        {{ __(menuItem.title) }}
                    </span>
                    <span
                        v-if="menuItem.description"
                        class="menu__description">
                        {{ __(menuItem.description) }}
                    </span>
                </span>
            </div>
        </a>
    </div>
</template>

<script>
import SmSidebarMenu from './sidebar-menu.vue';

export default Vue.component('SmSidebarItem', {
    components: {SmSidebarMenu},

    props: {
        index: {
            type: Number,
            default: 0
        },
        menuItem: {
            type: Object,
            default: undefined
        }
    },

    data() {
        return {
            isMouseHover: false
        };
    },

    methods: {
        getId() {
            return `menu-item_${this.menuItem.name.replace('.', '_')}`;
        },

        isActive() {
            return this.isMenuItemActive(this.menuItem) || this.isChildActive();
        },

        isChildActive() {
            if (!this.menuItem.items) return false;
            return this.menuItem.items.some((item) => this.isMenuItemActive(item));
        },

        isMenuItemActive(menuItem) {
            if (menuItem.type !== 'Menu' && menuItem.type !== 'Link') {
                return false;
            }

            if (window.location.pathname === '/' && menuItem.name === 'side-menu.main') {
                return true;
            }

            return menuItem.url === window.location.pathname;
        },

        showMenu() {
            return this.isMouseHover;
        },

        focus() {},

        focusOut() {},

        async handle() {
            if (this.menuItem.type === 'Label') {
                this.$emit('onMouseClick');
            }
            if (this.menuItem.type === 'Menu') {
                if (this.menuItem.items && this.menuItem.items.length > 1) {
                    this.urlNavigate(this.menuItem.items[1]);
                } else {
                    this.urlNavigate(this.menuItem);
                }
            } else if (this.menuItem.type === 'Link') {
                this.urlNavigate(this.menuItem);
            } else if (this.menuItem.type === 'Func') {
                this.executeFunc(this.menuItem);
            } else if (this.menuItem.type === 'Script') {
                this.executeScript(this.menuItem);
            }
        },

        mouseEnter(event) {
            this.isMouseHover = true;
            this.$emit('onMouseEnter', this.menuItem);
        },

        mouseLeave(event) {
            this.isMouseHover = false;
        },

        menuInit() {
            this.$emit('onMenuInit');
        },

        getMenuIcon() {
            if (this.menuItem.icon) {
                try {
                    return require(`src/img/menu/${this.menuItem.icon}.svg`);
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log(`Failed to load SVG menu icon ${this.menuItem.icon}`, e);
                }
            }

            return require('src/img/menu/default.svg');
        },

        getClassesWithActive() {
            return this.isActive() ? `active ${this.menuItem.classes}` : this.menuItem.classes;
        },

        isUserProfile() {
            return this.menuItem.name === 'side-menu.profile' || this.menuItem.name === 'side-menu.profile-label';
        },

        getImageText() {
            if (!this.menuItem.title) return '';
            const splitted = this.menuItem.title.split(' ', 2);
            let imageText = '';
            splitted.forEach((element) => {
                imageText += element.charAt(0).toUpperCase();
            });
            return imageText;
        },

        urlNavigate(menuItem) {
            if (!menuItem || !menuItem.url) {
                return;
            }

            // TODO: Выяснить как работать со ссылками на другие интерфейсы Angular.
            if (menuItem.urlType === 'Relative') {
                window.location.href = `${window.location.origin}${menuItem.url}`;
            } else if (menuItem.urlType === 'Absolute') {
                window.open(menuItem.url, '_blank');
            }
        },

        executeFunc(menuItem) {
            if (!menuItem || menuItem.type !== 'Func' || !menuItem.handler) {
                return;
            }

            if (menuItem.name === 'side-menu.profile.logout') {
                sm.systemUser.onLogout();
            }
        },

        executeScript(menuItem) {
            if (!menuItem || menuItem.type !== 'Script' || !menuItem.handler) {
                return;
            }

            const handlerObj = menuItem.handler;
            if (!handlerObj || !handlerObj.selector || !handlerObj.function) {
                return;
            }

            const element = document.querySelector(handlerObj.selector);
            if (element) {
                if (handlerObj.function === 'Click') {
                    element.click();
                }
            }
        }
    }
});
</script>

<style lang="scss" scoped>
// li
.menu__item {
    // Открытое меню
    &.menu__item--opened {
        .menu__link {
            z-index: 3;
            text-decoration: none;
            background-color: #353535;
            background-image: none;

            &:hover + .menu-dropdown {
                &:before {
                    width: calc(var(--menu-size) * 0.45);
                    transition-delay: 0.2s;
                    transition-property: width;
                }
            }
        }

        .menu__icon {
            filter: brightness(1);
        }
    }
}

.menu__link:not(.menu__link--label) {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-height: var(--menu-item-height);
    padding-top: 2vh;
    padding-bottom: 2vh;
    transition: 0.3s ease background-color;
    text-align: center;
    text-decoration: none;
    outline: none;
    background-image: none;

    &:not(.menu__link--label) {
        &:focus {
            box-shadow: 0 0 0 3px #95baed inset;
        }
    }

    .menu__icon {
        width: var(--main-menu-icon-size);
        height: var(--main-menu-icon-size);
    }

    .menu__text {
        // Заготовка под вывод подписей пунктов меню
        // display: inline-block;
        display: NONE;
        width: 100%;
    }

    .menu__title {
        width: 100%;
        color: #ffffff;
        font-size: 10px;
        line-height: 10px;
    }

    // Выбрано
    &.active {
        &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            pointer-events: none;
            border-left: 2px solid #ffffff;
            background-color: #e7e9eb26;
        }

        .menu__icon {
            filter: brightness(1);
        }
        .menu__image {
            filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0 0 11px #1b7ff4);
        }
    }
}

// ОБЩИЕ СТИЛИ
.menu__icon {
    display: inline-flex;
    flex-grow: 0;
    flex-shrink: 0;
    filter: brightness(0.85);
}

.menu__image {
    width: 100%;
    height: 100%;
}

.menu__info {
    display: flex;
}

.menu-user,
.menu-user__icon {
    width: 32px;
    height: 32px;
}

.menu-user__icon--default {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #7bb466;
}

.menu-user__text {
    color: #ffffff;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
}
</style>
