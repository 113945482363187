var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items
    ? _c(
        "ul",
        {
          staticClass: "menu-dropdown aim-menu",
          class: {
            "menu-dropdown--expand": _vm.menuExpanded === true
          },
          attrs: { id: _vm.getId() },
          on: {
            mouseenter: function($event) {
              return _vm.expandOnMouseEnter()
            },
            mouseleave: function($event) {
              return _vm.collapseOnMouseLeave()
            }
          }
        },
        [
          _vm._l(_vm.items, function(item, idx) {
            return [
              _c(
                "li",
                { key: idx, attrs: { id: _vm.getItemId(item) } },
                [
                  [
                    item
                      ? _c("SmSidebarItem", {
                          attrs: { index: 0, menuItem: item },
                          on: { onMouseClick: _vm.mouseMenuItemClick }
                        })
                      : _vm._e()
                  ]
                ],
                2
              )
            ]
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4f302620", { render: render, staticRenderFns: staticRenderFns })
  }
}