<template>
    <div class="sm-tree">
        <sm-search-input v-if="filterInput" v-model.trim="filterTerm" :disabled="!treeData.length"/>
        <slot>
            <div/>
        </slot>
    </div>
</template>

<script>
require('src/js/widgets/searchInput.vue');

$.ui.fancytree.debugLevel = 1;

export default Vue.component('sm-tree', {
    props: {
        value: {
            type: Array,
            default: () => {
                return [];
            }
        },
        data: {
            type: Array,
            default: () => {
                return [];
            }
        },
        config: {
            type: Object
        },
        dataId: {
            type: String,
            default: 'id'
        },
        dataText: {
            type: String,
            default: 'title'
        },
        /* eslint-disable vue/require-prop-types */
        root: {
            validator(value) {
                return _.isObject(value) || _.isString(value) || value == null;
            }
        },
        /* eslint-enable vue/require-prop-types */
        filterInput: {
            type: Boolean,
            default: true
        },
        customFilter: {
            type: Function
        }
    },
    data() {
        var rootDataId = 0;
        var expandedNodes = {};
        var computedRoot = null;
        if (this.root) {
            computedRoot = _.isObject(this.root)
                ? this.root
                : {
                      [this.dataId]: rootDataId,
                      [this.dataText]: this.root,
                      expanded: true
                  };
        }
        return {
            rootDataId,
            treeData: [],
            computedRoot,
            expandedNodes,
            internalModelChange: false,
            filterTerm: '',
            hasFakeFilter: false
        };
    },
    computed: {
        cfg() {
            /* eslint-disable vue/no-side-effects-in-computed-properties */
            if (_.get(this, 'config.selectMode') === 'branchIsSeparateEntity') {
                _.extend(this.config, {
                    beforeSelect: (event, data) => {
                        this.internalModelChange = true;
                        var selectMode = 3;
                        if (
                            !data.node.children ||
                            (data.node.isExpanded() &&
                                data.node.data[this.dataId] !== _.get(this, `computedRoot[${this.dataId}]`))
                        ) {
                            selectMode = 2;
                            if (data.node.selected) {
                                data.node.partsel = false;
                                data.node.renderStatus();
                            }
                        }
                        this.$tree.fancytree('option', 'selectMode', selectMode);
                    },
                    selectMode: 3
                });
            }
            return _.extend(
                {},
                {
                    extensions: ['filter'],
                    filter: {
                        mode: 'hide',
                        highlight: true
                    },
                    strings: {
                        noData: __('common.alerts.no-data')
                    },
                    checkbox: true,
                    selectMode: 3,
                    icon: false,
                    source: this.treeData,
                    beforeSelect: (event, data) => {
                        this.internalModelChange = true;
                    },
                    select: (event, data) => {
                        if (this.internalModelChange) {
                            this.$emit('input', this.getSelectionWithoutRoot()).$emit('select', data);
                        }
                    },
                    beforeExpand: (event, data) => {
                        this.expandedNodes[data.node.data[this.dataId]] = !data.node.expanded;
                    },
                    click: (event, data) => {
                        if (event.originalEvent.target.classList.contains('fancytree-title')) {
                            if (this.hasCheckboxes) {
                                data.node.toggleSelected();
                            } else {
                                data.node.toggleExpanded();
                            }
                        }
                    },
                    dblclick: (event, data) => {
                        if (_.get(data, 'node.data.id') === this.rootDataId) {
                            return false;
                        }
                    }
                },
                this.config
            );
            /* eslint-enable vue/no-side-effects-in-computed-properties */
        },
        hasCheckboxes() {
            return !!this.cfg.checkbox;
        }
    },
    watch: {
        data: {
            handler(val) {
                var processNode = (node) => {
                    node.key = `${node[this.dataId]}`;
                    node.title = node[this.dataText];
                    _.map(node.children, processNode);
                };
                var result = val;
                if (this.computedRoot && this.data.length) {
                    this.computedRoot.children = val;
                    result = [this.computedRoot];
                }
                _.map(result, processNode);
                this.treeData = result;
            },
            immediate: true
        },
        value: {
            handler(val) {
                var processNode = (node) => {
                    var nodeId = node[this.dataId];
                    if (this.hasCheckboxes) {
                        node.selected = _.includes(val, nodeId);
                    }
                    if (node.expanded === true) {
                        this.expandedNodes[nodeId] = true;
                    }
                    node.expanded = !!this.expandedNodes[nodeId];
                    _.map(node.children, processNode);
                };
                if (this.treeData[0]) {
                    processNode(this.treeData[0]);
                }
                this.treeDataWatcher(this.treeData);
            },
            immediate: true
        },
        treeData(val) {
            this.treeDataWatcher(val);
        },
        filterTerm() {
            this.applyFilter();
        },
        customFilter() {
            this.applyFilter();
        }
    },
    mounted() {
        this.$tree = $(this.$el)
            .find('>*')
            .last();
        if (_.includes(this.cfg.extensions, 'table')) {
            this.$tree = this.$tree.find('table');
        }

        this.$tree.fancytree(this.cfg);
        this.tree = this.$tree.fancytree('getTree');

        if (!this.hasCheckboxes) {
            this.$tree.on('click', (e) => {
                if (sm.utils.parentOrSelfIs(e.target, '.fancytree-node')) {
                    setTimeout(() => {
                        var node = $.ui.fancytree.getNode(e.target);
                        var expanded = $(e.target)
                            .closest('tr')
                            .is('.fancytree-expanded');
                        this.expandedNodes[node.data[this.dataId]] = expanded;
                        this.findAndProcessDataInSource(node.data[this.dataId], function(data) {
                            data.expanded = expanded;
                        });
                    }, 0);
                }
            });
        }
    },
    methods: {
        debouncedForceUpdate: _.debounce(function() {
            this.treeDataWatcher(this.treeData);
        }, sm.constants.debounceWidgetTimeout),
        treeDataWatcher(val) {
            if (!this.tree) {
                return;
            }
            if (this.internalModelChange) {
                this.internalModelChange = false;
            } else {
                this.tree.reload(val);
            }
            this.ensureRootState();

            // noData отображается только при фильтрации, в случае пустых данных - нет
            if (!this.treeData.length) {
                this.applyFilter('xyz');
                this.hasFakeFilter = true;
            } else if (this.hasFakeFilter) {
                this.hasFakeFilter = false;
                this.applyFilter('');
            }
        },
        applyFilter(filterTerm) {
            filterTerm = filterTerm || this.filterTerm;
            var isMatched = (node) => _.includes(node.title.toLowerCase(), filterTerm.toLowerCase());
            if (!filterTerm) {
                this.tree.clearFilter();
            }
            if (_.isFunction(this.customFilter)) {
                this.tree.filterBranches((node) => {
                    var matched = isMatched(node) && this.customFilter(node);
                    this.toggleMatch(node, matched, filterTerm);
                    return matched;
                });
            } else {
                if (!filterTerm) {
                    return;
                }
                this.tree.filterNodes((node) => {
                    var matched = isMatched(node);
                    this.toggleMatch(node, matched, filterTerm);
                    return matched && node.children ? 'branch' : matched;
                });
            }
        },
        toggleMatch(node, matched, filterTerm) {
            if (matched && filterTerm) {
                node.titleWithHighlight = node.title.replace(
                    new RegExp(sm.utils.escapeRegexp(filterTerm), 'ig'),
                    '<mark>$&</mark>'
                );
            } else {
                delete node.titleWithHighlight;
            }
        },
        getSelectionWithoutRoot() {
            var selection = _.map(this.tree.getSelectedNodes(), `data.${this.dataId}`);
            var filter = this.computedRoot
                ? (selection) => _.without(selection, this.computedRoot[this.dataId])
                : _.identity;
            return filter(selection);
        },
        getChildrenCount(children) {
            var result = children.length;
            _.each(children, (node) => {
                if (node.children) {
                    result += this.getChildrenCount(node.children);
                }
            });
            return result;
        },
        ensureRootState() {
            if (!this.computedRoot || !this.treeData.length) {
                return;
            }
            var selectedCount = this.getSelectionWithoutRoot().length;
            var allCount = this.getChildrenCount(this.data);
            var node = this.tree.getFirstChild();
            node.selected = selectedCount === allCount;
            node.partsel = selectedCount > 0;
            node.renderStatus();
        },
        findAndProcessDataInSource(source, id, callback) {
            if (!callback) {
                callback = id;
                id = source;
                source = this.treeData[0];
            }
            source.id === id
                ? callback(source)
                : _.find(source.children, (child) => this.findAndProcessDataInSource(child, id, callback));
        }
    }
});
</script>

<style lang="less">
.ui-fancytree > .fancytree-lastsib > .fancytree-node > .fancytree-expander {
    display: none;
}

.fancytree-container {
    padding-left: 2px; // Визуальная компенсация

    & > li > ul {
        padding-left: 4px; // Компенасция удаления fancytree-expander у первого элемента
    }
}

mark,
.mark {
    padding: 1px;
    color: #ffffff;
    border-radius: 2px;
    background-color: #333333;
}

.fancytree-active {
    background-color: transparent;

    .fancytree-title {
        background-color: transparent;
    }
}
</style>
