var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.typeData
    ? _c("div", { staticClass: "navigation__control" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-default",
            attrs: { type: _vm.type === "save" ? "submit" : "button" }
          },
          [
            _vm.typeData.icon
              ? _c("i", { staticClass: "material-icons" }, [
                  _vm._v(_vm._s(_vm.typeData.icon))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.typeData.title
              ? _c("span", [
                  _vm._v(
                    "\n            " + _vm._s(_vm.typeData.title) + "\n        "
                  )
                ])
              : _vm._t("default")
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-602cbeac", { render: render, staticRenderFns: staticRenderFns })
  }
}