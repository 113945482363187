export class Tag extends sm.Model {
    constructor(data) {
        super(data);
        this.id = `${this.name}.${this.colorName}`;
        this.tagClass = `tag bg-${this.colorName}-imp`;
    }
}

class TagService extends sm.NoCacheService {
    constructor() {
        super(...arguments);
        this.Model = Tag;
    }

    fetch({templateId, searchParams, ...params}) {
        if (templateId) {
            const url = `/api/webui/sm/v1/sla-calculator/${templateId}/events/tags?` + $.param({...params});
            return $
                .ajax({
                    url,
                    method: 'POST',
                    contentType: 'application/json',
                    data: JSON.stringify({
                        ...searchParams
                    })
                })
                .then((data) => {
                    return _.map(this.parse(data), (elem) => this.make(elem));
                });
        } else {
            const url = `/api/webui/pl/v1/tags`;
            return $
                .ajax({
                    url,
                    method: 'GET',
                    contentType: 'application/json',
                    data: params
                })
                .then((data) => {
                    return _.map(this.parse(data), (elem) => this.make(elem));
                });
        }
    }

    makeUrl() {
        return `/api/webui/pl/v1/tags`;
    }

    getColors() {
        return [
            {
                colorTitle: __('old-main.events-of-the-period.tag-adding-dialog.tag-colors.pink'),
                colorName: 'pink'
            },
            {
                colorTitle: __('old-main.events-of-the-period.tag-adding-dialog.tag-colors.orange'),
                colorName: 'orange'
            },
            {
                colorTitle: __('old-main.events-of-the-period.tag-adding-dialog.tag-colors.light-green'),
                colorName: 'green'
            },
            {
                colorTitle: __('old-main.events-of-the-period.tag-adding-dialog.tag-colors.dark-green'),
                colorName: 'teal'
            },
            {
                colorTitle: __('old-main.events-of-the-period.tag-adding-dialog.tag-colors.blue'),
                colorName: 'blue'
            },
            {
                colorTitle: __('old-main.events-of-the-period.tag-adding-dialog.tag-colors.dark-blue'),
                colorName: 'indigo'
            },
            {
                colorTitle: __('old-main.events-of-the-period.tag-adding-dialog.tag-colors.purple'),
                colorName: 'violet'
            },
            {
                colorTitle: __('old-main.events-of-the-period.tag-adding-dialog.tag-colors.brown'),
                colorName: 'brown'
            },
            {
                colorTitle: __('old-main.events-of-the-period.tag-adding-dialog.tag-colors.gray'),
                colorName: 'slate'
            }
        ];
    }
}

export var tagService = new TagService();

export class EventTagService extends sm.NoCacheService {
    constructor() {
        super({
            singleton: true
        });
        this.Model = Tag;
    }

    makeUrl({eventId} = {}) {
        return `/api/webui/sm/v1/events-history/${eventId}/tags`;
    }

    create(eventId, tag) {
        this.setUrlParams({eventId});
        return super.create(tag);
    }

    delete(eventId, tag) {
        this.setUrlParams({eventId});
        delete tag.element; // из-за JSON.stringify
        return super.deleteObject(tag);
    }
}

export class UserFavouriteTagService extends sm.NoCacheService {
    constructor() {
        super(...arguments);
        this.Model = Tag;
    }

    makeUrl({userId}) {
        return `/api/webui/pl/v1/tags/users/${userId}/favourite-tags`;
    }

    delete(tag) {
        return super.deleteObject(tag);
    }
}
