var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-container input-container_password" },
    [
      _vm.showInput
        ? [
            _c(
              "sm-labeled-node",
              { attrs: { label: _vm.showInputLabel } },
              [_vm._t("default")],
              2
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.valueExists,
                    expression: "valueExists"
                  }
                ],
                key: 1,
                staticClass: "sm-button sm-button_text",
                attrs: { disabled: _vm.disabled, type: "button" },
                on: {
                  click: function($event) {
                    return _vm.$emit("password-toggle", false, $event)
                  }
                }
              },
              [_c("span", [_vm._v(_vm._s(_vm.stayTheSameLabel))])]
            )
          ]
        : _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.valueExists,
                  expression: "valueExists"
                }
              ],
              key: 2,
              staticClass:
                "sm-button sm-button_full-width sm-button_field-style toogle-password-true",
              attrs: { disabled: _vm.disabled, type: "button" },
              on: {
                click: function($event) {
                  return _vm.$emit("password-toggle", true)
                }
              }
            },
            [
              _c("i", { staticClass: "material-icons" }, [_vm._v("vpn_key")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.changeLabel))])
            ]
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-77dc65b6", { render: render, staticRenderFns: staticRenderFns })
  }
}