var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type
    ? _c("i", { staticClass: "material-icons config-item-type-icon" }, [
        _vm._v(_vm._s(_vm.type))
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-61996baa", { render: render, staticRenderFns: staticRenderFns })
  }
}