var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.opts.data
        ? _c(
            "sm-select",
            _vm._b(
              {
                attrs: { value: _vm.value, multiple: _vm.multiple },
                on: {
                  input: function($event) {
                    return _vm.$emit("input", $event)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "option",
                      fn: function(props) {
                        return [
                          _c("sm-ci-icon", {
                            key: props.option.icon.name,
                            attrs: {
                              ci: {
                                configItemType: { icon: props.option.icon }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.getTranslate(props.option.name)) +
                                "\n            "
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "tag",
                      fn: function(ref) {
                        var option = ref.option
                        var remove = ref.remove
                        return [
                          _c(
                            "span",
                            { staticClass: "multiselect__tag" },
                            [
                              _c("sm-ci-icon", {
                                key: option.icon.name,
                                attrs: {
                                  ci: { configItemType: { icon: option.icon } }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(option.name) +
                                    "\n                "
                                )
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "multiselect__tag-icon",
                                on: {
                                  click: function($event) {
                                    return remove(option)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2344178834
                )
              },
              "sm-select",
              _vm.opts,
              false
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-29eb339c", { render: render, staticRenderFns: staticRenderFns })
  }
}