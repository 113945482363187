<template>
    <span v-if="!data.dateEnd" :class="[cls]" class="label duration-label_wrapper blink-opacity">
        <span class="duration-label">{{ durationStr }}</span>
    </span>
    <span v-else>{{ durationStr }}</span>
</template>

<script>
export default Vue.component('SmEventDuration', {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            cls: `priority-bg-color-${this.data.priority ? this.data.priority : this.data.status.value.toLowerCase()}`,
            durationStr: moment.duration(this.data.duration, 'seconds').format(sm.constants.durationFormat)
        };
    }
});
</script>
