var $dispatcher = $({});

sm.Module = class {
    on() {
        $dispatcher.on(...arguments);
    }

    off() {
        $dispatcher.off(...arguments);
    }

    trigger() {
        $dispatcher.trigger(...arguments);
    }
};
