<template>
    <a v-if="ci" v-bind="attrs" :href="'/configItem.html?id=' + ci.id" target="_blank" :title="ci.name">
        <sm-ci-name :ci="ci"/>
    </a>
    <span v-else v-html="__('old-main.events-of-the-period.no-parent-label')"/>
</template>

<script>
// ссылка на страницу КЕ
export default Vue.component('SmCiAnchor', {
    props: {
        ci: {
            type: Object
        }
    },
    computed: {
        attrs() {
            return _.omit(this.$attrs, ['title', 'href']);
        }
    }
});
</script>
