<template>
    <div>
        <table ref="table" :class="[cls]" class="table table-hover"/>
        <div ref="headerSlotWrapper" class="header hidden">
            <slot name="header"/>
        </div>
        <div ref="footerSlotWrapper" class="footer hidden">
            <slot name="footer"/>
        </div>

        <portal :to="getStdBtnUid('export')">
            <sm-datatable-btn :type="{cls: 'download'}" :disabled="loading" class="dropdown">
                <sm-dropdown slot="custom-content" :external-parent="true" :title="__('old-main.events-of-the-period.export-button')">
                    <ul>
                        <li>
                            <a href="#" class="export-proxy-btn" data-type="csv">CSV</a>
                        </li>
                        <li>
                            <a href="#" class="export-proxy-btn" data-type="excel">Excel</a>
                        </li>
                    </ul>
                </sm-dropdown>
            </sm-datatable-btn>
        </portal>
        <portal :to="getStdBtnUid('shortPaging')">
            <div v-if="pages >= 2" :disabled="loading" class="table-paging table-paging_short">
                <a :class="{'table-paging__button_available' : page > 0 }" class="table-paging__button table-paging__button_previous" @click.prevent="page--">
                    <i class="material-icons">navigate_before</i>
                </a>
                <form class="table-paging__switch-page" @submit.prevent="loadPageUsingShortPaging">
                    <sm-number ref="shortPagingInput" :value="page+1" :options="{minimumValue: 1, maximumValue: pages}"/>
                </form>
                <span class="table-paging__text"> {{ __('common.lists.pagination.out-of-label') }} {{ pages }}</span>
                <a :class="{'table-paging__button_available' : page < pages - 1 }" class="table-paging__button table-paging__button_next" @click.prevent="page++">
                    <i class="material-icons">navigate_next</i>
                </a>
            </div>
        </portal>
        <portal :to="getStdBtnUid('paging')">
            <div v-if="pages >= 2" :disabled="loading" class="table-paging dataTables_paginate paging_simple">
                <a :class="{'table-paging__button_available' : page > 0 }" class="table-paging__button table-paging__button_previous" @click.prevent="page--">
                    <i class="material-icons">navigate_before</i>
                </a>
                    <div class="table-paging__text">{{ __('common.lists.pagination.pages-label') }}</div>
                    <form class="switch-page" @submit.prevent="loadPageUsingFooterPaging">
                        <div class="input-group input-group-xs">
                            <sm-number ref="footerPagingInput" :value="page+1" :options="{minimumValue: 1, maximumValue: pages}" class="current-page form-control"/>
                            <div class="input-group-btn">
                                <button type="submit" class="footer-paging__btn" :title="__('common.lists.pagination.enter-page-button')"><i class="material-icons">arrow_forward</i></button>
                            </div>
                        </div>
                    </form>
                    <div class="table-paging__text">&nbsp;{{ __('common.lists.pagination.out-of-label') }} {{ pages }}</div>
                <a :class="{'table-paging__button_available' : page < pages - 1 }" class="table-paging__button table-paging__button_next" @click.prevent="page++">
                    <i class="material-icons">navigate_next</i>
                </a>
            </div>
        </portal>
        <portal :to="getStdBtnUid('length')">
            <div v-if="length && opts.paging" :disabled="loading" class="datatable-footer__length">
                <label :class="{'no-length-menu': !isAnyLengthMenuItemVisible}">
                    <span>
                        {{ __('common.lists.pagination.element-count-label') }}
                    </span>
                    <select v-if="isAnyLengthMenuItemVisible" :size="opts.lengthMenu[0].length" v-model="length" class="form-control" @change="dt.page.len(length).draw()">
                        <option v-for="(lengthElem, index) in opts.lengthMenu[0]" v-show="isLengthMenuItemVisible(lengthElem)" :value="lengthElem">
                            {{ opts.lengthMenu[1][index] }}
                        </option>
                    </select>
                </label>
                <span class="table-paging__text">
                    <span v-if="isAnyLengthMenuItemVisible">
                        {{ __('common.lists.pagination.out-of-label') }}
                    </span>
                    {{ recordsDisplay }} {{ recordsDisplay === recordsTotal
                    ? ''
                    : __('common.lists.pagination.filtered-out-label').replace(' )', ` ${recordsTotal})`) }}
                </span>
            </div>
        </portal>

    </div>
</template>

<script>
// Неявно предполагается:
// - кнопки isStandardType присутствуют в единственном экземпляре
// - search и export находится в header
// При этом скрытые настоящие кнопки экспорта (на них проксируется клик) находятся в footer)
export default {
    props: {
        options: {
            type: Object,
            required: true
        },
        cls: {
            type: String
        }
    },
    data() {
        if (_.get(this.options, 'sm.owner')) {
            throw 'не используйте свойство owner, c _.cloneDeep опций возможны проблемы';
        }

        // sm.Datatable и $.datatable изменяют переданный объект настроек, options могут использоваться
        // повторно (<sm-datatable v-if :options="options"/> ), поэтому оставим оригинальные options неизменными
        var opts = _.cloneDeepWith(this.options, function(value, key) {
            if (key === 'service') {
                return value;
            }
        });
        opts.sm = opts.sm || {};
        opts.lengthMenu = opts.lengthMenu || sm.Datatable.defaultLengthMenu;

        return {
            smDt: null,
            dt: null,

            pages: 0,
            length: 0,
            start: 0,
            end: 0,
            recordsDisplay: 0,
            recordsTotal: 0,
            serverSide: false,
            loading: false,
            lastAjaxHasError: false,

            $_page: 0,
            $_search: '',
            $_selectedData: [],

            stdDtBtns: [],
            opts
        };
    },
    computed: {
        page: {
            get() {
                return this.$data.$_page;
            },
            set(val) {
                if (this.dt.page.info().page !== val) {
                    this.dt.page(val).draw('page');
                } else {
                    this.$data.$_page = val;
                }
            }
        },

        search: {
            get() {
                return this.$data.$_search;
            },
            set(val) {
                this.$data.$_search = val;
                if (this.dt.search() !== val) {
                    $(this.$refs.header)
                        .find('.dataTables_filter input')
                        .val(val)
                        .trigger('input');
                }
            }
        },

        selectedData: {
            get() {
                return this.$data.$_selectedData;
            },
            set(val) {
                if (this.hasCheckedColumn) {
                    if (!_.isEqual(_.toArray(val), _.toArray(this.smDt.getSelected().data()))) {
                        throw 'selectedData - для просмотра выбранных элементов, задать их через set нельзя';
                    }
                    this.$data.$_selectedData = val;
                }
            }
        },

        hasCheckedColumn() {
            return !!_.find(this.opts.columnDefs, (col) => col.checkboxes);
        },

        isAnyLengthMenuItemVisible() {
            return this.opts.lengthMenu[0][0] < this.recordsDisplay;
        }
    },
    watch: {
        loading: {
            handler(val) {
                this.$nextTick(function() {
                    this.$emit('loading', val);
                });
                if (val === true) {
                    this.lastAjaxHasError = false;
                }
            },
            immediate: true
        }
    },
    mounted() {
        var vm = this;

        function findSlotStdBtns(slot) {
            return _.filter(
                vm.$slots[slot][0].children,
                (vnode) => vnode.tag && _.get(vnode, 'componentInstance.isStandardType')
            );
        }

        this.stdDtBtns = findSlotStdBtns('header');
        this.stdDtBtns.push(...findSlotStdBtns('footer'));

        if (this.opts.dom) {
            throw 'вместо опции dom используйте sm-datatable-btn';
        }
        if (this.opts.sm.$table) {
            throw 'заменим все равно $table';
        }
        _.set(this.opts.sm, 'owner.$preloader', {
            addClass() {
                vm.loading = false;
            },
            removeClass() {
                vm.loading = true;
            }
        });
        _.set(this.opts.sm, 'owner.$table', $(this.$refs.table));
        if (!this.hasCheckedColumn) {
            this.selectedData = null;
        }

        $(this.$refs.table).on('preXhr.dt.initHeaderFooterLayout', (e, settings, data) => {
            if (e.target !== this.$refs.table) {
                return;
            }
            vm.loading = true;
            $(this.$refs.table).off('preXhr.dt.initHeaderFooterLayout');
            this.$refs.header = $(this.$el).find('> .dataTables_wrapper > .datatable-header')[0];
            this.$refs.footer = $(this.$el).find('> .dataTables_wrapper > .datatable-footer')[0];
            if (this.opts.sm.newDesign === true) {
                $(this.$refs.headerSlotWrapper)
                    .children()
                    .eq(0)
                    .children()
                    .prependTo(this.$refs.header);
                $(this.$refs.headerSlotWrapper).remove();
                $(this.$refs.footerSlotWrapper)
                    .children()
                    .eq(0)
                    .children()
                    .prependTo(this.$refs.footer);
                $(this.$refs.footerSlotWrapper).remove();
            }
        });

        _.each(this.opts.columnDefs, (col) => {
            if (col.createdCell) {
                var orig = col.createdCell;
                col.createdCell = function(td) {
                    var result = orig.apply(this, arguments);
                    if (result instanceof Vue) {
                        sm.utils.onDestroyNode(td, vm.$refs.table, function() {
                            result.$destroy();
                        });
                    }
                    return result;
                };
            }
        });

        if (this.opts.sm.newDesign == null) {
            this.opts.sm.newDesign = true;
        }

        this.opts.dom = `<"datatable-header"><"datatable-scroll"t><"datatable-footer"${
            this.getStdBtnByType('export') ? 'B' : ''
        }>}`;
        this.opts.paging = this.opts.paging != null ? this.opts.paging : true;
        if (_.get(this.opts, 'sm.asList')) {
            this.opts.pageLength = this.opts.pageLength || 100;
        }

        if (this.getStdBtnByType('export')) {
            this.processExportSettings(this.opts);
        }

        function refreshSelectedData() {
            if (vm.hasCheckedColumn) {
                vm.selectedData = vm.smDt
                    .getSelected()
                    .data()
                    .toArray();
            }
        }

        function refreshData() {
            _.forOwn(vm.dt.page.info(), function(val, key) {
                vm[key] = val;
            });
            vm.search = vm.dt.search();
            refreshSelectedData();
        }

        if (vm.opts.sm.service) {
            vm.opts.sm.service.onDataOutdate(this.reload);
            vm.opts.sm.isVueProcessed = true;
        }
        vm.smDt = new sm.Datatable(vm.opts);
        vm.dt = vm.smDt.datatable;

        refreshData();

        this.smDt
            .on('draw.dt', function() {
                refreshData();
                vm.loading = false;
            })
            .on('processing.dt', function(e, settings, processing) {
                // https://datatables.net/reference/event/processing
                // событие срабатывает при манипуляциях с данными (сортировка, фильтрация, добавление, удаление). Подписка на это событие была произведена в целях реализации задачи SMCORE-1484
                // т.к. есть необходимость отслеживать актуальные данные таблицы, после операций добавления/удаления строк
                vm.$emit('rowsUpdated', _.toArray(vm.dt.rows().data()));
            })
            .on('error.dt.reload', ({params}, settings, tn, msg) => {
                if (tn === 'ajax.error.custom') {
                    this.lastAjaxHasError = true;
                }
            });

        if (this.hasCheckedColumn) {
            this.smDt
                .on('select.dt', function(e) {
                    refreshSelectedData();
                })
                .on('deselect.dt', function(e) {
                    refreshSelectedData();
                });
        }

        $(this.$refs.header)
            .find('.dataTables_filter input')
            .on(
                'input',
                _.debounce(function() {
                    if (this.value === _.trim(this.value)) {
                        vm.dt.search(this.value).draw();
                    } else {
                        $(this)
                            .val(_.trim(this.value))
                            .trigger('input');
                    }
                }, sm.constants.onTypingTimeout)
            );

        if (this.getStdBtnByType('export')) {
            $(this.$refs.header).on('click', '.export-proxy-btn', (e) => {
                e.preventDefault();
                $(this.$refs.footer)
                    .find(`.buttons-${$(e.target).data('type')}.buttons-html5`)
                    .click();
            });
        }
    },
    destroyed() {
        if (_.get(this, 'opts.sm.service', {}) instanceof sm.NoCacheService) {
            this.opts.sm.service.offDataOutdate(this.reload);
        }
        this.smDt.destroy();
    },
    methods: {
        // callback перетирается внутри smDt.reload
        reload(callback, resetPaging = true) {
            var dfd = this.smDt.reload(callback, resetPaging);
            if (_.get(this.opts, 'select.style') === 'single') {
                var selectedRows = this.dt.rows({selected: true}).data();
                if (selectedRows.length) {
                    this.reselectRow(selectedRows[0].id);
                }
            }
            return dfd;
        },
        getDataByNode(node) {
            return this.dt.row($(node).closest('tr')[0]).data();
        },

        toggleRow(td, isEnabled) {
            $(td)
                .closest('tr')
                .toggleAttr('disabled', !isEnabled);
            this.dt.cell(td).checkboxes.disable(!isEnabled);
        },

        // .always этого метода сработает после окончания ajax запроса action, но до старта ajax reload
        _toggleSelected({action, actionText, pluralObj}) {
            if (!this.hasCheckedColumn) {
                throw 'таблица не имеет столбца checked';
            }
            var data = this.selectedData;
            if (!data || !data.length) {
                sm.utils.showWarning(`Выберите ${pluralObj} для ${actionText}`);
                return;
            }
            this.loading = true;
            return action(data).fail(() => {
                this.loading = false;
                sm.utils.showError(__('common.alerts.state-save-failed'));
            });
        },

        removeSelected({smDtDst, pluralObj}, options) {
            var vm = this;
            return this._toggleSelected({
                pluralObj,
                action(data) {
                    // удаление данных из локального сервиса для таблицы автоматом синхронно перерисует таблицу и deselect не сработает
                    // (а нужен, чтобы в случае повторного добавления, запись не была чекнута автоматом)
                    if (!vm.serverSide) {
                        vm.dt.rows({selected: true}).deselect();
                    }

                    return (vm.serverSide ? vm.opts.sm.service : vm.smDt.services[0])
                        .deleteMultiple(data, options)
                        .done(function() {
                            if (vm.serverSide) {
                                vm.dt.rows({selected: true}).deselect();
                            }
                            smDtDst.reload();
                        });
                },
                actionText: 'удаления'
            });
        },

        addSelectedToService({service, pluralObj, data}, opts) {
            var vm = this;
            return this._toggleSelected({
                pluralObj,
                action(selectedData) {
                    return service.createMultiple(data || selectedData, opts).done(function() {
                        vm.dt.rows({selected: true}).deselect();
                        vm.reload();
                    });
                },
                actionText: 'добавления'
            });
        },

        ensureRemove({action, cases, name}) {
            var selectedData = this.hasCheckedChildren ? this.selectedData : this.dt.rows({selected: true}).data();
            if (!selectedData.length) {
                sm.utils.showWarning(`${_.upperFirst(sm.utils.pluralize(selectedData.length, cases))} для удаления`);
                return;
            }
            if (!name) {
                var elem = selectedData[0];
                if (elem.name) {
                    name = 'name';
                } else if (elem.title) {
                    name = 'title';
                } else {
                    throw 'определите name';
                }
            }

            const separateNames = (names) => `<b>${names.join(', ')}</b>`;
            let question = '';
            if (cases.nom === 'правило') {
                question = __('rules-actions.rule-deleting-dialog').replace(
                    '{name}',
                    separateNames(_.map(selectedData, name))
                );
            } else if (cases.nom === 'действие') {
                question = __('rules-actions.action-deleting-dialog').replace(
                    '{name}',
                    separateNames(_.map(selectedData, name))
                );
            } else if (cases.nom === 'рабочая группа') {
                question = __('data-streams.stream-details.access-tab.access-remove-confirm')
                    .split(' | ')
                    [Number(selectedData.length > 1)].replace('{name}', separateNames(_.map(selectedData, name)));
            } else if (cases.nom === 'отчет') {
                question = __('sla.deletion-dialog').replace('{name}', separateNames(_.map(selectedData, name)));
            } else if (cases.nom === 'роль') {
                question = __('sla.deletion-dialog').replace('{name}', separateNames(_.map(selectedData, name)));
            } else if (cases.nom === 'рассылку') {
                question = __('sla.deletion-dialog').replace('{name}', separateNames(_.map(selectedData, name)));
            } else if (cases.nom === 'пользователя') {
                question = __('sla.deletion-dialog').replace('{name}', separateNames(_.map(selectedData, name)));
            } else {
                console.debug('Отсутствует перевод для подтвреждения удаления:', cases.nom);
                question = `Точно удалить ${sm.utils.pluralize(selectedData.length, cases, true)} ${separateNames(
                    _.map(selectedData, name)
                )}?`;
            }
            sm.utils.showConfirm({
                question,
                onAgree: ($noty) => {
                    $.when(action()).then(() => $noty.close());
                },

                onDecline($noty) {
                    $noty.close();
                }
            });
        },

        processExportSettings() {
            var self = this;

            var exportOptions = _.merge(
                {
                    columns: [
                        (id, data, node) => {
                            var columnData = this.dt.settings()[0].aoColumns[id];
                            return columnData.title != null && this.smDt.isColumnDataVisible(columnData);
                        }
                    ]
                },
                this.opts.sm.exportOptions
            );

            function emulateButton(buttonApi, buttonCtx) {
                return _.extend(_.omit(buttonApi, ['exportOptions', 'action']), buttonCtx, {
                    title: null,
                    exportOptions,
                    filename: self.opts.sm.exportFilename,

                    // @see https://stackoverflow.com/questions/32692618/how-to-export-all-rows-from-datatables-using-ajax
                    action(e, dt, button, config) {
                        var self = this;
                        var oldStart = dt.settings()[0]._iDisplayStart;
                        dt.one('preXhr', function(e, s, data) {
                            data.start = 0;
                            data.length = -1;

                            dt.one('preDraw', function(e, settings) {
                                if (buttonApi.available(dt, config)) {
                                    buttonApi.action.call(self, e, dt, button, config);
                                } else {
                                    sm.utils.showError('Экспорт невозможен!');
                                    return;
                                }

                                dt.one('preXhr', function(e, s, data) {
                                    settings._iDisplayStart = oldStart;
                                    data.start = oldStart;
                                });
                                setTimeout(dt.ajax.reload, 0);
                                return false;
                            });
                        });
                        dt.ajax.reload();
                    }
                });
            }

            this.opts.buttons = [
                emulateButton($.fn.dataTable.ext.buttons.csvHtml5, {bom: true}),
                emulateButton($.fn.dataTable.ext.buttons.excelHtml5, {})
            ];
        },

        getStdBtnUid(type) {
            if (!this.stdDtBtns.length) {
                return '';
            }
            return _.get(this.getStdBtnByType(type), 'componentInstance.uid', '');
        },

        getStdBtnByType(type) {
            return _.filter(this.stdDtBtns, {componentInstance: {type}})[0];
        },

        isLengthMenuItemVisible(lengthElem) {
            var menuItems = this.opts.lengthMenu[0];
            if (lengthElem === -1) {
                return this.recordsDisplay > menuItems[0] && !_.some(menuItems, (item) => item === this.recordsDisplay);
            }
            return lengthElem > 0 && lengthElem <= this.recordsDisplay;
        },

        reselectRow(id, isImmediately) {
            var reselect = () => {
                var row = this.dt.row((rowId, data) => data.id === id);
                if (row.length) {
                    row.select();
                    if (_.get(this, 'opts.sm')) {
                        this.opts.sm.onSelect(row.data());
                    }
                } else {
                    console.debug(`Невозможно выбрать данные с идентификатором ${id}`);
                }
            };

            if (isImmediately === true) {
                reselect();
            } else {
                this.smDt.one('draw.dt', reselect);
            }
        },

        loadPageUsingShortPaging() {
            var page = +this.$refs.shortPagingInput.$el.value - 1;
            if (page >= 0) {
                this.page = page;
            }
        },

        loadPageUsingFooterPaging() {
            var page = +this.$refs.footerPagingInput.$el.value - 1;
            if (page >= 0) {
                this.page = page;
            }
        }
    }
};
</script>

<style lang="less">
// Паджинация в таблицах
@data-table_icon-color: #687189;

.table-paging {
    // .layout-row.layout-align-center-center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    flex-wrap: nowrap;
    float: none;
    margin: 0;
    color: #263238;
    height: 100%;
    transition: 0.3s ease width;
    min-height: 40px;

    &.table-paging_short {
    }

    .switch-page {
        margin-left: 4px;
        margin-right: 4px;

        .input-group {
            display: flex;
        }

        .current-page {
            width: 40px;
            padding-right: 0;
            padding-left: 4px;
        }

        input {
            height: 26px;
            padding-top: 0;
            padding-bottom: 0;
            border-color: @data-table_icon-color;
        }

        .input-group-btn {
            button.footer-paging__btn {
                height: 26px;
                padding-top: 0;
                padding-bottom: 0;
                color: #fff;
                border: none;
                border-radius: 0 2px 2px 0;
                outline: none;
                background-color: @data-table_icon-color;
                box-shadow: none;

                &:hover,
                &:focus {
                    border-color: darken(@data-table_icon-color, 10%);
                    background-color: darken(@data-table_icon-color, 10%);
                }

                .material-icons {
                    font-size: 18px;
                    line-height: 26px;
                }
            }
        }
    }
}

.table-paging__button {
    // .layout-row.layout-align-center-center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    overflow: hidden;
    color: @data-table_icon-color;
    transition: 0.3s ease color, 0.05s ease transform, 0.3s ease opacity;
    height: 100%;

    &:not(.table-paging__button_available) {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.2;
    }

    .material-icons {
        font-size: 20px;

        .table-paging_short & {
            font-size: 18px;
        }
    }

    &:hover {
        opacity: 1;
        color: darken(@data-table_icon-color, 10%);
    }

    &:focus {
        color: darken(@data-table_icon-color, 20%);
    }

    &.table-paging__button_previous {
        padding-left: 8px;
        padding-right: 4px;
    }

    &.table-paging__button_next {
        padding-left: 4px;
        padding-right: 8px;
    }

    &.table-paging__button_available {
        opacity: 1;
    }
}

.table-paging__text {
    white-space: nowrap;
    color: #263238;

    .datatable-footer .datatable-footer__length & {
        & > span {
            padding-right: 2px;
            padding-left: 4px;
        }
    }
}

.datatable-footer__length {
    .no-length-menu {
        & > span {
            &:first-child {
                padding-right: 8px;
            }
        }
    }
}

.table-paging_short {
    .table-paging__switch-page {
        margin: 0;

        input {
            border: none;
            text-align: center;
            max-width: 22px;
            transition: max-width 0.3s ease;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                /* display: none; <- Crashes Chrome on hover */
                -webkit-appearance: none;
                margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
            }

            &:focus {
                background-color: #f1f1f1;
                border-radius: 3px;
                max-width: 32px;
                outline: none;
            }
        }
    }
}
// /Паджинация в таблицах
</style>
