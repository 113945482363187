<template>
    <div v-if="typeCls" :class="['datatable-header__control--' + typeCls]" class="datatable-header__control">
        <button v-if="!$slots['custom-content']" type="button" class="btn btn-default">
            <span>
                <template v-if="!$slots.default">
                    {{ (_.isString(type) ? types[type] : type).title }}
                </template>
                <template v-else>
                    <slot/>
                </template>
            </span>
        </button>
        <slot v-else name="custom-content"/>
    </div>
    <div v-else-if="type === 'search'" class="dataTables_filter">
        <div class="input-group">
            <div class="has-feedback has-feedback-left">
                <div class="form-control-feedback">
                    <i class="icon-search4 text-muted text-size-base"/>
                </div>
                <input ref="searchInput" :placeholder="!$slots.default ? __('old-main.events-of-the-period.search-placeholder') : $slots.default[0].text" type="text">
            </div>
            <div class="input-group-btn">
                <button :title="__('workgroups.notifications.creation-dialog.clear-button')" class="btn btn-default clear" @click="clearSearch">
                    <i class="icon-eraser"/>
                </button>
            </div>
        </div>
    </div>
    <portal-target v-else-if="isStandardType" :name="uid"/>
</template>

<script>
export default Vue.component('SmDatatableBtn', {
    props: {
        type: {
            type: [String, Object],
            required: true
        }
    },
    data() {
        return {
            uid: _.uniqueId('datatable-btn-'),
            types: {
                add: {
                    cls: 'add',
                    title: __('common.add-button')
                },
                remove: {
                    cls: 'remove',
                    title: __('common.delete-button')
                }
            }
        };
    },
    computed: {
        typeCls() {
            return ((_.isString(this.type) ? this.types[this.type] : this.type) || {}).cls;
        },
        isStandardType() {
            return _.includes('search export shortPaging length paging'.split(' '), this.type);
        }
    },
    mounted() {
        if (this.$listeners.click) {
            var error = 'нужен модификатор native: click.native';
            console.error(error, this.$el);
            throw error;
        }
        if (this.types[this.type] && !('disabled' in this.$attrs)) {
            console.debug('как правило, нужен :disabled - на время ajax запросов лучше отключать кнопку', this.$el);
        }
    },
    methods: {
        clearSearch() {
            var $searchInput = $(this.$refs.searchInput);
            if ($searchInput.val()) {
                $searchInput.val('').trigger('input');
            }
        }
    }
});
</script>
