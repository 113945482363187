sm.NoCacheService = class NoCacheService extends sm.Module {
    constructor(params) {
        super(...arguments);
        if (params && params.singleton) {
            this.singleton = params.singleton;
            if (this.constructor._instance) {
                return this.constructor._instance;
            }
            this.constructor._instance = this;
        }
        this.Model = this.Model || sm.Model;
        this.url = this.makeUrl(params);
        this.urls = {};
        this.types = {};
        this.serviceName = _.camelCase(sm.utils.getFunctionName(this));
        this.eventNs = `.${_.uniqueId(this.serviceName)}`;
        this.batch = (params && params.batch) || false;
        _.bindAll(this, 'make');
    }

    fetch(params = {}) {
        var self = this;
        if (params.overrideUrl === true) {
            this.setUrlParams(params);
        }
        params.overrideUrl = undefined;
        return (this.isLocal === true
            ? this.fetchLocal(...arguments)
            : $.ajax(
                  this.ensureContentType(
                      _.merge(
                          {
                              url: this.urls.fetch || this.url,
                              type: this.types.fetch || 'GET',
                              data: params
                          },
                          this.fetchOpts
                      )
                  )
              )
        ).then(function(data) {
            return _.map(self.parse(data), (elem) => self.make(elem));
        });
    }

    fetchDt(params, request, callback, settings) {
        var self = this;
        _.merge(request, params);
        if (request.overrideUrl === true) {
            this.setUrlParams(request);
        }
        request.overrideUrl = undefined;
        return (this.isLocal === true
            ? this.fetchDtLocal(...arguments)
            : $.ajax(
                  this.ensureContentType(
                      _.merge(
                          {
                              url: this.urls.datatables || `${this.url}/datatables`,
                              type: this.types.fetchDt || 'POST',
                              data: request
                          },
                          this.fetchDtOpts
                      )
                  )
              )
        )
            .then(function(data) {
                data.data = _.map(self.parse(data.data), (elem) => self.make(elem));
                return data;
            })
            .always(function(data, textStatus) {
                if (textStatus === 'error') {
                    self.emptyCallbackDt(request, callback, settings, data, textStatus, arguments[2]);
                    return;
                }
                callback(data);
            });
    }

    emptyCallbackDt(request, callback, settings, jqXHR, textStatus, errorThrown) {
        setTimeout(() => {
            var e = $.Event('error.dt');
            e.params = {
                jqXHR,
                textStatus,
                errorThrown,
                errback() {
                    callback(
                        _.merge(
                            {
                                data: [],
                                draw: 0,
                                error: null,
                                recordsFiltered: 0,
                                recordsTotal: 0
                            },
                            request
                        )
                    );
                }
            };
            $(settings.nTable).trigger(e, [settings, 'ajax.error.custom']);
        }, 0);
    }

    create(data) {
        var self = this;
        var _create = this.isLocal
            ? new $.Deferred(function(dfd) {
                  dfd.resolve(data);
              })
            : $.ajax(
                  this.ensureContentType(
                      _.merge(
                          {
                              url: self.urls.create || `${self.url}/`,
                              type: self.types.create || 'POST',
                              data
                          },
                          this.createOpts
                      )
                  )
              );

        // @see https://github.com/jquery/jquery/issues/3740
        if (_.get(this, 'createOpts.async') !== false) {
            return _create.then(function(data) {
                self.triggerDataOutdate();
                return self.make(self.parse([data])[0]);
            });
        }
        return new $.Deferred(function(dfd) {
            _create.done(function(data) {
                self.triggerDataOutdate();
                dfd.resolve(self.make(self.parse([data])[0]));
            });
        });
    }

    update(source) {
        var self = this;
        var id = source.id;

        /* eslint-disable no-empty */
        try {
            id = JSON.parse(source).id;
        } catch (e) {}
        /* eslint-enable no-empty */

        return (this.isLocal
            ? new $.Deferred(function(dfd) {
                  dfd.resolve(source);
              })
            : $.ajax(
                  this.ensureContentType(
                      _.merge(
                          {
                              url: self.urls.update || `${self.url}/${id}`,
                              type: self.types.update || 'PUT',
                              data: source
                          },
                          this.updateOpts
                      )
                  )
              )
        ).then(function(data) {
            self.triggerDataOutdate();
            return self.make(self.parse([data])[0]);
        });
    }

    patchMultiple(url, source) {
        var self = this;
        return this._patch(url + (this.batch ? '/batch' : ''), source).then(function(data) {
            self.triggerDataOutdate();
            return self.make(self.parse(data));
        });
    }

    patch(url, source, options = {silent: false}) {
        var self = this;
        return this._patch(url, source).then(function(data) {
            if (!options.silent) {
                self.triggerDataOutdate();
            }
            return self.make(self.parse([data])[0]);
        });
    }

    _patch(url, source) {
        var self = this;
        return this.isLocal
            ? new $.Deferred(function(dfd) {
                  dfd.resolve(source);
              })
            : $.ajax(
                  this.ensureContentType({
                      url,
                      type: 'PATCH',
                      data: source
                  })
              );
    }

    delete(id) {
        var self = this;
        if (this.deleteXhr) {
            return this.deleteXhr;
        }
        this.deleteXhr = this.isLocal
            ? new $.Deferred(function() {
                  this.resolve();
              })
            : $.ajax({
                  url: self.urls.delete || `${self.url}/${id}`,
                  type: self.types.delete || 'DELETE'
              });
        return this.deleteXhr
            .done(function() {
                self.triggerDataOutdate();
            })
            .fail(function(jqXHR) {
                // скорее всего, запись уже удалена кем-то
                if (jqXHR.status === 404) {
                    self.triggerDataOutdate();
                }
            })
            .always(function() {
                self.deleteXhr = null;
            });
    }

    deleteObject(obj) {
        var self = this;
        if (this.deleteObjXhr) {
            return this.deleteObjXhr;
        }
        this.deleteObjXhr = this.isLocal
            ? new $.Deferred(function() {
                  this.resolve();
              })
            : $.ajax({
                  url: self.urls.delete || self.url,
                  contentType: 'application/json',
                  data: JSON.stringify(obj),
                  type: self.types.delete || 'DELETE'
              });
        return this.deleteObjXhr
            .done(function() {
                self.triggerDataOutdate();
            })
            .fail(function(jqXHR) {
                // скорее всего, запись уже удалена кем-то
                if (jqXHR.status === 404) {
                    self.triggerDataOutdate();
                }
            })
            .always(function() {
                self.deleteObjXhr = null;
            });
    }

    getById(id, params) {
        var self = this;
        return (this.isLocal === true
            ? this.getByIdLocal(...arguments)
            : $.ajax({
                  url: `${this.urls.getById || this.url}/${id}`,
                  type: this.types.getById || 'GET',
                  data: params
              })
        ).then(function(data) {
            return self.make(self.parse([data])[0]);
        });
    }

    setUrlParams(params) {
        this.url = this.makeUrl(params);
    }

    parse(value) {
        return value;
    }

    make(data) {
        return new this.Model(data);
    }

    createNew() {
        return new this.Model({});
    }

    createMultiple(data) {
        var self = this;
        return $
            .ajax(
                this.ensureContentType(
                    _.merge(
                        {
                            url: this.urls.createMultiple || `${this.url}/${this.batch ? 'batch' : ''}`,
                            type: this.types.createMultiple || 'POST',
                            data
                        },
                        this.createOpts
                    )
                )
            )
            .done(function() {
                self.triggerDataOutdate();
            });
    }

    _deleteMultiple(data) {
        var self = this;
        return $
            .ajax(
                this.ensureContentType({
                    url: this.urls.deleteMultiple || `${this.url}${this.batch ? '/batch' : ''}`,
                    type: this.types.deleteMultiple || 'DELETE',
                    data
                })
            )
            .done(function() {
                self.triggerDataOutdate();
            });
    }

    deleteMultiple(data) {
        return this._deleteMultiple(_.map(data, 'id'));
    }

    deleteMultipleObjects(data) {
        return this._deleteMultiple(data);
    }

    ensureContentType(data) {
        if (
            data.contentType !== false &&
            data.processData !== false &&
            (data.type === 'POST' || data.type === 'PUT' || data.type === 'PATCH' || this.batch)
        ) {
            data.contentType = 'application/json';
            data.data = JSON.stringify(data.data);
        }
        if (
            (data.contentType === false && data.processData !== false) ||
            (data.contentType !== false && data.processData === false)
        ) {
            console.warn(
                'проверьте, все ли в порядке с contentType и processData - обычно они равны false одновременно'
            );
        }
        return data;
    }

    // срабатывает на всех инстансах this.serviceName
    triggerDataOutdate() {
        this.trigger(`${this.serviceName}|dataOutdate`);
    }

    onDataOutdate(callback) {
        this.on(`${this.serviceName}|dataOutdate${this.eventNs}`, callback);
    }

    offDataOutdate(callback) {
        this.off(`${this.serviceName}|dataOutdate${this.eventNs}`, callback);
    }

    fetchLocal(params) {
        var self = this;
        var json = this.fetchJsonLocal();
        json = _.cloneDeep(json);
        return new $.Deferred(function(dfd) {
            setTimeout(function() {
                dfd.resolve(json);
            }, 200);
        });
    }

    fetchDtLocal(params, request) {
        var json = this.fetchJsonLocal();
        json = _.cloneDeep(json);
        return new $.Deferred(function(dfd) {
            setTimeout(function() {
                dfd.resolve(json);
            }, 200);
        }).then(function(data) {
            return _.merge(
                {
                    data: data.slice(
                        request.start,
                        request.start + (request.length === -1 ? Number.MAX_SAFE_INTEGER : request.length)
                    ),
                    recordsFiltered: data.length,
                    recordsTotal: data.length
                },
                request
            );
        });
    }

    destroy() {
        if (this.dfd && this.dfd.abort) {
            this.dfd.abort();
        }
        this.off(`${this.serviceName}|dataOutdate${this.eventNs}`);
        if (this.singleton) {
            this.constructor._instance = null;
        }
    }
};
