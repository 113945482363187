require('src/js/services/service.js');

export class UserAllSettingService extends sm.Service {
    constructor(options) {
        options.singleton = true;
        super(...arguments);
    }

    fetchLocal() {
        return new $.Deferred(function(dfd) {
            dfd.resolve({});
        });
    }

    makeUrl(params) {
        return `/api/webui/pl/v1/users/${params.userId}/settings`;
    }

    refresh(newModel) {
        var oldModel = _.find(this.models, {key: newModel.key});
        if (oldModel) {
            oldModel.value = newModel.value;
        } else {
            this.models.push(newModel);
        }
    }
}

export class UserSettingService extends sm.Service {
    constructor(options) {
        if (options.key == null) {
            throw 'Необходим ключ';
        }
        if (options.userId == null) {
            throw 'Необходим id пользователя';
        }
        super(...arguments);
        this._lastId = 0;
        this.key = options.key;
        this.userId = options.userId;
    }

    getKey() {
        return this.key;
    }

    fetch() {
        if (!this.fetchDfd) {
            this.fetchDfd = new $.Deferred();
            this._fetch(...arguments);
        }
        return this.fetchDfd;
    }

    _fetch() {
        var self = this;
        if (UserAllSettingService._instance == null) {
            throw 'Сначала получите все настройки пользователя!';
        }
        if (this.dfd) {
            return this.dfd;
        }
        this.dfd = new $.Deferred();
        this.loaded = true;
        this.prevUrl = this.url;
        this.dfd
            .resolve(_.find(UserAllSettingService._instance.models, {key: this.key}))
            .done(function(data) {
                self.models = [];
                self._processData(data);
            })
            .fail(function() {
                console.warn('service fetch fails', arguments);
            });
        return this.dfd;
    }

    parse(data) {
        var self = this;
        data = data || {};
        if (data.value && data.value.length > 0) {
            this._lastId = Math.max(
                ..._.map(data.value, function(elem) {
                    return elem.id;
                })
            );
            setTimeout(function() {
                self.fetchDfd.resolve();
            }, 0);
            return data.value;
        }
        this.createInitialValue();
        return [];
    }

    create(data, opts) {
        var self = this;
        data.id = ++this._lastId;
        if (opts && opts.createSetting === true) {
            return (opts.type === 'POST'
                ? $.ajax({
                      type: 'POST',
                      url: `/api/webui/pl/v1/users/${this.userId}/settings`,
                      contentType: 'application/json',
                      data: JSON.stringify({
                          key: this.key,
                          value: [data]
                      })
                  })
                : this._updateAll([data])
            )
                .done(function() {
                    self.models.push(new self.Model(data));
                })
                .fail(function() {
                    console.warn('не могу создать UserSetting');
                });
        }
        var models = _.cloneDeep(this.models);
        models.push(data);
        return this._updateAll(models)
            .done(function() {
                self.models.push(new self.Model(data));
                UserAllSettingService._instance.refresh(self.stringify());
            })
            .fail(function() {
                console.warn('не могу добавить UserSetting');
            });
    }

    update(data) {
        var self = this;
        var models = _.cloneDeep(this.models);
        var index = _.findIndex(models, {id: data.id});
        models.splice(index, 1, data);
        return this._updateAll(models)
            .done(function() {
                var elem = self.getById(data.id);
                _.each(new self.Model(data), function(propValue, propName) {
                    elem[propName] = propValue;
                });
                UserAllSettingService._instance.refresh(self.stringify());
            })
            .fail(function() {
                console.warn('не могу изменить UserSetting');
            });
    }

    delete(id) {
        var self = this;
        var models = _.cloneDeep(this.models);
        _.remove(models, {id});
        return this._updateAll(models)
            .done(function() {
                _.remove(self.models, self.getById(id));
                UserAllSettingService._instance.refresh(self.stringify());
            })
            .fail(function() {
                console.warn('не могу удалить UserSetting');
            });
    }

    stringify() {
        return {
            key: this.key,
            value: this.models
        };
    }

    _updateAll(data) {
        return $.ajax({
            url: `/api/webui/pl/v1/users/${this.userId}/settings/${this.key}`,
            type: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify({
                value: data
            })
        });
    }

    _clear() {
        var self = this;
        $.ajax({
            type: 'DELETE',
            url: `/api/webui/pl/v1/users/${this.userId}/settings/${this.key}`
        })
            .done(function() {
                self.models = [];
            })
            .fail(function() {
                console.warn('service clear fails', arguments);
            });
    }

    makeUrl(params) {
        return `/api/webui/pl/v1/users/${this.userId}/settings/${this.key}`;
    }
}

export class SingleValueSettingService extends UserSettingService {
    constructor({initialValue}) {
        super(...arguments);
        this.initialValue = initialValue;
    }

    createInitialValue() {
        var self = this;
        this.create(this.initialValue, {
            createSetting: true,
            type: 'POST'
        }).done(function() {
            self.fetchDfd.resolve();
        });
    }

    getValue() {
        return this.models[0];
    }
}
