import {UserAllSettingService} from 'src/js/services/settings/userSettingService.js';
import Auth from 'src/js/auth.js';
import i18n from 'src/js/services/i18n.js';

if (sm.apiHost === 'production') {
    $('body').addClass('warning-production');
}

sm.Page = class {
    constructor(options) {
        _.extend(this, options);
        this.getResources();
    }

    initVue() {
        this.vueInstance = new Vue({
            el: '#app',
            comments: true,
            components: !this.isVue
                ? {}
                : {
                      [`sm-page-${_.kebabCase(sm.utils.getPageName())}`]: () => ({
                          component: import(`./pages/${sm.utils.getPageName()}/index.vue`),
                          loading: {
                              template:
                                  '<div class="content show"><i class="icon-spinner spinner position-right preloader"/></div>'
                          },
                          error: {
                              template: '<div class="content"/>',
                              mounted: this.onFailGetResources
                          }
                      })
                  }
        });
        this.vueInstance.$nextTick(function() {
            $(window).trigger('resize');
        });
        if (!this.isVue) {
            this.$container = $('.page-container');
        }
    }

    processLayout() {
        var self = this;

        function containerHeight() {
            var availableHeight =
                $(window).height() -
                ($('body > #app > .navbar').outerHeight() || 0) -
                ($('body > .navbar-fixed-top:not(.navbar)').outerHeight() || 0) -
                ($('body > .navbar-fixed-bottom:not(.navbar)').outerHeight() || 0) -
                ($('body > .navbar + .navbar').outerHeight() || 0) -
                ($('body > .navbar + .navbar-collapse').outerHeight() || 0);

            $('.page-container, #app').attr('style', `min-height:${availableHeight}px`);
        }

        // актуализация состояния панелей на момент загрузки страницы
        $(document)
            .find('.panel-collapsed')
            .children('.panel-heading')
            .nextAll()
            .hide();
        $(document)
            .find('.panel-collapsed')
            .find('[data-action=collapse]')
            .addClass('rotate-180');

        // Collapse on click
        $(document).on('click', '.panel>.panel-heading [data-action=collapse]', function(e) {
            e.preventDefault();
            var $panelCollapse = $(this)
                .closest('.panel-heading')
                .nextAll();
            var $panel = $(this).closest('.panel');
            $panel.toggleClass('panel-collapsed');
            $(this).toggleClass('rotate-180');

            containerHeight();
            $panelCollapse.slideToggle(150, function() {
                $(window).trigger('resize');
            });

            self.vueInstance.$root.$emit('app-panelCollapsed', {
                $panel,
                isCollapsed: $panel.hasClass('panel-collapsed')
            });
            $(window).trigger('resize');
        });

        $(window)
            .on('resize', function() {
                setTimeout(containerHeight, 100);
            })
            .resize();
    }

    static onDefaultFailGetResources() {
        sm.utils.showError(__('failedGetPageData'));
    }

    onFailGetResources() {
        sm.Page.onDefaultFailGetResources();
    }

    async getResources() {
        const auth = new Auth();
        await auth.init();
        await i18n();
        const userAllSettingService = new UserAllSettingService({
            userId: sm.systemUser.id
        });
        await userAllSettingService.fetch();
        this.initVue();
        this.processLayout();
    }
};
