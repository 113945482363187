<template>
    <i v-if="type" class="material-icons config-item-type-icon">{{ type }}</i>
</template>

<script>
export default Vue.component('SmCiIcon', {
    props: {
        ci: {
            type: Object,
            required: true
        }
    },
    computed: {
        type() {
            return _.get(this.ci, 'configItemType.icon.name');
        }
    }
});
</script>

<style>
.config-item-type-icon {
    color: #333333;
}
</style>
