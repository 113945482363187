var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "monq-sidebar-wrapper" }, [
    _c(
      "nav",
      { staticClass: "monq-sidebar", attrs: { id: "main-sidebar-menu" } },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "monq-sidebar__menu" },
          [
            _c("perfect-scrollbar", { staticClass: "ps" }, [
              _c("div", { staticClass: "ps-content" }, [
                _c(
                  "ul",
                  { staticClass: "menu-list menu-list--top" },
                  [
                    _vm._l(_vm.menuItemsTop, function(item, idx) {
                      return [
                        _c(
                          "li",
                          { key: idx, staticClass: "aim-item" },
                          [
                            _c("SmSidebarItem", {
                              attrs: { index: idx, menuItem: item },
                              on: {
                                onMenuInit: function($event) {
                                  return _vm.childMenuInit()
                                },
                                onMouseEnter: _vm.mouseItemEnter
                              }
                            })
                          ],
                          1
                        )
                      ]
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "menu-list menu-list--bottom" },
                  [
                    _vm._l(_vm.menuItemsBottom, function(item, idx) {
                      return [
                        _c(
                          "li",
                          { key: idx, staticClass: "aim-item" },
                          [
                            _c("SmSidebarItem", {
                              attrs: {
                                index: idx + _vm.menuItemsTop.length,
                                menuItem: item
                              },
                              on: {
                                onMenuInit: _vm.childMenuInit,
                                onMouseEnter: _vm.mouseItemEnter
                              }
                            })
                          ],
                          1
                        )
                      ]
                    })
                  ],
                  2
                )
              ])
            ])
          ],
          1
        ),
        _vm._v(" "),
        [
          _c("div", { staticClass: "aim-item" }, [
            _c(
              "div",
              { staticClass: "monq-sidebar__user" },
              [
                _vm.menuItemProfile
                  ? _c("SmSidebarItem", {
                      attrs: {
                        index:
                          _vm.menuItemsTop.length + _vm.menuItemsBottom.length,
                        menuItem: _vm.menuItemProfile
                      },
                      on: {
                        onMenuInit: _vm.childMenuInit,
                        onMouseEnter: _vm.mouseItemEnter
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ]
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "monq-sidebar__logo logo", attrs: { href: "/" } },
      [
        _c("picture", { staticClass: "logo__icon" }, [
          _c("img", {
            attrs: {
              alt: "Логотип компании",
              width: "64",
              height: "64",
              src: "/api/webui/pl/v1/userspaces/current/logo?size=1",
              srcset:
                "/api/webui/pl/v1/userspaces/current/logo?size=2" +
                " 2x, " +
                "/api/webui/pl/v1/userspaces/current/logo?size=3" +
                " 3x`"
            }
          })
        ])
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7aa91ac8", { render: render, staticRenderFns: staticRenderFns })
  }
}