<template>
    <btn v-bind="$props" v-model="model"><slot/></btn>
</template>

<script>
import {Btn, BtnGroup} from 'uiv';

Vue.component('SmBtnGroup', BtnGroup);

/**
 * uiv - vue компоненты в стиле boostrap 3
 * @see https://uiv.wxsm.space/
 * @see https://github.com/wxsms/uiv
 */
export default Vue.component('SmBtn', {
    props: Btn.props,
    components: {
        btn: Btn
    },
    computed: {
        /**
         * @see https://uiv.wxsm.space/button#checkbox-radio в качестве модели принимает массив,
         * что неудобно в случае единственного чекбокса, добавлена возможность для чекбокса
         * передавать boolean. Нужно, например, в правилах и действиях, кнопка НЕ (селектор отрицания условия)
         */
        model: {
            get() {
                if (this.inputType === 'checkbox' && _.isBoolean(this.value)) {
                    return this.value === false ? [] : [this.value];
                } else {
                    return this.value;
                }
            },
            set(val) {
                if (this.inputType === 'checkbox' && _.isBoolean(this.value)) {
                    this.$emit('input', !!val.length);
                } else {
                    this.$emit('input', val);
                }
            }
        }
    }
});
</script>
