import {UserPacketsHttpService} from './userPacketsHttpService.js';
import {UserProfileHttpService} from './userProfileHttpService.js';

export class UserGrantsService {
    userProfileService = new UserProfileHttpService();
    userPacketsService = new UserPacketsHttpService();
    constructor() {}

    async getCurrentProfileGrants() {
        const currentProfile = await this.userProfileService.getCurrentProfile();
        const userPackets = await this.userPacketsService.getUserPackets(currentProfile.id);

        const packetsGrants = userPackets.map((packet) => packet.grants);
        return packetsGrants.reduce((acc, val) => acc.concat(val), []);
    }

    hasUserGrant(needGrants, userGrants) {
        for (const grant of needGrants) {
            const search = this.createSearchGrantRegex(grant);
            const result = userGrants.some((userGrant) => userGrant.match(search));
            if (result) return true;
        }
        return false;
    }

    createSearchGrantRegex(grant) {
        return new RegExp(`^${grant.replace('.', '\\.').replace('*', '.*')}$`);
    }
}
