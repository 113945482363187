var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "json-viewer",
    _vm._g(
      _vm._b(
        {
          attrs: { value: _vm.innerModel },
          on: {
            input: function($event) {
              _vm.innerModel = $event
            }
          }
        },
        "json-viewer",
        _vm.attrs,
        false
      ),
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-72fb8474", { render: render, staticRenderFns: staticRenderFns })
  }
}