<template>
    <label :class="['switchery-'+size, {'checkbox-inline': nodesCount <= 2, 'checkbox-switchery': nodesCount <= 2, 'switchery-double': nodesCount > 2}]"
           class="vue-switchery-wrapper control-label" @click.capture="ensureValueAndSingleClick">
        <slot>
            <input type="checkbox">
        </slot>
        <span v-if="nodesCount < 2">&nbsp;</span>
        {{ label }}
    </label>
</template>

<script>
export default Vue.component('SmSwitchery', {
    props: {
        value: {
            type: Boolean,
            required: true
        },
        options: {
            type: Object,
            default() {
                return {
                    color: sm.constants.switcheryDefaultColor
                };
            }
        },
        size: {
            type: String,
            default: 'xs'
        },
        label: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            switchery: null,
            $_canBubble: false
        };
    },
    computed: {
        nodesCount() {
            return this.$slots.default ? this.$slots.default.length : 0;
        }
    },
    watch: {
        disabled(val) {
            this.switchery[val ? 'disable' : 'enable']();
        },
        checked() {
            if (this.$attrs.disabled != null) {
                throw 'нельзя менять значение switchery, когда оно disabled';
            }

            // фильтруем изменение модели по клику
            if (!this.$data.$_canBubble) {
                this.$refs.checkbox.checked = this.value;
                sm.utils.dispatchMouseEvent(this.$refs.checkbox, 'change');
            }
        }
    },
    destroyed() {
        this.switchery.destroy();
    },
    mounted() {
        this.$refs.checkbox = $(this.$el).find('input')[0];
        this.$nextTick(function() {
            this.$refs.checkbox.checked = this.value;
            this.switchery = new Switchery(this.$refs.checkbox, this.options);
        });
    },
    methods: {
        // корректная обработка клика на input в связи со всплытием события до label
        ensureValueAndSingleClick(e) {
            if (this.$data.$_canBubble === true) {
                return;
            }
            e.preventDefault();
            e.stopImmediatePropagation();
            this.$data.$_canBubble = true;
            this.$emit('input', !this.$refs.checkbox.checked);
            sm.utils.dispatchMouseEvent(this.$refs.checkbox, 'click');
            this.$nextTick(function() {
                this.$data.$_canBubble = false;
            });
        }
    }
});
</script>

<style lang="less">
.vue-switchery-wrapper {
    cursor: pointer;
}
</style>
