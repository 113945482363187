<template>
    <span class="ci-name"><sm-ci-icon :ci="ci"/><span class="ci-name__text">{{ ci.name }}</span></span>
</template>

<script>
export default Vue.component('SmCiName', {
    props: {
        ci: {
            type: Object,
            required: true
        }
    }
});
</script>
