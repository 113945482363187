<template>
    <span :class="[priorityCls]" class="label priority-label_wrapper"><span class="priority-label">{{ priorityTitle }}</span></span>
</template>

<script>
import SynthTriggerService from 'src/js/services/synthTriggerService.js';

export default Vue.component('SmPriorityLabel', {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        var statusData = SynthTriggerService.getStatusesData()[_.get(this.data, 'status.value')];
        return {
            priorityCls: `priority-bg-color-${this.data.priority ? this.data.priority : statusData.value}`,
            priorityTitle: this.data.priority ? this.data.priority : statusData.title
        };
    }
});
</script>
