export default class SynthTriggerService extends sm.NoCacheService {
    constructor(opts) {
        super(opts);
        this.urls.datatables = `${this.url}/filter/datatables`;
        this.urls.createMultiple = this.urls.deleteMultiple = `${this.url}/config-items/batch`;
        this.batch = true;
    }

    makeUrl() {
        return '/api/webui/sm/v1/synthetic-triggers';
    }

    createMultiple(triggers, ci) {
        var data = _.map(triggers, (elem) => ({
            trigger: {
                id: elem.id
            },
            configItem: {
                id: ci.id
            }
        }));
        return super.createMultiple(data);
    }

    deleteMultiple(triggers, ci) {
        var data = _.map(triggers, (elem) => ({
            trigger: {
                id: elem.id
            },
            configItem: {
                id: ci.id
            }
        }));
        return this.deleteMultipleObjects(data);
    }

    static make(data) {
        return new SynthTrigger(data);
    }

    static getStatusesData() {
        return statusesData;
    }

    static isSyntheticTrigger(event) {
        return !!event.trigger;
    }
}

var statusesData = {
    Ok: {value: 0, title: 'Ок', backendEnumValue: 'Ok'},
    Problem1: {value: 1, title: 1, backendEnumValue: 'Problem1'},
    Problem2: {value: 2, title: 2, backendEnumValue: 'Problem2'},
    Problem3: {value: 3, title: 3, backendEnumValue: 'Problem3'},
    Problem4: {value: 4, title: 4, backendEnumValue: 'Problem4'},
    Disabled: {value: -1, title: 'Выкл', backendEnumValue: 'Disabled'}
};
