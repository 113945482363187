var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "panel",
      staticClass: "panel panel-default",
      class: {
        "panel-collapsed": _vm.collapsed,
        "is-loading": _vm.loading,
        "sm-widget": _vm.hasContainerClass
      }
    },
    [
      !_vm.$slots["custom-heading"]
        ? _c(
            "div",
            { staticClass: "panel-heading" },
            [
              _vm.title || _vm.$slots.title
                ? [
                    _c(
                      "h6",
                      { staticClass: "panel-title" },
                      [
                        _vm._t("title", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.title) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v(_vm._s(_vm.icon))
                        ])
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.loading === true,
                          expression: "loading === true"
                        }
                      ],
                      staticClass:
                        "icon-spinner spinner position-right preloader"
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "heading-elements" }, [
                      _c("ul", { staticClass: "icons-list" }, [
                        _vm.collapsible
                          ? _c("li", [
                              _c("a", {
                                class: { "rotate-180": _vm.collapsed },
                                attrs: { "data-action": "collapse" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    _vm.collapsed = !_vm.collapsed
                                  }
                                }
                              })
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.showRefreshButton
                      ? _c(
                          "sm-switchery",
                          {
                            staticClass: "auto-update",
                            attrs: {
                              title: [
                                _vm.checked
                                  ? "Включить автообновление событий"
                                  : "Выключить автообновление событий"
                              ]
                            },
                            on: { input: _vm.refreshButtonChange },
                            model: {
                              value: _vm.refreshStop,
                              callback: function($$v) {
                                _vm.refreshStop = $$v
                              },
                              expression: "refreshStop"
                            }
                          },
                          [_c("input", { attrs: { type: "checkbox" } })]
                        )
                      : _vm._e()
                  ]
                : [_vm._t("custom-title")]
            ],
            2
          )
        : _vm._t("custom-heading"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "panel-body",
          class: [_vm.bodyClass, { hidden: _vm.collapsed && _vm.hiddenOnStart }]
        },
        [_vm._t("default")],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-20d39308", { render: render, staticRenderFns: staticRenderFns })
  }
}