var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isActive,
          expression: "isActive"
        }
      ],
      class: { disabled: _vm.disabled }
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm.$slots.title
        ? _c("portal", { attrs: { to: _vm.tabIndex } }, [_vm._t("title")], 2)
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-21314319", { render: render, staticRenderFns: staticRenderFns })
  }
}