<template>
    <div class="sm-tooltip" :class="['sm-tooltip_position-' + dir]">
        <div class="sm-tooltip__trigger">
            <slot name="title" v-if="$slots.title"/>
            <i v-else class="material-icons">help_outline</i>
        </div>
        <div class="sm-tooltip__content-wrapper">
            <div class="sm-tooltip__content">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
export default Vue.component('SmTooltip', {
    props: {
        dir: {
            type: String,
            default: 'right'
        }
    }
});
</script>

<style lang="less">
.sm-tooltip {
    position: relative;
    cursor: help;
    display: inline-block;

    &:hover,
    &:focus {
        .sm-tooltip__trigger {
            opacity: 0.8;
        }

        .sm-tooltip__content-wrapper {
            transform: translate(100%, -50%) scale(1);
            opacity: 1;
        }
    }

    &.sm-tooltip_position-left {
        &:hover,
        &:focus {
            .sm-tooltip__content-wrapper {
                transform: translate(-100%, -50%) scale(1);
            }
        }
    }

    &.sm-tooltip_information-icon {
        border-radius: 4px;
    }

    &.sm-tooltip_information-notification {
        background-color: fade(#fc8a23, 10%);
        // box-shadow: 0 0 0 2px fade(#FC8A23, 5%);

        .material-icons {
            color: #fc8a23;
        }
    }

    &.sm-tooltip_information-regIncident {
        background-color: fade(#eb5757, 10%);
        // box-shadow: 0 0 0 2px fade(#EB5757, 5%);

        .material-icons {
            color: #eb5757;
        }
    }

    &.sm-tooltip_information-autoinformer {
        background-color: fade(#009688, 10%);
        // box-shadow: 0 0 0 2px fade(#009688, 5%);

        .material-icons {
            color: #009688;
        }
    }

    &.sm-tooltip_information-externalScript {
        background-color: fade(#2f80ed, 10%);

        .material-icons {
            color: #2f80ed;
        }
    }
}

.sm-tooltip__trigger {
    height: 100%;
    transition: opacity 0.2s ease;
    opacity: 0.25;

    .material-icons {
        font-size: 14px;
    }

    .sm-tooltip_information-icon & {
        opacity: 1;
        width: 30px;
        height: 30px;

        // background-color: #F8F8F8;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .material-icons {
            font-size: 16px;
        }

        &:before {
            content: '';
            height: 100%;
            width: 44px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }
}

.sm-tooltip__content-wrapper {
    position: absolute;
    z-index: 888;
    top: calc(50% ~'-' 1px);
    right: 24px;
    padding-left: 36px;
    transition: 0.1s ease opacity, 0.2s ease transform;
    transform: translate(0, -50%) scale(0);
    transform-origin: 100% 50%;
    opacity: 0;

    .sm-tooltip.sm-tooltip_position-left & {
        padding-left: 0;
        padding-right: 36px;
        left: 24px;
        right: auto;
        transform: translate(0, -50%) scale(0);
        transform-origin: -100% 50%;
    }

    &:before {
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 24px;
        width: 8px;
        height: 8px;
        content: '';
        transform: translate(100%, -50%) rotate(45deg);
        background: #fffef9;
        box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.05);

        .sm-tooltip.sm-tooltip_position-left & {
            left: auto;
            right: 24px;
            transform: translate(-100%, -50%) rotate(45deg);
            box-shadow: 3px -1px 1px 0 rgba(0, 0, 0, 0.05);
        }
    }
}

.sm-tooltip__content {
    overflow-y: auto;
    width: 400px;
    min-height: 30px;
    max-height: 250px;
    padding: 8px 16px;
    border-radius: 2px;
    background: #fffef9;
    box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.05);
    font-size: 12px;

    .sm-tooltip_information-icon & {
        width: 300px;
    }

    h5 {
        margin-bottom: 0;
        font-size: 13px;
        font-weight: 500;
    }

    p {
        margin-top: 0;

        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }
}
</style>
