<!-- Select2 который рендерит переданные в него ноды -->
<template>
    <div class="sm-rsm-select" :class="{clearable: !noClearButton}">
        <sm-select :data="data" :value="!isPrimitive ? value : _.find(data, function(el){return el[dataId] === value})" @input="$emit('input', !isPrimitive ? $event : $event[dataId])" name="service-group" :placeholder="__('workgroups.rsm.select-ci-combo')" :multiple="false">
            <template slot="option" slot-scope="props">
                <sm-ci-icon :ci="props.option"/>
                <span>
                    {{ props.option.name }}
                </span>
            </template>
            <template slot="singleLabel" slot-scope="props">
                <sm-ci-icon :ci="props.option"/>
                <span>
                    {{ props.option.name }}
                </span>
            </template>
            <template slot="tag" slot-scope="{ option, remove }">
                <span class="multiselect__tag">
                    <sm-ci-icon :ci="option"/>
                    <span>
                        {{ option.name }}
                    </span>
                    <i class="multiselect__tag-icon" @click="remove(option)"/>
                </span>
            </template>
        </sm-select>
        <span
            v-if="!noClearButton"
            class="input-group-addon btn btn-default"
            :title="__('workgroups.notifications.creation-dialog.clear-button')"
            @click="$emit('input', null)"
            >
            <i class="icon-eraser" />
        </span>
    </div>
</template>

<script>
export default Vue.component('sm-rsm-select', {
    props: {
        /* eslint-disable vue/require-prop-types */
        value: {
            validator(value) {
                return (value && (_.isArray(value) || _.isObject(value))) || true;
            }
        },
        /* eslint-enable vue/require-prop-types */
        nodes: {
            type: Array,
            required: true
        },
        dataId: {
            type: String,
            default: 'id'
        },
        dataText: {
            type: String,
            default: 'name'
        },
        dataIcon: {
            type: String,
            default: 'configItemType.icon'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isPrimitive: {
            type: Boolean,
            default: false
        },
        noClearButton: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {};
    },
    computed: {
        data() {
            return _.map(this.nodes, (node) => {
                return {
                    id: _.get(node, this.dataId),
                    name: _.get(node, this.dataText),
                    configItemType: {
                        icon: _.get(node, this.dataIcon)
                    }
                };
            });
        }
    }
});
</script>

<style lang="less">
.sm-rsm-select {
    display: flex;
    width: 100%;
}
@clear-button-width: 40px;
.sm-rsm-select {
    .select-container {
        min-width: 0;
    }

    &.clearable {
        .input-group-addon {
            width: @clear-button-width;
        }
    }
}
</style>
