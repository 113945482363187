export class MainMenuHttpService extends sm.Service {
    _url = `/api/webui/pl/v1/userspaces`;
    _urlCurrent = `/api/webui/pl/v1/userspaces/current`;
    _urlConfigs = `/api/webui/pl/v1/userspaces/current/configs`;

    constructor() {
        super({
            singleton: true
        });
    }

    makeUrl(params) {
        return ``;
    }

    async getMenuConfiguration() {
        if (this._menuItemsCache) return this._menuItemsCache;

        const mainMenuConfigurationName = 'main-menu';
        const mainMenuCustomConfigurationName = 'main-menu-custom';

        this.url = `${this._urlConfigs}/filter?perPage=-1`;
        const filter = {
            names: [mainMenuConfigurationName, mainMenuCustomConfigurationName]
        };

        this.createOpts = {
            headers: {
                'content-type': 'application/json'
            }
        };

        const moduleConfigsResponse = await this.create(JSON.stringify(filter));

        let result = [];
        for (const moduleConfig of moduleConfigsResponse) {
            result = result.concat(moduleConfig.jsonConfig.menuItems);
        }

        this._menuItemsCache = result;
        return result;
    }

    async getCurrentUserspace() {
        if (this._currentUserspaceCache) return this._currentUserspaceCache;
        this.url = `${this._urlCurrent}`;
        const currentUserspaceResponse = await this.fetch();
        return currentUserspaceResponse;
    }

    async getMenuLogo(userspaceId, size) {
        const key = JSON.stringify(`menu-logo-x${size}`);
        this.url = `${this._url}/${userspaceId}/logo?size=${size}`;
        const itemResponse = await this.fetch();
        return itemResponse;
    }
}
