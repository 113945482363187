<template>
    <div class="hidden">
        <div v-on-clickaway="away" ref="modal" class="v-white-popup">
            <slot/>
        </div>
    </div>
</template>

<script>
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import {VueClickaway} from 'src/js/lib/vue2/vue-clickaway-2.1.0.js';

let {mixin: clickaway} = VueClickaway;

export default Vue.component('SmModal', {
    mixins: [clickaway],
    props: {
        cls: {
            type: String
        },
        disableClickaway: {
            type: Boolean,
            default: false
        },
        disableEscapeClose: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.show();
    },
    beforeDestroy() {
        this.hide();
    },
    destroyed() {
        $(this.$el).remove();
    },
    methods: {
        show() {
            var vm = this;
            $.magnificPopup.open({
                closeOnBgClick: false,
                enableEscapeKey: !this.disableEscapeClose,
                items: {
                    src: vm.$refs.modal,
                    type: 'inline'
                },
                callbacks: {
                    beforeOpen() {
                        this.st.mainClass = `sm-modal ${this.st.mainClass}${vm.cls ? vm.cls : ''}`;
                    },
                    open() {},
                    close() {
                        vm.$emit('destroy');
                        vm.$destroy();
                    }
                }
            });
        },
        hide() {
            $.magnificPopup.close();
        },
        away(e) {
            if (this.disableClickaway) {
                return;
            }
            // правильно ли выбрано место или пропатчить clickaway?
            if (sm.utils.isDetachedNode(e.target) || (e.target === document.body && e.isTrusted === false)) {
                return;
            }

            // разрешим закрывать попап noty без закрытия модалки
            if ($(e.target).closest('.noty_bar').length) {
                return;
            }

            this.hide();
        }
    }
});
</script>

<style lang="less">
/*#region NEW-DESIGN_SM-MODAL*/
@sm-modal_accent-color: #46a2fc;
@sm-modal_border-color: #d7dce8;
// @data-table_border-color: #d7dce8;

// Перенести
@data-table_background-light-color: #edeff3;
@data-table_icon-color: #687189;

.material-icons {
    display: inline-block;
    white-space: nowrap;
    letter-spacing: normal;
    text-transform: none;
    word-wrap: normal;
    font-family: 'Material Icons', sans-serif;
    font-size: 18px; /* Preferred icon size */
    font-weight: 400;
    font-style: normal;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.sm-modal {
    .mfp-container .mfp-content {
        width: 90vw;
        @media (min-width: 1340px) {
            width: 80vw;
        }
        // Сдвиг вправо, для визуальной компенсации просвечивающего сайдбара на пол его ширины
        margin-left: 56px;
    }

    .v-white-popup {
        background-color: #fff;
    }

    .panel {
        padding: 0 !important;
        border-radius: 0;
    }

    /*#region SM-MODAL_wrapper*/
    .fancybox-skin {
        border: none;
        border-radius: 1px;
        border-radius: 0;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.06);
    }

    .fancybox-inner {
        // .layout-row.layout-align-start-stretch;
        display: flex;
        overflow: initial !important;
        align-content: stretch;
        align-items: stretch;
        flex-direction: column;
        justify-content: flex-start;
        // FIXME: Придумать способ вызывать попап с адаптацией под высоту контента с учетом максимальной высоты
        height: 713px !important;
        padding-right: 0;
    }

    // Стилизация кнопки закрытия
    body & .mfp-container .mfp-content .mfp-close {
        top: 0;
        right: 20px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        height: 46px;
        width: 24px;
        padding: 0;
        text-decoration: none;
        color: @data-table_background-light-color;
        text-shadow: none;
        font-size: 0;

        &:hover {
            background-color: transparent;
        }

        &:after {
            .material-icons;
            content: 'close';
            color: @data-table_icon-color;
            font-size: 24px;
        }
    }

    /*#endregion SM-MODAL_wrapper*/

    /*#region SM-MODAL_Header-and-Navigation*/
    .sm-modal__header {
        position: relative;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex: 0 0 auto;

        width: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: 0;

        // TODO: Продумать возможность модалок без табов
        // height: 46px;
        // padding-right: 60px;
        // padding-left: 20px;
        // border-bottom: 1px solid @sm-modal_border-color;
        // background-color: @data-table_background-light-color;
        height: 100%;

        h6 {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            max-height: 22px;
            margin: 0;
            color: @data-table_icon-color;
            font-size: 17px;
            line-height: 17px;
            line-height: 1em;

            & > span {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            i.material-icons {
                padding-right: 12px;
                font-size: 22px;
            }
        }
    }

    .sm-modal__navigation {
        position: relative;
        // .layout-row.layout-align-start-center;
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        flex-direction: row;
        justify-content: flex-start;
        box-sizing: border-box;
        height: 46px;
        margin-bottom: 0;
        border-bottom: 1px solid @sm-modal_border-color;

        // background-color: #e8eaf1;
        background-color: @data-table_background-light-color;
    }

    .navigation__wrapper {
        // .layout-row.layout-align-center-center;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;

        .preloader {
            top: 1px;
            margin-left: 10px;
        }
    }

    .tabs__counter {
        display: inline-block;
        width: 30px;
        min-width: 24px;
        margin-left: 10px;
        padding: 5px 0;
        transition: opacity 0.3s ease;
        text-align: center;
        opacity: 1;
        color: #687189;
        border-radius: 6px;
        background-color: rgba(0, 0, 0, 0.08);
        font-size: 10px;
        line-height: 10px;

        &.tabs__counter_hidden {
            opacity: 0;
        }
    }

    .navigation__control {
        height: 100%;
        padding: 2px;
        border-right: 1px solid @sm-modal_border-color;

        button {
            // .layout-row.layout-align-center-center;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            height: 100%;
            margin: 0;
            padding: 0 20px;
            color: @data-table_icon-color;
            border: 0;
            background-color: transparent;
            font-size: 15px;

            &:hover,
            &:focus {
                background-color: @sm-modal_border-color;
            }

            i.material-icons {
                padding-right: 12px;
                font-size: 22px;
            }
        }
    }

    .navigation__title {
        margin-left: 20px;
        text-transform: none;

        span {
            color: @data-table_icon-color;
            font-size: 15px;
            line-height: 1em;
        }

        .navigation-title__counter {
            .tabs__counter;

            &.navigation-title__counter_hidden {
                opacity: 0;
            }
        }
    }
    /*#endregion SM-MODAL_Header-and-Navigation*/

    // FIXME SEARCH SIZE
    // .dataTables_wrapper .datatable-header .left .dataTables_filter input {
    //     width: 120px;
    //     min-width: auto;
    // }

    // & {
    //     .selected-triggers,
    //     .selected-hosts,
    //     .selected-llds,
    //     .selected-metrics {
    //         @media (min-width: 1340px) {
    //             .dataTables_wrapper .datatable-header .left .dataTables_filter input {
    //                 // max-width: 120px;
    //                 min-width: 250px;
    //             }
    //         }

    //         @media (max-width: 1339px) {
    //             .dataTables_wrapper .datatable-header .left {
    //                 width: 100%;

    //                 .input-group {
    //                     width: 100%;
    //                 }

    //                 .dataTables_filter {
    //                     width: 100%;
    //                     max-width: none;
    //                     border-right: none;
    //                 }

    //                 .has-feedback {
    //                     width: 100%;
    //                 }

    //                 input {
    //                     width: 100%;
    //                 }
    //             }
    //         }
    //     }

    //     .all-triggers,
    //     .all-hosts,
    //     .all-llds,
    //     .all-metrics {
    //         @media (max-width: 1340px) {
    //             .dataTables_wrapper .datatable-header .left .dataTables_filter input {
    //                 width: 120px;
    //                 min-width: auto;
    //             }
    //         }

    //         @media (min-width: 1339px) {
    //             .dataTables_wrapper .datatable-header .left {
    //                 width: 100%;

    //                 .input-group {
    //                     width: 100%;
    //                 }

    //                 .dataTables_filter {
    //                     width: 100%;
    //                     max-width: none;
    //                     border-right: none;
    //                 }

    //                 .has-feedback {
    //                     width: 100%;
    //                 }

    //                 input {
    //                     width: 100%;
    //                 }
    //             }
    //         }
    //     }
    // }

    .sm-modal__content {
        width: 100%;
    }

    .sm-modal__footer {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        height: 60px;
        padding: 0 20px;
        border-top: 1px solid #d7dce8;
    }

    /*#region SM-MODAL_Tabs*/
    .sm-modal__navigation {
    }

    .nav-tabs {
        margin-bottom: 0;

        & > li {
            border: 0;

            & > a {
                position: relative;
                // .layout-row.layout-align-center-center;
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: center;
                height: 46px;
                padding: 0;
                text-transform: uppercase;
                border: 0;

                .navigation__wrapper {
                    position: relative;
                    height: 46px;
                    padding: 0 20px 0 20px;

                    &.navigation__wrapper_mode_edit {
                        padding: 0;
                        padding-right: 20px;
                    }

                    &:after {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 0;
                        content: '';
                        transition: height 0.2s ease-in-out;
                        background-color: @sm-modal_accent-color;
                    }

                    .preloader {
                        position: absolute;
                        top: calc(~'50% - 8px');
                        right: 27px;
                        left: auto;
                    }
                }
            }

            &.active {
                border: 0;

                & > a {
                    color: @sm-modal_accent-color;
                    border: 0;
                    background-color: transparent;

                    .navigation__wrapper:not(.navigation__wrapper_mode_edit):after {
                        height: 2px;
                    }

                    span {
                        font-weight: 500;
                    }
                }
            }
        }
    }
    /*#endregion SM-MODAL_Tabs*/
}

.sm-modal div:not(.panel-heading) > .sm-modal__header {
    height: 46px;
    padding-right: 60px;
    padding-left: 20px;
    border-bottom: 1px solid @sm-modal_border-color;
    background-color: @data-table_background-light-color;
}

// TODO: Изменить шаблон модалки
.sm-modal.sm-modal_edit-user,
.sm-modal_incidents,
.sm-modal_notifications,
.sm-modal_import-filter {
    .mfp-content {
        width: 860px;
        max-width: 860px;

        @media (min-width: 1340px) {
            width: 860px;
            max-width: 860px;
        }
    }

    .content-group {
        padding-left: 20px;
        padding-right: 20px;

        &:first-child {
            padding-top: 20px;
        }
    }

    .btns-block {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        height: 60px;
        padding: 0 20px;
        border-top: 1px solid #d7dce8;
    }

    .sm-modal__content {
        padding: 20px 20px 0 20px;
        max-height: calc(80vh ~'-' 46px ~'-' 60px);
        overflow-y: auto;
    }
}

.sm-modal_incident-details {
    .panel-heading {
        padding: 0 !important;
        padding-right: 60px !important;
        padding-left: 20px !important;
        border-bottom: 1px solid #d7dce8 !important;
        background-color: #edeff3 !important;
    }

    .panel-body {
        padding: 20px !important;
    }
}

.sm-modal.sm-modal_templates {
    .mfp-container .mfp-content {
        width: 80vw;
        max-width: 80vw;

        @media (min-width: 1340px) {
            width: 60vw;
            max-width: 60vw;
        }
    }
}

/*#endregion NEW-DESIGN_SM-MODAL*/
</style>
