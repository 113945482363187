var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sm-search-input" }, [
    _c("div", { staticClass: "input-group content-group search-wrapper" }, [
      _c("div", { staticClass: "has-feedback has-feedback-left" }, [
        _c("input", {
          staticClass: "form-control col-md-12 search",
          attrs: {
            disabled: _vm.disabled,
            placeholder: _vm.placeholder,
            type: "text"
          },
          domProps: { value: _vm.value },
          on: {
            input: function($event) {
              return _vm.$emit("input", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _vm._m(0)
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-group-btn" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-default clear",
            attrs: {
              title: _vm.__(
                "workgroups.notifications.creation-dialog.clear-button"
              ),
              type: "button"
            },
            on: {
              click: function($event) {
                return _vm.$emit("input", "")
              }
            }
          },
          [_c("i", { staticClass: "icon-eraser" })]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-control-feedback" }, [
      _c("i", { staticClass: "icon-search4 text-muted text-size-base" })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2fcdd6f5", { render: render, staticRenderFns: staticRenderFns })
  }
}