<template>
    <label ref="label"
           :class="{'fancytree-selected': value, 'fancytree-partsel': !value && indeterminate}"
           class="fancytree-checkbox-wrapper vue-fancytree-checkbox-wrapper"
           @click.capture="ensureValueAndSingleClick">
        <span class="fancytree-checkbox">
            <input ref="checkbox" :checked="value" type="checkbox">
        </span>
        <span v-html="label"></span>
    </label>
</template>

<script>
// компонент использует стили из @see https://wwwendt.de/tech/fancytree/demo/#sample-select.html
export default Vue.component('SmCheckbox', {
    props: {
        value: {
            required: true,
            type: Boolean
        },
        label: {
            type: String
        },
        indeterminate: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            $_canBubble: false
        };
    },
    watch: {
        value(val) {
            if (val === true && this.$options.propsData.indeterminate != null) {
                this.$emit('update:indeterminate', false);
            }
        }
    },
    methods: {
        // корректная обработка клика на input в связи со всплытием события до label
        ensureValueAndSingleClick(e) {
            if (this.$data.$_canBubble === true) {
                return;
            }
            e.preventDefault();
            e.stopImmediatePropagation();
            this.$data.$_canBubble = true;
            this.$emit('input', !this.$refs.checkbox.checked);
            sm.utils.dispatchMouseEvent(this.$refs.checkbox, 'click');
            this.$data.$_canBubble = false;
        }
    }
});
</script>
