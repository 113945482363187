export class MenuItem {
    /** Имя элемента меню. */
    name = '';

    /** Название элемента меню. */
    title = '';

    /** Описание элемента меню. */
    description = '';

    /** Подэлемент меню, отображаемый как кнопка. */
    items = [];

    /** Краткое название модуля (продукта), для которого определен пункт меню. */
    moduleName = '';

    /** Тип компонента меню (url или скрипт). */
    type = 'Link';

    /** URL компонента меню, если тип элемента Link. */
    url = '';

    /** Способ открытия ссылки (_self, _blank). */
    utlTarget = '_self';

    /**
     * Определить тип URL. Если URL начинается с /, то считается относительным.
     * Во всех остальных случаях URL будет считаться абсолютным.
     */
    get urlType() {
        if (this.url && this.url.startsWith('/')) {
            return 'Relative';
        }
        return 'Absolute';
    }

    /** Обработчик скрипта, если тип элемента Script.  */
    handler = undefined;

    /** Название иконки меню. */
    icon = 'string';

    /** Изображение для элемента меню. */
    image = '';

    /**
     * Флаг указывает, игнорировать ли QueryParams для вычисления routerLinkActive.
     * Для примера возьмем элемент меню "Профиль".
     * При true элемент будет активирован как для url /profile/user, так и для /profile/user?userId=10.
     * При false элемент будет активирован только строго при url /profile/user.
     * По умолчанию false.
     */
    activateOnQueryParamsPresent = false;

    /**
     * Флаг указывает тип активации свойства
     * Для примера возьмем элемент меню "Профиль".
     * При true элемент будет активирован только строго при url /profile/user.
     * При false элемент будет активирован как для url /profile/user, так и для /profile/user/2/groups.
     * По умолчанию: true.
     */
    activateOnExactUrl = true;

    /** Список классов через пробел, которые будут добавлены к элементу и под элементам. */
    classes = '';

    /** Расположение элемента. */
    position = '';

    /** Порядок элемента меню в категории. */
    order = 0;

    constructor(init) {
        Object.assign(this, init);
    }
}
