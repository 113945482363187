var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "navbar navbar-inverse navbar-fixed-top",
      attrs: { id: "navbar-primary" }
    },
    [
      _c(
        "div",
        { staticClass: "navbar-header" },
        [
          _c(
            _vm.isPageActive("index.html") ? "span" : "a",
            {
              tag: "component",
              staticClass: "navbar-brand navbar-brand--flex",
              attrs: {
                href: !_vm.isPageActive("index.html") ? "index.html" : ""
              }
            },
            [
              _c("div", { staticClass: "logo" }, [
                _c("picture", [
                  _c("img", {
                    attrs: {
                      alt: "Логотип компании",
                      width: "64",
                      height: "64",
                      src: "/api/webui/pl/v1/userspaces/current/logo?size=1",
                      srcset:
                        "/api/webui/pl/v1/userspaces/current/logo?size=2" +
                        " 2x, " +
                        "/api/webui/pl/v1/userspaces/current/logo?size=3" +
                        " 3x`"
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.__("systemTitle", { ns: "userspace" })))
              ])
            ]
          ),
          _vm._v(" "),
          _vm._m(0)
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "navbar-collapse collapse",
          attrs: { id: "navbar-mobile" }
        },
        [
          _c(
            "ul",
            { staticClass: "nav navbar-nav navbar-right" },
            [
              _vm.sm.customer === "dit"
                ? [
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "bitrix-chat-link",
                          attrs: { href: "#", title: _vm.__("menu.support") },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.toggleBitrixChat()
                            }
                          }
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "bitrix-chat-link__icon",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 50 50"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M25,4C12.3,4,2,13,2,24c0,6.2,3.3,12.1,9,15.8c-0.1,1.1-0.7,3.6-2.8,6.6L7.1,48h2c5.4,0,9.1-3.3,10.3-4.6 c1.8,0.4,3.7,0.6,5.6,0.6c12.7,0,23-9,23-20S37.7,4,25,4z M15,26c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S16.1,26,15,26z M25,26 c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S26.1,26,25,26z M35,26c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S36.1,26,35,26z"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.helpLink,
                            title: _vm.__("menu.help") /* Помощь */
                          }
                        },
                        [_c("i", { staticClass: "icon-help" })]
                      )
                    ])
                  ]
                : _vm.sm.customer === "tneft"
                  ? [
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://rospartner.atlassian.net/wiki/spaces/SMPUB/overview",
                              title: _vm.__("menu.help")
                            }
                          },
                          [_c("i", { staticClass: "icon-help" })]
                        )
                      ])
                    ]
                  : _vm._e(),
              _vm._v(" "),
              _vm.sm.systemUser.id && _vm.sm.systemUser.hasGrant("pl.admins.*")
                ? _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/pl/admin/",
                          title: _vm.__("menu.admin-panel")
                        }
                      },
                      [_c("i", { staticClass: "icon-crown" })]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.sm.systemUser.id
                ? _c("li", { staticClass: "dropdown dropdown-user" }, [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-toggle",
                        attrs: { "data-toggle": "dropdown" }
                      },
                      [
                        _vm.sm.systemUser.hasPhoto
                          ? _c("img", {
                              attrs: { src: _vm.sm.systemUser.getPhotoUrl() }
                            })
                          : _c("div", { staticClass: "empty-avatar" }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.sm.systemUser.fullName))
                        ]),
                        _vm._v(" "),
                        _c("i", { staticClass: "caret" })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "ul",
                      { staticClass: "dropdown-menu dropdown-menu-right" },
                      [
                        _c(
                          "li",
                          {
                            class: { active: _vm.isPageActive("profile.html") }
                          },
                          [
                            _c("a", { attrs: { href: "/pl/profile" } }, [
                              _c("i", { staticClass: "icon-user-plus" }),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.__("menu.profile")) +
                                  "\n                        "
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("li", { staticClass: "divider" }),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.sm.systemUser.onLogout($event)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "icon-switch2" }),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.__("menu.profile-logout")) +
                                  "\n                        "
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      { staticClass: "nav navbar-nav pull-right visible-xs-block" },
      [
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                "data-toggle": "collapse",
                "data-target": "#navbar-mobile"
              }
            },
            [_c("i", { staticClass: "icon-tree5" })]
          )
        ])
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-01906126", { render: render, staticRenderFns: staticRenderFns })
  }
}