<template>
    <div v-if="typeData" class="navigation__control">
        <button :type="type === 'save' ? 'submit' : 'button'" class="btn btn-default">
            <i v-if="typeData.icon" class="material-icons">{{ typeData.icon }}</i>
            <span v-if="typeData.title">
                {{ typeData.title }}
            </span>
            <slot v-else/>
        </button>
    </div>
</template>

<script>
export default Vue.component('SmNavBtn', {
    props: {
        type: {
            type: [String, Object],
            required: true
        }
    },
    data() {
        return {
            types: {
                back: {
                    icon: 'arrow_back',
                    title: __('common.back-button')
                },
                save: {
                    icon: 'check',
                    title: __('common.save-button')
                },
                cancel: {
                    icon: 'close',
                    title: __('common.cancel-button')
                },
                clone: {
                    icon: 'filter_none',
                    title: __('common.clone-button')
                }
            }
        };
    },
    computed: {
        typeData() {
            return _.isObject(this.type) ? this.type : this.types[this.type];
        }
    },
    mounted() {
        if (this.types[this.type] && !('disabled' in this.$attrs)) {
            console.debug('как правило, нужен :disabled - на время ajax запросов лучше отключать кнопку', this.$el);
        }
    }
});
</script>
