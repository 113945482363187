import {UserProfileHttpService} from './userProfileHttpService.js';
import {UserGrantsService} from './userGrantsService.js';
import {MainMenuHttpService} from './mainMenuHttpService.js';
import {MenuService} from './menuService.js';
import {MenuItem} from '../models/menuItemModel.js';

export class MainMenuService {
    _mainMenuHttpService = new MainMenuHttpService();
    _userGrantsService = new UserGrantsService();
    _userProfileService = new UserProfileHttpService();
    _menuService = new MenuService();

    constructor() {}

    async getMenu() {
        const rawMenuItems$ = this._mainMenuHttpService.getMenuConfiguration();
        const currentUserspace$ = this._mainMenuHttpService.getCurrentUserspace();
        const userGrants$ = this._userGrantsService.getCurrentProfileGrants();
        const profile$ = this._userProfileService.getCurrentProfile();

        const [rawMenuItems, currentUserspace, userGrants, profile] = await Promise.all([
            rawMenuItems$,
            currentUserspace$,
            userGrants$,
            profile$
        ]);

        const menuTitle = currentUserspace.name;

        const mainMenuItems = rawMenuItems.filter((item) => {
            // Проверяем, есть ли у пользователя доступ к элементу меню.
            if (!item.grants) return true;
            return this._userGrantsService.hasUserGrant(item.grants, userGrants);
        });

        const menuItems = this.createMenu(mainMenuItems);

        this.translateMenu(menuItems);
        this.updateProfileItem(menuItems, profile);
        this.createLabels(menuItems);

        return [menuTitle, menuItems, profile];
    }

    /**
     * Задать активный элемент.
     * @param menuItems Список элементов меню.
     * @returns
     */
    setActiveMenuItem(location, menuItems) {
        const items = this.findItemsByTypes(menuItems, ['Menu']);
        // Определяем активный элемент для того, чтобы понять какие пункты меню требуется показывать для второго уровня.
        const activeMenuItems = items.filter((item) => {
            return this._menuService.isMenuItemActive(item, location);
        });

        // Если не найдено активных пунктов меню, ничего не делаем.
        if (activeMenuItems.length === 0) {
            return undefined;
        }

        // Если найдено активных пунктов меню более одного.
        if (activeMenuItems.length > 1) {
            console.error('There are more then 1 active menu items. Selecting first.');
        }
        return activeMenuItems[0];
    }

    /**
     * Создать элементы меню.
     * @param mainMenuItems Бэк элементы меню.
     * @returns
     */
    createMenu(mainMenuItems) {
        const rootItems = mainMenuItems.filter((item) => !item.parent).map((item) => this.createMenuItem(item));

        for (const rootItem of rootItems) {
            rootItem.items = [];
        }

        const otherItems = mainMenuItems.filter((item) => item.parent);
        for (const item of otherItems) {
            if (item.parent) {
                const parentItem = this.findItem(rootItems, item.parent);
                if (parentItem && parentItem.items) parentItem.items.push(this.createMenuItem(item));
            }
        }

        for (const rootItem of rootItems) {
            if (rootItem.items && rootItem.items.length > 1) {
                rootItem.url = rootItem.items[0].url;
            }
        }

        return rootItems;
    }

    /**
     * Создать элемент меню для SidebarMenu и назначить в группу.
     * @param mainItem Элемент MainMenuItem.
     * @returns
     */
    createMenuItem(mainMenuItem) {
        return new MenuItem(mainMenuItem);
    }

    /**
     * Создать заголовки подменю для корневых элементов меню.
     * @param menuItems
     */
    createLabels(menuItems) {
        for (const menuItem of menuItems) {
            if (!menuItem.items) menuItem.items = [];
            menuItem.items = [this.createMenuLabel(menuItem), ...menuItem.items];
        }
    }

    /**
     * Создать заголовок подменю для элемента меню.
     * @param menuItem Элемент меню.
     * @returns
     */
    createMenuLabel(menuItem) {
        const menuLabel = new MenuItem(menuItem);
        menuLabel.name = `${menuItem.name}-label`;
        menuLabel.type = 'Label';
        menuLabel.order = 0;
        return menuLabel;
    }

    /**
     * Обновить информацию по профилю пользователя для элемента меню.
     * @param profile Профиль пользователя.
     */
    updateProfileItem(menuItems, profile) {
        if (!profile) return;
        const profileItem = this.findItem(menuItems, 'side-menu.profile');
        if (profileItem) {
            profileItem.title = profile.name;
            if (profile.thumbnail) profileItem.image = `data:image/png;base64,${profile.thumbnail}`;
        }
    }

    setLanguageSelect(profile) {
        const key = 'side-menu.profile-language';
        const selectValue = LanguageSelectMenuItem.get(profile.locale);
        if (selectValue) MenuItemSelectDefaultMap.set(key, selectValue);
    }

    /**
     * Найти элемент среди элементов и подэлементов меню.
     * @param menuItems Элементы меню.
     * @param name Название элемента.
     * @returns
     */
    findItem(menuItems, name) {
        for (const item of menuItems) {
            if (item.name === name) return item;
            if (item.items) {
                const needItem = this.findItem(item.items, name);
                if (needItem) return needItem;
            }
        }
        return undefined;
    }

    findItemsByTypes(menuItems, types) {
        let needItems = menuItems.filter((item) => types.some((type) => type === item.type));
        for (const menuItem of menuItems) {
            if (!menuItem.items || types.some((type) => menuItem.type === type)) continue;
            needItems = needItems.concat(this.findItemsByTypes(menuItem.items, types));
        }
        return needItems;
    }

    /**
     * Перевести элементы меню.
     * @param menuItems Элементы меню.
     * @param transloco Переводчик.
     */
    translateMenu(menuItems) {
        if (menuItems.length > 0) {
            for (const menuItem of menuItems) {
                this.translateMenuItem(menuItem);
            }
        }
    }

    /**
     * Перевести элемент и его подэлементы меню.
     * @param menuItem Элемент меню.
     * @param transloco Переводчик.
     */
    translateMenuItem(menuItem) {
        if (menuItem.items) {
            for (const item of menuItem.items) {
                this.translateMenuItem(item);
            }
        }

        if (!menuItem.title) menuItem.title = __(menuItem.name);
        if (menuItem.description) menuItem.description = __(menuItem.description);
    }
}
