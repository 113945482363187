module.exports = {
	"en": {
		"systemTitle": "MONQ Cloud",
		"copyright": "LLC «Monq Digital Lab»",
		"headerCI": "CI",
		"abbrHour": "h",
		"abbrDay": "d",
		"abbrMonth": "m",
		"abbrYear": "y",
		"linkProfile": "Profile",
		"linkLogout": "Logout",
		"yes": "Yes",
		"no": "No",
		"changeBorderWithScale": "Do you agree that the border will change with scale?",
		"showNow": "Now",
		"timeChoose": "Choose time",
		"downloadMobileApp": "Download a mobile app",
		"filters": "Filters",
		"reset": "Reset",
		"mainScreen": "Main",
		"operativeScreen": "Operative",
		"primaryEventsScreen": "Primary events",
		"slaScreen": "SLA",
		"timelineScreen": "Timeline",
		"screens": "Views",
		"management": "Management",
		"managementUsers": "Users",
		"managementWorkgroups": "Workgroups",
		"managementRsm": "RSM",
		"managementRulesActions": "Rules actions",
		"dtProcessing": "Wait...",
		"dtLengthMenu": "<span>Show</span> _MENU_",
		"dtInfo": "Records from _START_ to _END_ of _TOTAL_",
		"dtInfoEmpty": "Records from 0 to 0 of 0",
		"dtInfoFiltered": "(filtered from _MAX_ records)",
		"dtLoadingRecords": "Loading records...",
		"dtZeroRecords": "No records",
		"dtEmptyTable": "No data",
		"dtPaginateFirst": "First",
		"dtPaginateLast": "Last",
		"dtAriaSortAscending": ": activate to sort column by ascending",
		"dtAriaSortDescending": ": activate to sort column by descending",
		"dtPage": "Page",
		"dtDataSize": "Records per page",
		"dtSearchPlaceholder": "Search",
		"dtOf": "of",
		"onlyProblems": "Only problems",
		"close": "Close",
		"prev": "Previous",
		"next": "Next",
		"fancyError": "Failed to load information. <br/> Please try again later.",
		"export": "Export",
		"dtSearchClear": "Clear",
		"dtBtnAdd": "Add",
		"dtBtnRemove": "Remove",
		"manageView": "Manage view",
		"eventCount": "Events count",
		"priority": "Priority",
		"noEventsPriority": "no events",
		"eventsChartTitle": "Events by time",
		"systemStatusChartTitle": "Status by time",
		"rsmEntityTotal": "Total",
		"systemStatusChartOnline": "Online",
		"systemStatusChartRightButtonClick": "To open а config item page in а new tab, please, click with pressed Ctrl or mouse wheel click",
		"anyganttWeek": "week",
		"dispatcherWidgetOnlyActive": "Only active",
		"dispatcherWidgetTitle": "Events by period",
		"dispatcherWidgetMenuCreate": "Create",
		"dispatcherWidgetMenuNotification": "Notification",
		"event": "Event",
		"begin": "Start",
		"duration": "Duration",
		"status": "Status",
		"code": "Code",
		"object": "Object",
		"dispatcherWidgetLink": "Relation",
		"dtAll": "All",
		"proxyServer": "Proxy server",
		"httpCode": "Http code",
		"error": "Error",
		"additionally": "Additionally",
		"configItemShowGraph": "Show graph",
		"configItemEmptyMetrics": "No metrics",
		"scenario": "Scenario",
		"scenarios": "Scenarios",
		"stepDescription": "Step description",
		"step": "Step",
		"configItemLastValue": "Last value, sec",
		"configItemControlTime": "Control time",
		"value": "Value",
		"metrics": "Metrics",
		"configItemGraph": "Graph",
		"configItemTable": "Table",
		"datetime": "Date",
		"dispatcherWidgetSendNotification": "Send notification",
		"dispatcherWidgetWhom": "Whom",
		"dispatcherWidgetDeliveryLists": "Delivery lists",
		"dispatcherWidgetUserLists": "User lists",
		"dispatcherWidgetSubjectMail": "Subject mail",
		"dispatcherWidgetSubject": "Subject",
		"dispatcherWidgetBodyMail": "Body mail",
		"dispatcherWidgetSystemInformation": "System information",
		"dispatcherWidgetAdditionalData": "Additional data",
		"dispatcherWidgetInitiator": "Initiator",
		"cancel": "Cancel",
		"dispatcherWidgetEnterDeliveryName": "Enter a delivery name",
		"dispatcherWidgetEnterUserSurname": "Enter a user surname",
		"dispatcherWidgetSelectEvent": "Please select a event",
		"dispatcherWidgetSelectDeliveryOrUser": "Please select a delivery or a user",
		"dispatcherWidgetNotificationSended": "Notification sent",
		"dispatcherWidgetFailedSendNotification": "Failed to send notification. Please try again later",
		"dispatcherWidgetEventNo": "Event No.",
		"dispatcherWidgetLinkEvent": "Link to events",
		"triggerName": "Trigger name",
		"dispatcherWidgetInSystem": "In system",
		"dispatcherWidgetEventsRegistered": "following events registered:",
		"failedGetPageData": "Failed to get page data. Try to refresh the page later.",
		"save": "Save",
		"apply": "Apply",
		"serverInternalError": "Temporary error, try again later",
		"priorities": "Priorities",
		"prioritiesDurationAtLeast": "Duration, at least",
		"smTreeAllObjects": "All objects",
		"rsmBusinessLevel": "Business",
		"rsmApplicationLevel": "Application",
		"rsmSystemLevel": "System",
		"rsmShowAllObjectsBusinessLevel": "Show all business objects",
		"rsmShowAllObjectsApplicationLevel": "Show all application objects",
		"rsmShowAllObjectsSystemLevel": "Show all system objects",
		"rsmShowDependentsBusinessLevel": "Show dependents in a business level",
		"rsmShowDependentsApplicationLevel": "Show dependents in a application level",
		"rsmShowDependentsSystemLevel": "Show dependents in a system level",
		"filtersNameFilter": "Filter name",
		"share": "Share",
		"allPriorities": "All priorities",
		"filtersCopiedFilter": "Link for sending has been copied to the buffer:",
		"filterMinute": "min",
		"abbrMinute": "m",
		"abbrSecond": "s",
		"eventDetailsFailedGetEvent": "Failed to get event data",
		"incidents": "Incidents",
		"forbidden": "Forbidden",
		"pleaseEnterMinCountSymbols": "Password must be at least {{ count }} characters long",
		"pleaseEnterEmail": "Please enter email address"
	}
}