<template>
    <div v-show="links.length > 1" :class="cls">
        <ul class="nav nav-tabs">
            <li v-for="link in links"><a :href="'#' + link.href">{{ link.title }}</a></li>
        </ul>
    </div>
</template>

<script>
// внутри bootstrap scrollspy, не имеющий деструктора. workaround - scrollspy должен содержаться внутри элемента с :key
export default Vue.component('SmScrollspy', {
    props: {
        links: {
            type: Array,
            required: true
        },
        amendment: {
            type: Number,
            default: 46 // $('.sm-widget-controls').eq(0).height()
        },
        areaSelector: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            cls: 'scrollspy'
        };
    },
    mounted() {
        if (_.find(this.links, (link) => _.includes(link, '#'))) {
            throw 'href должен быть без #';
        }
        var vm = this;
        this.$nextTick(() => {
            var $el = $(this.$el);
            var $scrollArea = $(this.areaSelector);

            $scrollArea.scrollspy({
                target: `.${this.cls}`,

                // +1, чтобы сразу был активен первый пункт
                offset: this.amendment + $el.height() + parseInt($scrollArea.css('padding-top'), 10) + 1
            });

            $el.on('click', '[href]', function(e) {
                e.preventDefault();
                this.blur();
                vm.activateById($(this).attr('href'));

                var $alertedElem = $($(this).attr('href')).find('.panel-heading');
                sm.utils.scrollTo({$scrollArea, $scrollTarget: $($(this).attr('href')), $alertedElem});
            });

            // костыль, при скролле в самом низу активируется последний пункт
            $scrollArea.on(
                'scroll',
                _.throttle(() => {
                    if (vm.isAreaScrolledToBottom()) {
                        this.activateById(`#${vm.links[vm.links.length - 1].href}`);
                    }
                }, 50)
            );
        });
    },
    methods: {
        // @see https://stackoverflow.com/a/34550171
        isAreaScrolledToBottom() {
            var scrollArea = $(this.areaSelector)[0];
            return scrollArea.scrollHeight - scrollArea.scrollTop === scrollArea.clientHeight;
        },
        activateById(id) {
            $.fn.scrollspy.Constructor.prototype.activate.call($(this.areaSelector).data('bs.scrollspy'), id);
        }
    }
});
</script>

<style lang="less">
.scrollspy {
    width: 100%;

    .nav-tabs {
        margin-bottom: 0;
        border-bottom: 1px solid #d7dce8;
        background-color: #edeff3;

        & > li {
            border: 0;

            & > a {
                position: relative;
                // .layout-row.layout-align-center-center;
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: center;
                height: 46px;
                padding: 0 20px 0 20px;
                text-transform: uppercase;
                border: 0;

                &:hover {
                    border: none !important;
                    background-color: transparent !important;
                }

                &:after {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 0;
                    content: '';
                    transition: height 0.4s ease, opacity 0.3s ease;
                    opacity: 0;
                    background-color: #46a2fc;
                }
            }

            &.active {
                border: 0;

                & > a {
                    margin: 0 !important;
                    color: darken(#46a2fc, 3%);
                    border: 0 !important;
                    background-color: transparent !important;

                    &:hover {
                        color: darken(#46a2fc, 3%);
                    }

                    &:after {
                        height: 2px;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.panel-heading {
    transition: background-color 0.4s ease;

    &.scroll-attention {
        background-color: #d7dce8 !important;
    }
}

.scroll-attention-setup {
    transition: background-color 0.4s ease;
}
</style>
