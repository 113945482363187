<template>
    <div id="navbar-primary" class="navbar navbar-inverse navbar-fixed-top">
        <div class="navbar-header">
            <component v-cloak :is="isPageActive('index.html') ? 'span' : 'a'" :href="!isPageActive('index.html') ? 'index.html' : ''" class="navbar-brand navbar-brand--flex">
                <div class="logo">
                    <picture>
                        <img
                            alt="Логотип компании"
                            width="64"
                            height="64"
                            src="/api/webui/pl/v1/userspaces/current/logo?size=1"
                            srcset="/api/webui/pl/v1/userspaces/current/logo?size=2 2x, /api/webui/pl/v1/userspaces/current/logo?size=3 3x`"
                            />
                    </picture>
                </div>
                <span>{{ __('systemTitle', { ns: 'userspace' }) }}</span>
            </component>
            <ul class="nav navbar-nav pull-right visible-xs-block">
                <li><a data-toggle="collapse" data-target="#navbar-mobile"><i class="icon-tree5"/></a></li>
            </ul>
        </div>
        <div id="navbar-mobile" class="navbar-collapse collapse">
            <!--<ul class="nav navbar-nav">-->
            <!--<li><a class="sidebar-control sidebar-main-toggle hidden-xs"><i class="icon-paragraph-justify3"></i></a></li>-->
            <!--</ul>-->
            <ul class="nav navbar-nav navbar-right">
                <!-- <li>
                    <template v-if="sm.customer === 'dit'">
                        <a :title="__('menu.mobile-app')" href="/downloads/apk/smon-mobile.apk" @click.prevent="downloadFile">
                            <i class="fa fa-android" style="font-size: 18px;"/>
                        </a>
                    </template>
                    <template v-else-if="sm.customer === 'core'">
                        <a :title="__('menu.mobile-app')" href="https://sm.monq.ru/downloads/apk/smon-mobile-cloud.apk" @click.prevent="downloadFile">
                            <i class="fa fa-android" style="font-size: 18px;"/>
                        </a>
                    </template>
                </li> -->
                <template v-if="sm.customer === 'dit'">
                    <li>
                        <a class="bitrix-chat-link" href="#" @click.prevent="toggleBitrixChat()" :title="__('menu.support')">
                            <svg class="bitrix-chat-link__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                <path d="M25,4C12.3,4,2,13,2,24c0,6.2,3.3,12.1,9,15.8c-0.1,1.1-0.7,3.6-2.8,6.6L7.1,48h2c5.4,0,9.1-3.3,10.3-4.6 c1.8,0.4,3.7,0.6,5.6,0.6c12.7,0,23-9,23-20S37.7,4,25,4z M15,26c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S16.1,26,15,26z M25,26 c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S26.1,26,25,26z M35,26c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S36.1,26,35,26z"/>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a :href="helpLink" :title="__('menu.help') /* Помощь */">
                            <i class="icon-help"/>
                        </a>
                    </li>
                </template>
                <template v-else-if="sm.customer === 'tneft'">
                    <li>
                        <a href="https://rospartner.atlassian.net/wiki/spaces/SMPUB/overview" :title="__('menu.help')">
                            <i class="icon-help"/>
                        </a>
                    </li>
                </template>
                <li v-if="sm.systemUser.id && sm.systemUser.hasGrant('pl.admins.*')">
                    <a href="/pl/admin/" :title="__('menu.admin-panel')">
                        <i class="icon-crown"/>
                    </a>
                </li>
                <!--<li>-->
                <!--<a href="support.html" title="Перейти на страницу техподдержки">-->
                <!--<i class="icon-help"></i>-->
                <!--</a>-->
                <!--</li>-->

                <li v-if="sm.systemUser.id" v-cloak class="dropdown dropdown-user">
                    <a class="dropdown-toggle" data-toggle="dropdown">
                        <img v-if="sm.systemUser.hasPhoto" :src="sm.systemUser.getPhotoUrl()">
                        <div v-else class="empty-avatar"/>
                        <span>{{ sm.systemUser.fullName }}</span>
                        <i class="caret"/>
                    </a>

                    <ul class="dropdown-menu dropdown-menu-right">
                        <li :class="{active : isPageActive('profile.html')}">
                            <a href="/pl/profile">
                                <i class="icon-user-plus" />
                                {{ __('menu.profile') }}
                            </a>
                        </li>
                        <li class="divider"/>
                        <li>
                            <a href="#" @click.prevent="sm.systemUser.onLogout">
                                <i class="icon-switch2"/>
                                {{ __('menu.profile-logout') }}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default Vue.component('SmAppNavbar', {
    data() {
        return {};
    },
    computed: {
        helpLink() {
            return _.get(sm, 'constants.supportLink', '//form.mos.ru/is/system_servmon/');
        }
    },
    created() {
        if (sm.customer === 'dit') {
            var script = document.createElement('script');
            script.async = true;
            script.src = 'https://help.mos.ru/upload/crm/site_button/loader_31_c9ho34.js?' + ((Date.now() / 60000) | 0);
            var head = document.getElementsByTagName('script')[0];
            head.parentNode.insertBefore(script, head);
        }
    },
    methods: {
        isPageActive: sm.utils.isPageActive,
        hasGrant(grant) {
            return grant === '' || sm.systemUser.hasGrant(grant) === true;
        },
        downloadFile(e) {
            sm.utils.downloadFileOrBlob({href: e.currentTarget.href});
        },
        toggleBitrixChat() {
            $('.b24-widget-button-block').click();
        }
    }
});
</script>
<style lang="less">
.navbar-nav {
    .icon-crown {
        color: transparent;
        background-image: url('../../img/admin-panel.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        width: 20px;
        height: 17px;
    }
}
</style>
