'use strict';

(function() {
    var tableUuid = 0;
    var rowChildClass = 'row-child-shown';
    var parentRowIdAttr = 'parentRowId';

    /**
     *  Обертка над @see https://datatables.net/
     *
     *  Известный баг - включение scrollY вызывает горизонтальный скролл ( ранее https://github.com/DataTables/DataTables/issues/733 ,
     *  сейчас datatables нет на github )
     *
     *  Используются плагины
     *  @see https://github.com/gyrocode/jquery-datatables-checkboxes
     *  @see https://cdn.datatables.net/buttons/
     *  @see https://cdn.datatables.net/select/
     *
     *  Реализован подкат, вызывается через onToggle в соответствующем columnDef (определяется наличием свойства renderDetailsControl в columnDef)
     */
    sm.Datatable = class Datatable {
        /**
         * @param options - встроенные опции @see https://datatables.net/reference/option/
         * следующие встроенные опции могут измениться или иметь недефолтные (относительно документации библиотеки datatables) значения по умолчанию:
         * @param options.ajax
         * @param options.rowId
         * @param options.select
         * @param options.columnDefs
         * @param options.columns
         * @param options.columns[].name
         *
         * @param [options.sm]- кастомные опции
         * @param [options.sm.owner] - обертка для jQuery селекторов таблицы и прелоадера, опциональна, используется в случае инстанса datatables напрямую (не через datatable.vue)
         * @param {jQuery} [options.sm.owner.$table] - селектор таблицы и прелоадера
         * @param {jQuery} [options.sm.owner.$preloader] - селектор прелоадера
         * @param {boolean} [options.sm.asList=false] - рендерить ли в виде списка (например, список РГ в /workgroups.html, или список коннекторов в /connectors.html)
         * @param {sm.Service|sm.NoCacheService} [options.sm.service] сервис-источник данных, в основном сервис класса sm.NoCacheService
         * @param [options.sm.datatableState] обертка для функционала сохранения состояния (пейджинга, например) таблицы после перезагрузки страницы
         * @param {DatatableStateService} [options.sm.datatableState.service] сервис, сохраняющий на сервер состояние таблицы
         * @param {Function} [options.sm.datatableState.decorateSetObject] обработчик для определения, какие данные сохранять
         * @param {boolean} [options.sm.searchInput=true] признак, рендерить ли инпут поиска
         * @param {Function} [options.sm.onSelect] обработчик, вызываемый при выборе строки таблицы, используется совместно с asList
         *
         * При экспорте всегда выгружаются все данные (при серверном пейджинге - тоже)
         * По умолчанию в экспорте участвуют столбцы такие, что options.columns[].title != null && sm.Datatable#isColumnDataVisible() == true
         * @param [options.sm.exportFilename] @see https://datatables.net/reference/button/excel , параметр filename
         * @param [options.sm.exportOptions] @see https://datatables.net/reference/button/excel , параметр exportOptions
         *
         * следующие внутренние свойства существуют, не переопределяйте их:
         * @param options.sm.newDesign=true свойство, исторически связанное с редизайном и различным видом таблиц
         * @param options.sm.isVueProcessed свойство, связанное с datatables.vue
         */
        constructor(options) {
            var self = this;
            var owner = options.sm.owner;
            var $table = owner.$table;
            if ($table.length === 0) {
                console.warn('таблица для Datatable не существует');
                return;
            }
            if ('service' in options.sm && options.sm.service == null) {
                console.error('не задан сервис для Datatable');
                return;
            }
            this.$preloader = owner.$preloader || {};
            var oldPreloader = this.$preloader;
            this.$preloader = {
                addClass(cls) {
                    oldPreloader.addClass && oldPreloader.addClass(...arguments);
                    if (cls === 'hidden') {
                        $table.closest('.panel-body').removeClass('loading');
                    }
                },
                removeClass(cls) {
                    oldPreloader.removeClass && oldPreloader.removeClass(...arguments);
                    if (cls === 'hidden') {
                        $table.closest('.panel-body').addClass('loading');
                    }
                }
            };
            this.uuid = tableUuid++;
            _.bindAll(this, 'reload');

            $(document).on('preInit.dt', function(e, settings) {
                if (e.target === $table[0]) {
                    self.$preloader.removeClass('hidden');
                }
            });

            if (options.sm.asList) {
                this.applyAsListView(options, $table);
            }
            this.processColumnDefs(options);
            if (options.select) {
                options.rowId = 'id';
            }
            this.initDetailsControl(options, $table);
            if (options.columns) {
                this.setDefaultColumnName(options);
            }
            this.resolveOrder(options);
            if (options.sm.service instanceof sm.NoCacheService) {
                this.processNoCacheServiceCase(options);
            }
            this.initDatatableState(options);

            this.makeLengthMenu(options);

            var resultOpts = _.defaults(
                {},
                options.sm.datatableState ? _.defaults({}, options.sm.datatableState.service.get(), options) : options,
                options.sm.newDesign === true
                    ? {
                          ajax(data, callback, settings) {
                              setTimeout(function() {
                                  callback({data: []});
                              }, 0);
                          },

                          pagingType: 'simple',

                          paging: options.sm.asList !== true,

                          autoWidth: true
                      }
                    : {
                          ajax(data, callback, settings) {
                              setTimeout(function() {
                                  callback({data: []});
                              }, 0);
                          },

                          paging: options.sm.asList !== true,

                          autoWidth: true,

                          lengthMenu: sm.Datatable.defaultLengthMenu,

                          dom:
                              options.sm.asList === true
                                  ? `<'datatable-header'${
                                        options.sm.searchInput !== false ? 'f' : ''
                                    }><"datatable-scroll"t>`
                                  : `<"datatable-header"l${
                                        options.sm.searchInput !== false ? 'f' : ''
                                    }><"datatable-scroll"t><"datatable-footer"ip>`
                      }
            );
            this.serverSide = !!options.serverSide;

            // datatable изменяет объект переданных ей настроек
            var cloneResultOpts = _.cloneDeep(resultOpts);

            var datatable = (this.datatable = $table.DataTable(resultOpts));
            this.on('init.dt', function(e) {
                self.$preloader.addClass('hidden');
            })
                .on('error.dt', function(e, settings, tn, msg) {
                    if (tn === 'ajax.error.custom') {
                        e.params.errback();
                    }
                })
                .on('preXhr.dt', function(e, settings, data) {
                    self.$preloader.removeClass('hidden');
                    self.saveOpenedRows($table);
                })
                .on('xhr.dt', function(e, settings, data, jqXHR) {
                    self.$preloader.addClass('hidden');
                    self.xhrCompleted = true;
                })
                .on('draw.dt', (e, settings, request, jqXHR) => {
                    var $tableContent = $(datatable.table().body());
                    $tableContent.jmRemoveHighlight({
                        className: 'mark'
                    });
                    if (!$tableContent.find('td:first').is('.dataTables_empty')) {
                        self.datatable
                            .columns((id, data, node) =>
                                this.isColumnSearchable(this.datatable.settings()[0].aoColumns[id])
                            )
                            .every(function() {
                                $(this.nodes()).jmHighlight(datatable.search(), {
                                    className: 'mark',
                                    separateWordSearch: true,
                                    filter: ['.material-icons']
                                });
                            });
                    }
                    self.loadOpenedRows();
                });

            var $container = $(datatable.table().container());

            this.skipSelectOnClickInnerInputs(options);

            if (!options.sm.newDesign === true) {
                if (options.sm.searchInput !== false) {
                    this.initSearchLayoutAndHandlers($container);
                }
            }

            $container.find('.dataTables_length select').addClass('form-control');

            // @see https://datatables.net/forums/discussion/41594/issue-with-scrollx-and-tables-of-unknown-width
            $table.attr('width', '100%');
            if (cloneResultOpts.scrollX === true) {
                $table.addClass('scrollX');
            }

            this.$table = $table;
        }

        isColumnDataVisible(column) {
            return (
                column.data &&
                column.bVisible &&
                (!_.includes(column.class, 'hidden-column') && !_.includes(column.className, 'hidden-column'))
            );
        }

        isColumnSearchable(column) {
            return this.isColumnDataVisible(column) && column.bSearchable !== false;
        }

        off(eventName) {
            this.datatable.off(eventName);
            return this;
        }

        on(eventName, callback) {
            var self = this;
            this.datatable.on(eventName, function(e) {
                if (e.target !== self.datatable.table().node()) {
                    return;
                }
                callback.apply(this, arguments);
            });
            return this;
        }

        one(eventName, callback) {
            var self = this;
            this.datatable.one(eventName, function(e) {
                if (e.target !== self.datatable.table().node()) {
                    return;
                }
                callback.apply(this, arguments);
            });
        }

        search(collection, {omit = [], pick = []} = {}) {
            if (_.intersection(omit, pick).length) {
                throw 'массивы omit и pick не должны пересекаться';
            }
            var settings = this.datatable.settings()[0];
            var searchTerm = settings.oPreviousSearch.sSearch;
            if (!searchTerm) {
                return collection;
            }

            // извлечем "дефолтные" поля для поиска из дескрипторов стоблцов
            var props = _.reduce(
                settings.aoColumns,
                (memo, val) => {
                    if (this.isColumnSearchable(val)) {
                        memo.push(val.name);
                    }
                    return memo;
                },
                []
            );

            // столбец id обычно не виден, по дефолту не ищем по нему
            omit.push('id');

            // сначала применим ограничение omit
            _.pullAll(props, omit);

            // по необходимости в pick можно передать и id
            props.push(...pick);

            props = _.uniq(props);
            return _.filter(collection, (elem) => sm.utils.isObjectContainsPrimitive(_.pick(elem, props), searchTerm));
        }

        // параметр callback никак не учитывается
        reload(callback, resetPaging = true) {
            return new $.Deferred((dfd) => {
                // поскольку ajax.reload debounced, при необходимости через dfd.progress можно узнать фактический старт ajax запроса
                this.one('xhr.dt', () => {
                    dfd.notify();
                });

                this.on('error.dt.reload', ({params}, settings, tn, msg) => {
                    if (tn === 'ajax.error.custom') {
                        var {jqXHR, textStatus, errorThrown} = params;
                        this.off('error.dt.reload');
                        dfd.reject(jqXHR, textStatus, errorThrown);
                    }
                });

                this.datatable.ajax.reload((data) => {
                    this.off('error.dt.reload');
                    dfd.resolve(data);
                }, resetPaging);
            });
        }

        getSelected() {
            return this.datatable.rows({selected: true});
        }

        get$Table() {
            return this.$table;
        }

        getTable() {
            return this.datatable;
        }

        destroy() {
            this.datatable.destroy();
            this.$table.off().empty();
        }

        applyAsListView(options, $table) {
            requestAnimationFrame(function() {
                $table.closest('.dataTables_wrapper').addClass('as-list');
            });
            $table.addClass('table-as-list');
            options.select = options.select || {style: 'single'};
        }

        initDetailsControl(options, $table) {
            var self = this;
            var columnRenderDetailsControl = _.find(options.columnDefs, function(columnDef) {
                return columnDef.renderDetailsControl != null;
            });
            if (columnRenderDetailsControl) {
                _.merge(columnRenderDetailsControl, {
                    orderable: false,
                    className: `details-control ${options.sm.newDesign === true ? 'details-control_closed' : ''}`,
                    render(data, type, full, meta) {
                        var currentRowId = full.id;
                        var row = self.datatable.row(function(rowId, rowData) {
                            return rowData.id === currentRowId;
                        });
                        if (row.length > 0) {
                            if (!options.sm.newDesign === true) {
                                return `<button class="btn btn-default" title="${__(
                                    'common.details-tooltip'
                                )}"><i class="glyphicon glyphicon-${
                                    row.child.isShown() === true ? 'minus' : 'plus'
                                }"></i></button>`;
                            }
                            return `<button class="details-control__button details-control__button_${
                                row.child.isShown() === true ? 'opened' : 'closed'
                            }" title="${__(
                                'common.details-tooltip'
                            )}"><i class="material-icons">visibility</i></button>`;
                        }
                        return '';
                    },
                    defaultContent:
                        !options.sm.newDesign === true
                            ? `<button class="btn btn-default" title="${__(
                                  'common.details-tooltip'
                              )}"><i class="glyphicon glyphicon-plus"></i></button>`
                            : `<button class="details-control__button details-control__button_closed" title="${__(
                                  'common.details-tooltip'
                              )}"><i class="material-icons">visibility</i></button>`,
                    width: '50px'
                });
                $table.on('click', '> tbody > tr > .details-control', function() {
                    var row = self.datatable.row($(this).closest('tr'));
                    if (row.child.isShown() === true) {
                        row.child.remove();
                    } else {
                        columnRenderDetailsControl.onToggle.call(this, row, row.child.isShown());
                        row.child.show();
                        row.child()
                            .data(parentRowIdAttr, row.data().id)
                            .addClass(rowChildClass);
                    }
                    if (!options.sm.newDesign === true) {
                        $(this)
                            .find('> :input > .glyphicon')
                            .toggleClass('glyphicon-minus glyphicon-plus');
                    } else {
                        $(this).toggleClass('details-control_closed details-control_opened');
                        $(this)
                            .find('> :input > i.material-icons')
                            .text(function(i, v) {
                                return v === 'visibility' ? 'visibility_off' : 'visibility';
                            });
                    }
                });
            }
        }

        setDefaultColumnName(options) {
            _.each(options.columns, function(col) {
                if (col.data && !col.name) {
                    col.name = col.data.replace(/\(\)/g, '');
                }
            });
        }

        resolveOrder(options) {
            // если в options.order пустой массив, значит, выводим в том порядке, как пришло с сервера
            if (_.isArray(options.order) && !options.order.length) {
                return;
            }

            if (!options.order) {
                options.columns.push({data: 'id', name: 'id'});
                options.columnDefs.push({
                    targets: options.columns.length - 1,
                    className: 'hidden-column',
                    searchable: false
                });
                options.order = [options.columns.length - 1, 'desc'];
            }
            if (_.isArray(options.order[0]) === false) {
                options.order = [options.order];
            }
            if (Math.max(options.columnDefs.length, options.columns.length) <= options.order[0][0]) {
                throw 'сортировка по несуществующему столбцу';
            }
        }

        processColumnDefs(options) {
            if (_.find(options.columnDefs, (col) => col.checkboxes)) {
                let index = _.findIndex(options.columnDefs, (col) => col.checkboxes);
                if (index !== 0) {
                    console.warn('обычно чекбокс должен содержаться в первом столбце таблицы');
                }
                if (!(options.columns[index].data === 'id' || _.endsWith(options.columns[index].data, '.id'))) {
                    throw 'data для чекбокс должен являться id!';
                }
                if (!options.select) {
                    throw 'определите опцию select, без нее обрабатывается только клик на чекбокс в header таблицы';
                }
            }
        }

        processNoCacheServiceCase(options) {
            if (options.processing !== true || options.serverSide !== true) {
                throw 'задайте processing и serverSide, равными true';
            }
            if (!options.sm.isVueProcessed) {
                options.sm.service.onDataOutdate(this.reload);
            }
            if (!options.ajax) {
                options.ajax = _.debounce(function(request, callback, settings) {
                    return options.sm.service.fetchDt({}, request, callback, settings);
                }, sm.constants.debounceWidgetTimeout);
            }
        }

        initDatatableState(options) {
            if (options.sm.datatableState) {
                if (options.ajax) {
                    var oldAjax = options.ajax;
                    options.ajax = function(request, callback, settings) {
                        var result = oldAjax.call(this, request, callback, settings);
                        options.sm.datatableState.service.set(
                            (options.sm.datatableState.decorateSetObject || _.identity)(
                                {
                                    order: [request.order[0].column, request.order[0].dir],
                                    pageLength: request.length
                                },
                                request,
                                callback,
                                settings
                            )
                        );
                        return result;
                    };
                }
            }
        }

        loadOpenedRows() {
            var self = this;
            if (this.xhrCompleted === true) {
                this.xhrCompleted = false;
                _.each(this.rowChildren, function(elem) {
                    var rowToOpen = self.datatable.row(function(id, data) {
                        return data.id === elem.id;
                    });
                    if (rowToOpen.length > 0) {
                        var $rowToOpen = $(rowToOpen.node());

                        sm.utils.dispatchMouseEvent($rowToOpen.find('> .details-control > :input')[0], 'click');
                    }
                });
            }
        }

        saveOpenedRows($table) {
            this.rowChildren = $table
                .find(`.${rowChildClass}`)
                .map(function() {
                    return {
                        id: $(this).data(parentRowIdAttr)
                    };
                })
                .toArray();
        }

        skipSelectOnClickInnerInputs(options) {
            var self = this;
            this.on('user-select', function(e, dt, type, cell, originalEvent) {
                if (type !== 'row') {
                    console.warn('обычно type равняется row (дальнейшая обработка именно этого случая)');
                    return;
                }

                var $target = $(originalEvent.target);
                var nodeName = originalEvent.target.nodeName.toLowerCase();
                var clickedColumnHasDtCheckboxes =
                    dt.settings()[0].aoColumns[dt.cell($target.closest('td')).index().column].checkboxes != null;
                if (
                    ((nodeName === 'label' && $target.find(':input').length > 0) ||
                        $target.closest('a').length > 0 ||
                        $target.closest(':input').length > 0 ||
                        $target.closest('.switchery').length > 0 ||
                        $target.closest('.select2-container').length > 0 ||
                        $target.is('td.dataTables_empty')) &&
                    !clickedColumnHasDtCheckboxes
                ) {
                    e.preventDefault();
                    return;
                }

                var isSelected = dt.row(cell.index().row, {selected: true}).any();
                var row = dt.row(cell.node());
                var tableHasDtCheckboxes = dt.settings()[0].aoColumns[0].checkboxes != null;
                if (!tableHasDtCheckboxes) {
                    if (isSelected) {
                        e.preventDefault();
                    } else {
                        options.sm.onSelect && options.sm.onSelect(row.data(), e);
                    }
                } else {
                    if (clickedColumnHasDtCheckboxes) {
                        if ($target.is('.dt-checkboxes')) {
                            e.preventDefault();
                            return;
                        }
                    }
                    if (options.sm.onSelect) {
                        options.sm.onSelect(row.data(), e);
                    }
                }
            });
        }

        initSearchLayoutAndHandlers($container) {
            var datatable = this.datatable;
            var $btnBlock = $(
                '<div class="input-group">' +
                    '<div class="has-feedback has-feedback-left">' +
                    '<div class="form-control-feedback">' +
                    '<i class="icon-search4 text-muted text-size-base"></i>' +
                    '</div>' +
                    '</div>' +
                    '<div class="input-group-btn">' +
                    `<button class="btn btn-default clear" title="${__(
                        'workgroups.notifications.creation-dialog.clear-button'
                    )}"><i class="icon-eraser"></i></button>` +
                    '</div>' +
                    '</div>'
            );
            $btnBlock.find('.has-feedback').append($container.find('.dataTables_filter input'));
            if (datatable.settings()[0].sDom.indexOf('"datatable-header"lf') !== -1) {
                $container.find('.dataTables_filter').css('margin-right', '180px');
            } else if (datatable.settings()[0].sDom.indexOf('"datatable-header"lBf') !== -1) {
                $container.find('.dataTables_filter').css('margin-right', '310px');
            }

            $container.find('.dataTables_filter label').replaceWith($btnBlock);
            $container.on('click', '.clear', function() {
                $container
                    .find('.dataTables_filter input')
                    .val('')
                    .trigger('input');
            });
            $container
                .find('.dataTables_filter input')
                .off()
                .on(
                    'input',
                    _.debounce(function() {
                        if (this.value === _.trim(this.value)) {
                            datatable.search(this.value).draw();
                        } else {
                            $(this)
                                .val(_.trim(this.value))
                                .trigger('input');
                        }
                    }, sm.constants.onTypingTimeout)
                );
        }

        makeLengthMenu(options) {
            if (options.language && options.language.lengthMenu && !_.includes(options.language.lengthMenu, '_MENU_')) {
                console.error('нельзя переопределить верстку');
                return;
            }
            var lengthMenu = options.lengthMenu || sm.Datatable.defaultLengthMenu;
            if (lengthMenu[0].length !== lengthMenu[1].length) {
                console.error('проверьте lengthMenu');
                return;
            }
            var select = `<select size="${lengthMenu[0].length}">`;
            _.each(lengthMenu[0], function(val, index) {
                select += `<option value="${lengthMenu[0][index]}">${lengthMenu[1][index]}</option>`;
            });
            select += '</select>';
            options.language = options.language || {};
        }
    };
})();
