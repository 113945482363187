<template>
    <span>{{ animated }}</span>
</template>

<script>
import {TweenLite} from 'gsap/TweenLite.js';

export default Vue.component('SmAnimatedNumber', {
    props: {
        /* eslint-disable vue/require-prop-types */
        value: {
            validator(value) {
                return _.isNumber(value) || null;
            }
        },
        /* eslint-enable vue/require-prop-types */
        options: {
            type: Object
        }
    },
    data() {
        return {
            tweened: 0
        };
    },
    computed: {
        animated() {
            return numeral(this.tweened).format(this.options.format);
        }
    },
    watch: {
        value: {
            handler(newValue) {
                if (newValue == null) {
                    this.tweened = 0;
                } else {
                    TweenLite.to(this.$data, this.options.duration, {tweened: newValue});
                }
            },
            immediate: true
        }
    },
    created() {
        this.options.duration = this.options.duration || 0.4;
        this.options.format = this.options.format || '0';
    }
});
</script>
