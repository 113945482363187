module.exports = {
	"ru": {
		"systemTitle": "МОНК",
		"copyright": "ООО \"Монк Диджитал Лаб\"",
		"headerCI": "КЕ",
		"headerAddConfigItem": "Название КЕ",
		"abbrHour": "ч",
		"abbrDay": "дн",
		"abbrMonth": "м",
		"abbrYear": "г",
		"linkProfile": "Профиль",
		"linkLogout": "Выйти",
		"yes": "Да",
		"no": "Нет",
		"changeBorderWithScale": "Вместе с границей сменится и масштаб, согласны?",
		"showNow": "Показать сейчас",
		"timeChoose": "Выбор времени",
		"downloadMobileApp": "Скачать мобильное приложение",
		"filters": "Фильтры",
		"reset": "Сбросить",
		"mainScreen": "Главный",
		"operativeScreen": "Оперативный",
		"primaryEventsScreen": "Первичные события",
		"slaScreen": "Отчеты SLA",
		"timelineScreen": "Шкала времени",
		"screens": "Экраны",
		"management": "Управление",
		"managementUsers": "Пользователи",
		"managementWorkgroups": "Рабочие группы",
		"managementRsm": "Панель РСМ",
		"managementRulesActions": "Правила и действия",
		"dtProcessing": "Подождите...",
		"dtLengthMenu": "<span>Показывать</span> _MENU_",
		"dtInfo": "Записи с _START_ до _END_ из _TOTAL_ записей",
		"dtInfoEmpty": "Записи с 0 до 0 из 0 записей",
		"dtInfoFiltered": "(отфильтровано из _MAX_ записей)",
		"dtLoadingRecords": "Загрузка записей...",
		"dtZeroRecords": "Записи отсутствуют.",
		"dtEmptyTable": "Данные отсутствуют",
		"dtPaginateFirst": "Первая",
		"dtPaginateLast": "Последняя",
		"dtAriaSortAscending": ": активировать для сортировки столбца по возрастанию",
		"dtAriaSortDescending": ": активировать для сортировки столбца по убыванию",
		"dtPage": "Страница",
		"dtDataSize": "Элементов на странице",
		"dtSearchPlaceholder": "Найти по ключевому слову",
		"dtOf": "из",
		"onlyProblems": "Только с проблемами",
		"close": "Закрыть",
		"prev": "Предыдущее",
		"next": "Следующее",
		"fancyError": "Не получилось загрузить информацию.<br/>Попробуйте позже еще раз.",
		"export": "Экспорт",
		"dtSearchClear": "Очистить",
		"dtBtnAdd": "Добавить",
		"dtBtnRemove": "Удалить",
		"manageView": "Настроить вид",
		"eventCount": "Количество событий",
		"priority": "Приоритет",
		"noEventsPriority": "нет событий",
		"eventsChartTitle": "События во времени",
		"systemStatusChartTitle": "Статус во времени",
		"rsmEntityTotal": "Всего",
		"systemStatusChartOnline": "Онлайн",
		"systemStatusChartRightButtonClick": "Для открытия страницы КЕ в новой вкладке сделайте клик с зажатым Ctrl, либо клик колесиком мыши",
		"anyganttWeek": "неделя",
		"highchartsNoData": "Данные отсутствуют",
		"highchartsResetZoom": "Сбросить масштаб",
		"dispatcherWidgetOnlyActive": "Только активные",
		"dispatcherWidgetTitle": "События за период",
		"dispatcherWidgetMenuCreate": "Создать",
		"dispatcherWidgetMenuEdit": "Применить",
		"dispatcherWidgetMenuNotification": "Оповещение",
		"event": "Событие",
		"begin": "Начало",
		"duration": "Длительность",
		"status": "Статус",
		"object": "Объект",
		"dispatcherWidgetLink": "Действия",
		"dtAll": "Все",
		"proxyServer": "Прокси-сервер",
		"httpCode": "Код ответа",
		"error": "Ошибка",
		"additionally": "Дополнительно",
		"configItemShowGraph": "Посмотреть график",
		"configItemEmptyMetrics": "Метрики отсутствуют",
		"scenario": "Сценарий",
		"scenarios": "Сценарии",
		"stepDescription": "Описание шага",
		"step": "Шаг",
		"configItemLastValue": "Посл. значение, c",
		"configItemControlTime": "Контр. время, c",
		"value": "Значение",
		"metrics": "Метрики",
		"configItemGraph": "График",
		"configItemTable": "Таблица",
		"datetime": "Время",
		"dispatcherWidgetSendNotification": "Рассылка оповещений",
		"dispatcherWidgetReadNotification": "Просмотр оповещения",
		"dispatcherWidgetEditNotification": "Редактирование оповещения",
		"dispatcherWidgetWhom": "Кому",
		"dispatcherWidgetDeliveryLists": "Списки рассылок",
		"dispatcherWidgetUserLists": "Адресаты пользователи",
		"dispatcherWidgetSubjectMail": "Тема сообщения",
		"dispatcherWidgetSubject": "Тема",
		"dispatcherWidgetBodyMail": "Тело сообщения",
		"dispatcherWidgetSystemInformation": "Системное сообщение",
		"dispatcherWidgetAdditionalData": "Дополнительные сведения",
		"dispatcherWidgetInitiator": "Инициатор",
		"cancel": "Отмена",
		"dispatcherWidgetEnterDeliveryName": "Введите название рассылки",
		"dispatcherWidgetEnterUserSurname": "Введите фамилию пользователя",
		"dispatcherWidgetSelectEvent": "Выберите хотя бы одно событие",
		"dispatcherWidgetSelectDeliveryOrUser": "Выберите, пожалуйста, списки рассылок или адресатов",
		"dispatcherWidgetNotificationSended": "Оповещение отправлено",
		"dispatcherWidgetFailedSendNotification": "Не удалось отправить оповещение. Попробуйте через некоторое время",
		"dispatcherWidgetEventNo": "Номер события",
		"dispatcherWidgetLinkEvent": "Ссылка на события",
		"triggerName": "Имя триггера",
		"dispatcherWidgetInSystem": "В системе",
		"dispatcherWidgetEventsRegistered": "были зарегистрированы следующие события:",
		"failedGetPageData": "Не удалось загрузить данные для страницы. Попробуйте обновить страницу через некоторое время.",
		"save": "Сохранить",
		"apply": "Применить",
		"serverInternalError": "Временная ошибка, попробуйте немного позже.",
		"priorities": "Приоритеты",
		"prioritiesDurationAtLeast": "с длительностью не менее",
		"smTreeAllObjects": "Все объекты",
		"rsmBusinessLevel": "Бизнес",
		"rsmApplicationLevel": "Прикладной",
		"rsmSystemLevel": "Технический",
		"rsmShowAllObjectsBusinessLevel": "Показать все объекты на бизнес уровне",
		"rsmShowAllObjectsApplicationLevel": "Показать все объекты на прикладном уровне",
		"rsmShowAllObjectsSystemLevel": "Показать все объекты на техническом уровне",
		"rsmShowDependentsBusinessLevel": "Показать зависимые на бизнес уровне",
		"rsmShowDependentsApplicationLevel": "Показать зависимые на прикладном уровне",
		"rsmShowDependentsSystemLevel": "Показать зависимые на техническом уровне",
		"filtersNameFilter": "Название фильтра",
		"share": "Поделиться",
		"allPriorities": "Все приоритеты",
		"filtersCopiedFilter": "В буфер скопирована ссылка для отправки:",
		"filterMinute": "мин",
		"abbrMinute": "м",
		"abbrSecond": "c",
		"eventDetailsFailedGetEvent": "Не удалось загрузить данные для события",
		"incidents": "Инциденты",
		"forbidden": "Нет доступа",
		"pleaseEnterUrl": "Введите, пожалуйста, валидный URL",
		"pleaseEnterEmail": "Пожалуйста, введите электронный адрес",
		"pleaseFillField": "Пожалуйста, заполните это поле",
		"pleaseEnterMinCountSymbols": "Пожалуйста, введите не меньше {{ count }} символов",
		"scriptModalTabLog": "Лог запуска",
		"scriptModalTabVariables": "Переменные запуска",
		"dataIsEmpty": "Данные отсутствуют",
		"sourceSubtypeHost": "Узел",
		"sourceSubtypeTrigger": "Триггер"
	}
}