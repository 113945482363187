<docs lang="md">
    Поисковый инпут с кнопкой "очистить".
    Изначально появился для дерева - smTree
</docs>

<template>
    <div class="sm-search-input">
        <div class="input-group content-group search-wrapper">
            <div class="has-feedback has-feedback-left">
            <input
                :value="value"
                :disabled="disabled"
                :placeholder="placeholder"
                type="text"
                class="form-control col-md-12 search"
                @input="$emit('input', $event.target.value)"
                >
                <div class="form-control-feedback"><i class="icon-search4 text-muted text-size-base"/></div>
            </div>
            <div class="input-group-btn">
                <button
                    :title="__('workgroups.notifications.creation-dialog.clear-button')"
                    type="button"
                    class="btn btn-default clear"
                    @click="$emit('input', '')"
                    >
                    <i class="icon-eraser"/>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default Vue.component('sm-search-input', {
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
});
</script>

<style scoped>
input,
button {
    height: 35px;
}
</style>
