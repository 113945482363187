<template>
    <span ref="editable"/>
</template>

<script>
require('src/js/lib/bootstrap-editable.js');
$.fn.editable.defaults.mode = 'inline';

export default Vue.component('SmEditable', {
    props: {
        options: {
            type: Object,
            required: true
        },
        url: {
            type: null
        },
        value: {
            type: [String, Number]
        }
    },
    data() {
        return {
            internalModelChange: false
        };
    },
    watch: {
        value() {
            if (!this.internalModelChange) {
                this.destroyEditable();
                this.initEditable();
            }
        }
    },
    mounted() {
        this.initEditable();
    },
    beforeDestroy() {
        this.destroyEditable();
    },
    methods: {
        initEditable() {
            var options = this.options;
            if (options.value) {
                throw 'value необходимо передать через v-model';
            }
            if (!('value' in this.$options.propsData)) {
                throw 'необходимо задать v-model';
            }
            if (!options.emptytext) {
                throw 'задайте emptytext';
            }
            var opts = _.omit(this.options, 'url', 'value', 'type', 'inputclass');
            _.extend(opts, {
                type: options.type || 'text',
                inputclass: options.inputclass || 'form-control',
                value: this.value,
                url: (params) => {
                    this.internalModelChange = true;
                    this.$emit('input', params.value);
                    this.$nextTick(() => {
                        this.internalModelChange = false;
                    });

                    if (this.url) {
                        return this.url(params);
                    }
                }
            });
            $(this.$refs.editable).editable(opts);
        },
        destroyEditable() {
            $(this.$refs.editable)
                .editable('destroy')
                .html('');
        }
    }
});
</script>
