const i18next = require('i18next');
const $ = require('jquery');
const ruOldTranslates = require('src/config/customers/common.json5').ru;
const enOldTranslates = require('src/config/customers/cloud/translations.json5').en;

async function tryFindLocale(userId) {
    if (!userId) throw Error('userId is not defined');

    const userSettings = await $.ajax({
        url: `/api/webui/pl/v1/users/${sm.systemUser.id}/settings`
    });

    const userSettingsLocale = _(userSettings)
        .chain()
        .find((item) => item.key === 'ui.locale')
        .get('value')
        .first()
        .get('locale')
        .value();
    if (userSettingsLocale) return userSettingsLocale;

    const userspaceInfo = await $.ajax({
        url: '/api/webui/pl/v1/userspaces/current'
    });
    const userspaceLocale = _.get(userspaceInfo, 'lang');
    if (userspaceLocale) return userspaceLocale;

    return null;
}

function clearTemplateInsertions(obj) {
    function getClearedTemplateString(value) {
        const remover = (string) => {
            return {
                _value: string,
                remove(token) {
                    this._value = _.replace(this._value, token, '');
                    return this;
                },
                fixInterpolation(token) {
                    if (token.match(/\{.*}/)) this._value = _.replace(this._value, token, `{${token}}`);
                    return this;
                },
                replace(oldValue, newValue) {
                    this._value = _.replace(this._value, oldValue, newValue);
                    return this;
                },
                value() {
                    return this._value;
                }
            };
        };
        if (typeof value === 'string') {
            return remover(value)
                .remove('{count}')
                .remove('{pagesCount}')
                .remove(/(\|\s)\{count}(л|г|\w)/i)
                .remove('{create-new-button}')
                .remove(' {workMode}')
                .remove('{startTime}')
                .remove('{endTime}')
                .value();
        }
    }

    (function clearTemplateInsertionsWrapper(obj) {
        for (let key in obj) {
            if (typeof obj[key] === 'object') {
                clearTemplateInsertions(obj[key]);
                continue;
            }
            if (typeof obj[key] === 'string') {
                obj[key] = getClearedTemplateString(obj[key]);
                continue;
            }
        }
    })(obj);
    return obj;
}

module.exports = async function() {
    const userspaceInfo = await $.ajax({
        url: '/api/webui/pl/v1/userspaces/current'
    });

    const locale = (await tryFindLocale(sm.systemUser.id)) || 'ru-RU';
    const shortLocale = locale.substring(0, 2);
    sm.systemUser.lang.getLocale = ({isShort} = {}) => {
        if (isShort) {
            return shortLocale;
        }
        return locale;
    };
    sm.systemUser.lang.get = () => shortLocale;

    const customTranslates = {
        'ru-RU': {
            direction: 'Направление',
            'service-group': 'Группа услуг',
            'general-ci': 'Основная КЕ',
            'short-description': 'Краткое описание',
            'sending-notification': 'Отправка оповещения',
            'script-launch': 'Запуск скрипта'
        },
        'en-US': {
            direction: 'Direction',
            'service-group': 'Service group',
            'general-ci': 'General CI',
            'short-description': 'Short description',
            'sending-notification': 'Sending notification',
            'script-launch': 'Script launch'
        }
    };

    const translates = await $.ajax({
        url: `/api/webui/pl/v1/i18n/l10n/${locale}`
    });

    const neededTranslates = _.assign(
        {},
        {'custom-translates': customTranslates[locale]},
        clearTemplateInsertions(locale === 'ru-RU' ? ruOldTranslates : enOldTranslates),
        clearTemplateInsertions({'side-menu': translates.find((t) => t.scope === 'side-menu').data}),
        clearTemplateInsertions({sla: translates.find((t) => t.scope === 'sla').data}),
        clearTemplateInsertions({profile: translates.find((t) => t.scope === 'profile').data}),
        clearTemplateInsertions({common: translates.find((t) => t.scope === 'common').data}),
        clearTemplateInsertions({workgroups: translates.find((t) => t.scope === 'workgroups').data}),
        clearTemplateInsertions({users: translates.find((t) => t.scope === 'users').data}),
        clearTemplateInsertions({'old-main': translates.find((t) => t.scope === 'old-main').data}),
        clearTemplateInsertions({'old-timeline': translates.find((t) => t.scope === 'old-timeline').data}),
        clearTemplateInsertions({'rules-actions': translates.find((t) => t.scope === 'rules-actions').data}),
        clearTemplateInsertions({'data-streams': translates.find((t) => t.scope === 'data-streams').data}),
        {'ci-profile': translates.find((t) => t.scope === 'ci-profile').data}
    );

    i18next.init({
        lng: locale,
        ns: 'dit',
        defaultNS: 'dit',
        resources: {
            [locale]: {
                dit: neededTranslates,
                userspace: {
                    systemTitle: userspaceInfo.name
                }
            }
        }
    });
    window.__ = _.bind(i18next.t, i18next);
    _.templateSettings.__ = __;

    moment.locale(locale);

    document.querySelector('title').innerHTML = __('systemTitle', {ns: 'userspace'});
    // Временно убран вывод копирайта в футере
    // document.getElementById(
    //     'copyright-footer'
    // ).innerHTML = `&copy; 2016 &mdash; ${new Date().getFullYear()} <span>&nbsp; ${__('systemTitle', {
    //     ns: 'userspace'
    // })}</span>`;

    if (window.numeral) {
        require(`numeral/locales/${shortLocale === 'ru' ? 'ru' : 'en-gb'}.js`);
        if (numeral.locales.ru) {
            numeral.locales.ru.ordinal = (number) => {
                var b = number % 10;
                if (number > 10 && number < 20) {
                    return 'ый';
                }
                if (b === 3) {
                    return 'ий';
                }
                if (b === 2 || (b > 5 && b < 9)) {
                    return 'ой';
                }
                return 'ый';
            };
        }
        numeral.locale(shortLocale);
    }

    if (sm.systemUser.lang.get() !== 'en') {
        require(`src/js/lib/summernote/lang/summernote-${sm.systemUser.lang.getLocale()}.js`);
    }

    if ($.fancybox) {
        _.merge($.fancybox.defaults, {
            autoSize: false,
            autoHeight: true,
            tpl: {
                error: `<p class="fancybox-error">${__('common.alerts.error-title')}</p>`,
                closeBtn: `<a title="${__(
                    'common.close-button'
                )}" class="fancybox-item fancybox-close" href="javascript:;"></a>`,
                next: `<a title="${__(
                    'common.lists.pagination.next-page'
                )}" class="fancybox-nav fancybox-next" href="javascript:;"><span></span></a>`,
                prev: `<a title="${__(
                    'common.lists.pagination.previous-page'
                )}" class="fancybox-nav fancybox-prev" href="javascript:;"><span></span></a>`
            }
        });
    }

    if ($.datepicker) {
        if (shortLocale !== 'en') {
            require(`src/js/lib/jqueryui.i18n.datepicker-${shortLocale}.js`);
        }
        $.datepicker.setDefaults($.datepicker.regional[shortLocale]);
    }

    if (window.moment) {
        if (shortLocale !== 'en') {
            require(`src/js/lib/moment/moment-locale.${shortLocale}.js`);
        }
        moment.locale(shortLocale);
    }

    if ($.noty) {
        $.noty.defaults.animation = {
            open: {opacity: 'toggle'},
            close: {opacity: 'toggle'},
            easing: 'swing',
            speed: 250
        };
        $.noty.defaults.theme = 'limitlessTheme';
        $.noty.themes.limitlessTheme = {
            name: 'limitlessTheme',
            helpers: {
                borderFix() {
                    if (this.options.dismissQueue) {
                        var b = `${this.options.layout.container.selector} ${this.options.layout.parent.selector}`;
                        switch (this.options.layout.name) {
                            case 'top':
                                $(b).css({borderRadius: '0px 0px 0px 0px'}),
                                    $(b)
                                        .last()
                                        .css({borderRadius: '0px 0px 3px 3px'});
                                break;

                            case 'topCenter':
                            case 'topLeft':
                            case 'topRight':
                            case 'bottomCenter':
                            case 'bottomLeft':
                            case 'bottomRight':
                            case 'center':
                            case 'centerLeft':
                            case 'centerRight':
                            case 'inline':
                                $(b).css({borderRadius: '0px 0px 0px 0px'}),
                                    $(b)
                                        .first()
                                        .css({
                                            'border-top-left-radius': '3px',
                                            'border-top-right-radius': '3px'
                                        }),
                                    $(b)
                                        .last()
                                        .css({
                                            'border-bottom-left-radius': '3px',
                                            'border-bottom-right-radius': '3px'
                                        });
                                break;
                            case 'bottom':
                                $(b).css({borderRadius: '0px 0px 0px 0px'}),
                                    $(b)
                                        .first()
                                        .css({borderRadius: '3px 3px 0px 0px'});
                        }
                    }
                }
            },

            modal: {
                css: {
                    position: 'fixed',
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#333',
                    zIndex: 1e4,
                    opacity: 0.6,
                    display: 'none',
                    left: 0,
                    top: 0
                }
            },

            style() {
                this.$bar.css({overflow: 'hidden', background: ''});
                this.$message.css({
                    fontSize: '13px',
                    lineHeight: '1.5384616',
                    textAlign: 'center',
                    padding: '15px 20px',
                    width: 'auto',
                    position: 'relative'
                });
                this.$closeButton.css({
                    position: 'absolute',
                    top: -2,
                    right: 6,
                    opacity: 0,
                    background: '',
                    cursor: 'pointer'
                });
                this.$closeButton.append(
                    `<span aria-hidden="true" style="font-size: 20px;">&times;</span><span class="sr-only">${__(
                        'close'
                    )}</span>`
                );
                this.$buttons.css({
                    padding: '15px 20px',
                    textAlign: 'right',
                    borderTop: '1px solid #ddd',
                    backgroundColor: '#fff'
                });
                this.$buttons.find('button').css({marginLeft: 5});
                this.$buttons.find('button:first').css({marginLeft: 0});
                this.$bar.on({
                    mouseenter() {
                        $(this)
                            .find('.noty_close')
                            .stop()
                            .fadeTo('normal', 1);
                    },
                    mouseleave() {
                        $(this)
                            .find('.noty_close')
                            .stop()
                            .fadeTo('normal', 0);
                    }
                });
                switch (this.options.layout.name) {
                    case 'top':
                        this.$bar.css({borderRadius: '0px 0px 3px 3px', marginBottom: 1});
                        break;
                    case 'topCenter':
                    case 'center':
                    case 'bottomCenter':
                    case 'inline':
                        this.$bar.css({borderRadius: '3px', marginBottom: 1}),
                            this.$message.css({
                                fontSize: '13px',
                                textAlign: 'center'
                            });
                        break;
                    case 'topLeft':
                    case 'topRight':
                    case 'bottomLeft':
                    case 'bottomRight':
                    case 'centerLeft':
                    case 'centerRight':
                        this.$bar.css({borderRadius: '3px', marginBottom: 1}),
                            this.$message.css({
                                fontSize: '13px',
                                textAlign: 'left'
                            });
                        break;
                    case 'bottom':
                        this.$bar.css({borderRadius: '3px 3px 0px 0px', marginTop: 1});
                        break;

                    default:
                        this.$bar.css({});
                }
                switch (this.options.type) {
                    case 'alert':
                    case 'notification':
                        this.$bar.css({backgroundColor: '#2196F3', color: '#fff'});
                        break;
                    case 'warning':
                        this.$bar.css({backgroundColor: '#FF7043', color: '#fff'}), this.$buttons.css({});
                        break;
                    case 'error':
                        this.$bar.css({
                            backgroundColor: '#EF5350',
                            color: '#fff'
                        }),
                            this.$message.css({}),
                            this.$buttons.css({});
                        break;
                    case 'information':
                        this.$bar.css({backgroundColor: '#00BCD4', color: '#fff'}), this.$buttons.css({});
                        break;
                    case 'success':
                        this.$bar.css({backgroundColor: '#4CAF50', color: '#fff'}), this.$buttons.css({});
                        break;

                    default:
                        this.$bar.css({backgroundColor: '#FFF', border: '1px solid #CCC', color: '#333'});
                }
            },
            callback: {
                onShow() {
                    $.noty.themes.limitlessTheme.helpers.borderFix.apply(this);
                },
                onClose() {
                    $.noty.themes.limitlessTheme.helpers.borderFix.apply(this);
                }
            }
        };
    }

    if ($.fn.dataTable) {
        $.extend(true, $.fn.dataTable.defaults, {
            language: {
                processing: __('dtProcessing'),
                search: '',
                lengthMenu: `${__('dtLengthMenu')}`,
                info: __('dtInfo'),
                infoEmpty: __('dtInfoEmpty'),
                infoFiltered: __('dtInfoFiltered'),
                infoPostFix: '',
                loadingRecords: __('dtLoadingRecords'),
                zeroRecords: __('dtZeroRecords'),
                emptyTable: __('common.alerts.no-data'),
                paginate: {
                    first: __('dtPaginateFirst'),
                    last: __('dtPaginateLast'),
                    next: '&rarr;',
                    previous: '&larr;'
                },
                thousands: '&thinsp;',
                aria: {
                    sortAscending: __('dtAriaSortAscending'),
                    sortDescending: __('dtAriaSortDescending')
                }
            }
        });

        $.fn.dataTable.ext.errMode = 'none';

        var numberAscFunc = function(a, b) {
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        };
        $.extend($.fn.dataTableExt.oSort, {
            'sm.duration-asc': numberAscFunc,
            'sm.duration-desc': -numberAscFunc
        });
        $.extend($.fn.dataTableExt.oSort, {
            'sm.datetime-asc': numberAscFunc,
            'sm.datetime-desc': -numberAscFunc
        });
        var stringStartsNumberAscFunc = function(a, b) {
            a = parseFloat(a);
            b = parseFloat(b);
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        };
        $.extend($.fn.dataTableExt.oSort, {
            'sm.stringStartsNumber-asc': stringStartsNumberAscFunc,
            'sm.stringStartsNumber-desc': -stringStartsNumberAscFunc
        });
    }

    sm.utils.showConfirm = function(opts) {
        noty({
            text: opts.question,
            type: 'confirm',
            dismissQueue: false,
            layout: 'center',
            modal: true,
            closeWith: [],
            buttons: opts.buttons || [
                {
                    addClass: 'btn btn-primary',
                    text: opts.yesLabel || __('yes'),
                    onClick: opts.onAgree
                },
                {
                    addClass: 'btn btn-default',
                    text: opts.noLabel || __('no'),
                    onClick: opts.onDecline
                }
            ],
            callback: {
                onShow() {
                    this.$buttons.addClass('btns-block');
                }
            }
        });
    };

    sm.utils.showPrompt = function(opts) {
        noty({
            text: opts.question,
            type: 'prompt',
            template: `<form><div class="noty_message form-group"><label class="control-label w-100"><span class="noty_text"></span><input type="text" autofocus class="form-control" value="${opts.startText}"></label><span class="help-block text-danger"></span><div class="noty_close"></div></div></form>`,
            dismissQueue: false,
            layout: 'center',
            modal: true,
            closeWith: [],
            buttons: [
                {
                    addClass: 'btn btn-primary',
                    text: opts.yesLabel || __('yes'),
                    onClick: $.noop
                },
                {
                    addClass: 'btn btn-default',
                    text: opts.noLabel || __('no'),
                    onClick: opts.onDecline
                }
            ],
            callback: {
                onShow() {
                    var noty = this;
                    this.$bar.find('form').on('submit', (e) => {
                        e.preventDefault();
                        opts.onAgree(noty);
                    });
                    this.$bar
                        .find('.noty_text')
                        .contents()
                        .unwrap();
                    this.$buttons.addClass('btns-block');
                    this.$buttons.find('.btn-primary').attr('type', 'submit');
                    this.$bar.find('form').append(this.$buttons);

                    this.$validationMsg = this.$bar.find('.help-block');
                    this.$validationMsg.empty();
                },

                afterShow() {
                    $('.noty_type_prompt input').focus();
                }
            }
        });
    };

    sm.constants.durationFormat = `Y[${__('old-main.events-of-the-period.time.years')}] M[${__(
        'old-main.events-of-the-period.time.months'
    )}] d[${__('old-main.events-of-the-period.time.days')}] h[${__('old-main.events-of-the-period.time.hours')}] m[${__(
        'old-main.events-of-the-period.time.minutes'
    )}] s[${__('old-main.events-of-the-period.time.seconds')}]`;

    const priorities = [
        {
            id: 1,
            color: sm.configItemStatesColors.Problem1,
            shortDescription: '1 - Критический',
            description: 'Не доступен веб-интерфейс',
            name: `${numeral(1).format('0o')} ${__('old-main.events-of-the-period.priority-column').toLowerCase()}`
        },
        {
            id: 2,
            color: sm.configItemStatesColors.Problem2,
            shortDescription: '2 - Высокий',
            description: 'Провалено 2 и более сценариев ФМ',
            name: `${numeral(2).format('0o')} ${__('old-main.events-of-the-period.priority-column').toLowerCase()}`
        },
        {
            id: 3,
            color: sm.configItemStatesColors.Problem3,
            shortDescription: '3 - Средний',
            description: 'Провален 1 сценарий',
            name: `${numeral(3).format('0o')} ${__('old-main.events-of-the-period.priority-column').toLowerCase()}`
        },
        {
            id: 4,
            color: sm.configItemStatesColors.Problem4,
            shortDescription: '4 - Низкий',
            description: 'Превышено контрольное время исполнения сценариев',
            name: `${numeral(4).format('0o')} ${__('old-main.events-of-the-period.priority-column').toLowerCase()}`
        }
    ];
    sm.constants.priorities = {
        getById: (id) => _.find(priorities, {id})
    };

    const configItemStates = [
        {
            id: 'Regular',
            name: __('common.ci.status.regular'),
            color: sm.configItemStatesColors.Regular
        },
        {
            id: 'Maintenance',
            name: __('common.ci.status.service'),
            color: sm.configItemStatesColors.Maintenance
        },
        {
            id: 'Unknown',
            name: __('common.ci.status.unknown'),
            color: sm.configItemStatesColors.Unknown
        }
    ];
    configItemStates.push(
        ..._.map([1, 2, 3, 4], (id) => {
            const data = sm.constants.priorities.getById(id);
            return {
                id: `Problem${id}`,
                name: `${__('common.ci.status.emergency')} - ${data.name}`,
                color: data.color
            };
        })
    );
    sm.constants.configItemStates = {
        getById: (id) => _.find(configItemStates, {id})
    };

    sm.Datatable.defaultLengthMenu = [
        [10, 25, 50, 100, -1],
        [10, 25, 50, 100, __('old-main.filter.all-check')]
    ];

    const durationUnits = [
        {name: __('old-main.filter.priority-filter.minutes'), inSeconds: 60},
        {name: __('old-main.filter.priority-filter.hours'), inSeconds: 60 * 60},
        {name: __('old-main.filter.priority-filter.days'), inSeconds: 60 * 60 * 24}
    ];
    sm.PriorityService.prototype.getDurationUnits = () => durationUnits;

    Vue.set(Vue.prototype, '__', __);
};
