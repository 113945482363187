<template>
    <div class="input-container input-container_password">
        <template v-if="showInput">
            <sm-labeled-node :label="showInputLabel">
                <slot/>
            </sm-labeled-node>
            <button :disabled="disabled" type="button" :key="1" v-show="valueExists" class="sm-button sm-button_text" @click="$emit('password-toggle', false, $event)">
                <span>{{ stayTheSameLabel }}</span>
            </button>
        </template>
        <button :disabled="disabled" v-else v-show="valueExists" :key="2" type="button" class="sm-button sm-button_full-width sm-button_field-style toogle-password-true" @click="$emit('password-toggle', true)">
            <i class="material-icons">vpn_key</i>
            <span>{{ changeLabel }}</span>
        </button>
    </div>
</template>

<script>
// Обертка для скрытия/показа значений, например, пароля
export default Vue.component('SmChangeValue', {
    inject: ['$validator'],

    props: {
        showInput: {
            type: Boolean,
            required: true
        },

        valueExists: {
            type: Boolean,
            default: true
        },

        changeLabel: {
            type: String,
            default: () => __('profile.general-info.change-password-button')
        },

        stayTheSameLabel: {
            type: String,
            default: () => __('common.cancel-button')
        },

        showInputLabel: {
            type: String,
            default: () => __('profile.general-info.password-label')
        },

        disabled: {
            type: Boolean,
            default: false
        }
    }
});
</script>

<style lang="less">
.toogle-password-true {
    visibility: visible;
}

.input-container_password {
    .material-icons {
        opacity: 0.75;
    }

    .input-container {
        padding-right: 0;
        padding-bottom: 0;

        .input-container__error {
            bottom: -20px;
        }
    }

    &:not(.input-container_required) {
        .input-container__label {
            display: none;
        }
    }

    .sm-button_text {
        position: absolute;
        right: 0;
        top: 0;
    }
}
</style>
