'use strict';

import 'core-js/shim';
import 'regenerator-runtime/runtime';

(function() {
    require('src/css/overrides.less');
    require('src/css/common.less');

    require('src/js/overrides.js');
    require('src/js/utils.js');
    require('src/js/module.js');
    require('src/js/smDatatable.js');
    require('src/js/services/service.js');
    require('src/js/services/noCacheService.js');
    require('src/js/services/settings/userSettingService.js');
    require('src/js/services/priorityService.js');
    require('src/js/services/rsm/ciCachedService.js');
    require('src/js/page.js');
    require('src/js/services/i18n.js');
    require('src/js/vComponents.js');
})();
