<template>
    <div>
        <ul :class="[headerClass]" class="nav nav-tabs">
            <li v-for="tab in orderedTabs" :key="tab.tabIndex" :class="{ active: (tab.tabIndex === activeTabIndex), disabled: tab.disabled }">
                <a href="#" @click.prevent="activeTabIndex = tab.tabIndex">
                    <span v-if="!tab.$slots.title">
                        {{ tab.title }}
                    </span>
                    <span v-else>
                        <portal-target :name="tab.tabIndex" :slim="true"/>
                    </span>
                </a>
            </li>
        </ul>
        <div :class="[contentClass]" class="tab-content">
            <slot/>
        </div>
    </div>
</template>

<script>
export default Vue.component('SmTabs', {
    props: {
        headerClass: {
            type: String
        },
        contentClass: {
            type: String
        }
    },
    data() {
        return {
            tabs: [],
            activeTabIndex: null
        };
    },
    computed: {
        orderedTabs() {
            return _.orderBy(this.tabs, 'order');
        },
        activeId() {
            if (this.activeTabIndex == null) {
                return null;
            }
            let foundTab = _.find(this.tabs, {tabIndex: this.activeTabIndex});
            if (!foundTab) return null;
            return _.get(foundTab, '$vnode.data.ref');
        }
    },
    watch: {
        activeTabIndex(val) {
            if (this.activeTabIndex != null) {
                let foundTab = _.find(this.tabs, {tabIndex: this.activeTabIndex});
                if (foundTab) foundTab.renderedContent = true;
            }
        },
        tabs: {
            handler(val) {
                var count = val.length;
                if (count) {
                    if (this.activeTabIndex == null) {
                        this.activeTabIndex = this.tabs[0].tabIndex;
                    }
                }
            },
            immediate: true
        }
    },
    methods: {
        toggle(val) {
            _.each(this.tabs, function(tab) {
                if (!tab.isActive) {
                    tab.disabled = !val;
                }
            });
        },
        getActiveTab() {
            return _.find(this.tabs, (tab) => tab.isActive);
        }
    }
});
</script>
