var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.menuItem
    ? _c(
        "div",
        {
          staticClass: "menu__item",
          class: {
            "menu__item--opened":
              _vm.menuItem.type === "Menu" && _vm.showMenu(),
            "menu_item--one-link":
              _vm.menuItem.type === "Menu" &&
              _vm.menuItem.items &&
              _vm.menuItem.items.length === 1,
            "menu_item--many-link":
              _vm.menuItem.type === "Menu" &&
              _vm.menuItem.items &&
              _vm.menuItem.items.length > 1
          },
          attrs: { id: _vm.getId() },
          on: { mouseenter: _vm.mouseEnter, mouseleave: _vm.mouseLeave }
        },
        [
          _vm.menuItem.type === "Label"
            ? _c(
                "a",
                {
                  staticClass: "menu__link menu__link--label",
                  attrs: { tabindex: "-1" },
                  on: {
                    click: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "left", 37, $event.key, [
                          "Left",
                          "ArrowLeft"
                        ])
                      ) {
                        return null
                      }
                      if (
                        $event.ctrlKey ||
                        $event.shiftKey ||
                        $event.altKey ||
                        $event.metaKey
                      ) {
                        return null
                      }
                      if ("button" in $event && $event.button !== 0) {
                        return null
                      }
                      $event.preventDefault()
                      _vm.handle()
                    }
                  }
                },
                [
                  _c("div", { staticClass: "menu__info" }, [
                    _vm.isUserProfile()
                      ? _c("div", { staticClass: "menu__user menu-user" }, [
                          _vm.menuItem.image
                            ? _c("span", { staticClass: "menu__icon" }, [
                                _c("img", {
                                  staticClass: "menu__image",
                                  attrs: {
                                    src: _vm.menuItem.image,
                                    width: "32",
                                    height: "32"
                                  }
                                })
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.menuItem.image
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "menu-user__icon menu-user__icon--default"
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "menu-user__text" },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(_vm.getImageText()) +
                                          "\n                    "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isUserProfile()
                      ? _c("span", { staticClass: "menu__icon" }, [
                          _c("img", {
                            staticClass: "menu__image",
                            attrs: {
                              src: _vm.getMenuIcon(),
                              width: "32",
                              height: "32"
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "menu__text",
                        attrs: { title: _vm.menuItem.title }
                      },
                      [
                        _c("span", { staticClass: "menu__title" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.__(_vm.menuItem.title)) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _vm.menuItem.description
                          ? _c("span", { staticClass: "menu__description" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.__(_vm.menuItem.description)) +
                                  "\n                "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.menuItem.type === "Menu" || _vm.menuItem.type === "Link"
            ? _c(
                "a",
                {
                  staticClass: "menu__link",
                  class: _vm.getClassesWithActive(),
                  attrs: { href: _vm.menuItem.url },
                  on: {
                    click: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "left", 37, $event.key, [
                          "Left",
                          "ArrowLeft"
                        ])
                      ) {
                        return null
                      }
                      if (
                        $event.ctrlKey ||
                        $event.shiftKey ||
                        $event.altKey ||
                        $event.metaKey
                      ) {
                        return null
                      }
                      if ("button" in $event && $event.button !== 0) {
                        return null
                      }
                      $event.preventDefault()
                      _vm.handle()
                    },
                    focus: function($event) {
                      return _vm.focus()
                    },
                    focusout: function($event) {
                      return _vm.focusOut()
                    }
                  }
                },
                [
                  _c("div", { staticClass: "menu__info" }, [
                    _vm.isUserProfile()
                      ? _c("div", { staticClass: "menu__user menu-user" }, [
                          _vm.menuItem.image
                            ? _c("span", { staticClass: "menu__icon" }, [
                                _c("img", {
                                  staticClass: "menu__image",
                                  attrs: {
                                    src: _vm.menuItem.image,
                                    width: "32",
                                    height: "32"
                                  }
                                })
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.menuItem.image
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "menu-user__icon menu-user__icon--default"
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "menu-user__text" },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(_vm.getImageText()) +
                                          "\n                    "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isUserProfile()
                      ? _c("span", { staticClass: "menu__icon" }, [
                          _c("img", {
                            staticClass: "menu__image",
                            attrs: {
                              src: _vm.getMenuIcon(),
                              width: "32",
                              height: "32"
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "menu__text",
                        attrs: { title: _vm.menuItem.title }
                      },
                      [
                        _c("span", { staticClass: "menu__title" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.__(_vm.menuItem.title)) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _vm.menuItem.description
                          ? _c("span", { staticClass: "menu__description" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.__(_vm.menuItem.description)) +
                                  "\n                "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showMenu() && _vm.menuItem.type === "Menu"
            ? _c("SmSidebarMenu", {
                attrs: {
                  parent: _vm.menuItem,
                  items: _vm.menuItem.items,
                  expandOnMouseEvents: false,
                  menuExpanded: true
                },
                on: {
                  onMenuInit: function($event) {
                    return _vm.menuInit()
                  },
                  onMouseItemClick: function($event) {
                    return _vm.handle()
                  },
                  onExecuteScript: _vm.executeScript
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.menuItem.type === "Script" || _vm.menuItem.type === "Func"
            ? _c(
                "a",
                {
                  staticClass: "menu__link",
                  class: _vm.menuItem.classes,
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.handle()
                    },
                    focus: function($event) {
                      return _vm.focus()
                    },
                    focusout: function($event) {
                      return _vm.focusOut()
                    }
                  }
                },
                [
                  _c("div", { staticClass: "menu__info" }, [
                    _vm.menuItem.image
                      ? _c("span", { staticClass: "menu__icon" }, [
                          _c("img", {
                            staticClass: "menu__image",
                            attrs: {
                              src: _vm.menuItem.image,
                              width: "32",
                              height: "32"
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.menuItem.image
                      ? _c("span", { staticClass: "menu__icon" }, [
                          _c("img", {
                            staticClass: "menu__image",
                            attrs: {
                              src: _vm.getMenuIcon(),
                              width: "32",
                              height: "32"
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu__text" }, [
                      _c("span", { staticClass: "menu__title" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.__(_vm.menuItem.title)) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.menuItem.description
                        ? _c("span", { staticClass: "menu__description" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.__(_vm.menuItem.description)) +
                                "\n                "
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-8d46dc58", { render: render, staticRenderFns: staticRenderFns })
  }
}