<script>
export default Vue.component('SmDatatable', {
    functional: true,

    render(h, context) {
        context.children = context.children || [];
        var parentH = context.parent.$createElement;

        if (!context.slots().header) {
            context.children.push(
                parentH(
                    'div',
                    {
                        slot: 'header'
                    },
                    [parentH('sm-datatable-btn', {props: {type: 'search'}})]
                )
            );
        }
        if (!context.slots().footer) {
            context.children.push(
                parentH(
                    'div',
                    {
                        slot: 'footer'
                    },
                    _.get(context.props.options, 'sm.asList')
                        ? [parentH('sm-datatable-btn', {props: {type: 'shortPaging'}})]
                        : [
                              parentH('sm-datatable-btn', {props: {type: 'length'}}),
                              parentH('sm-datatable-btn', {props: {type: 'paging'}})
                          ]
                )
            );
        }

        return parentH(require('./datatable.vue').default, context.data, context.children);
    }
});
</script>
