<template>
    <div>
        <sm-select v-if="opts.data" :value="value" @input="$emit('input', $event)" v-bind="opts" :multiple="multiple">
            <template slot="option" slot-scope="props">
                <sm-ci-icon :ci="{configItemType: {icon: props.option.icon}}" :key="props.option.icon.name"/>
                <span>
                    {{ getTranslate(props.option.name) }}
                </span>
            </template>
            <template slot="tag" slot-scope="{ option, remove }">
                <span class="multiselect__tag">
                    <sm-ci-icon :ci="{configItemType: {icon: option.icon}}" :key="option.icon.name"/>
                    <span>
                        {{ option.name }}
                    </span>
                    <i class="multiselect__tag-icon" @click="remove(option)"/>
                </span>
            </template>
        </sm-select>
    </div>
</template>

<script>
import ServiceTypeService from 'src/js/services/rsm/serviceTypeService.js';
import Multiselect from 'vue-multiselect';

export default Vue.component('SmCiTypeSelector', {
    components: {
        Multiselect
    },
    props: {
        value: {
            type: [Object, Array],
            required: true
        },
        multiple: {
            type: Boolean,
            default: true
        }
    },
    data() {
        var selectionPluralCases = {
            nom: 'выбранный тип КЕ',
            gen: 'выбранных типа КЕ',
            plu: 'выбранных типов КЕ'
        };
        return {
            opts: {
                placeholder: __('old-main.filter.ci-type-combo'),
                pluralLabelMinimumSelectionLength: 0,
                selectionPluralCases
            },
            ciTranslates: {
                'По умолчанию': __('common.ci.type.Default'),
                АТС: __('common.ci.type.TelephoneSwitch'),
                'Бизнес-сервис': __('common.ci.type.BusinessService'),
                'Веб-ресурс': __('common.ci.type.WebResource'),
                'Веб-сервис': __('common.ci.type.WebService'),
                'Виртуальный сервер': __('common.ci.type.VM'),
                ИБП: __('common.ci.type.UPS'),
                ИС: __('common.ci.type.IS'),
                'Канал связи': __('common.ci.type.CommChannel'),
                Кластер: __('common.ci.type.Cluster'),
                Коммутатор: __('common.ci.type.Switch'),
                Маршрутизатор: __('common.ci.type.Router'),
                'Межсетевой экран': __('common.ci.type.Firewall'),
                'Модуль ИС': __('common.ci.type.ISModule'),
                Оборудование: __('common.ci.type.Equipment'),
                ПО: __('common.ci.type.Software'),
                СХД: __('common.ci.type.SAN'),
                Услуга: __('common.ci.type.Service')
            }
        };
    },
    created() {
        new ServiceTypeService().fetch().done((types) => {
            Vue.set(this.opts, 'data', types);
        });
    },
    methods: {
        getTranslate(originalName) {
            if (sm.systemUser.lang.get() === 'ru') return originalName;
            return this.ciTranslates[originalName] || originalName;
        }
    }
});
</script>
