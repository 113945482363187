var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "ul",
      { staticClass: "nav nav-tabs", class: [_vm.headerClass] },
      _vm._l(_vm.orderedTabs, function(tab) {
        return _c(
          "li",
          {
            key: tab.tabIndex,
            class: {
              active: tab.tabIndex === _vm.activeTabIndex,
              disabled: tab.disabled
            }
          },
          [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.activeTabIndex = tab.tabIndex
                  }
                }
              },
              [
                !tab.$slots.title
                  ? _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(tab.title) +
                          "\n                "
                      )
                    ])
                  : _c(
                      "span",
                      [
                        _c("portal-target", {
                          attrs: { name: tab.tabIndex, slim: true }
                        })
                      ],
                      1
                    )
              ]
            )
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tab-content", class: [_vm.contentClass] },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-08a2c12a", { render: render, staticRenderFns: staticRenderFns })
  }
}