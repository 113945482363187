export class UserPacketsHttpService extends sm.Service {
    constructor() {
        arguments.singleton = true;
        super(...arguments);
    }

    makeUrl() {
        return ``;
    }

    async getUserPackets(userId) {
        this.url = `/api/webui/pl/v1/user-grants/users/${userId}/packets`;
        return await this.fetch();
    }
}
