var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ci
    ? _c(
        "a",
        _vm._b(
          {
            attrs: {
              href: "/configItem.html?id=" + _vm.ci.id,
              target: "_blank",
              title: _vm.ci.name
            }
          },
          "a",
          _vm.attrs,
          false
        ),
        [_c("sm-ci-name", { attrs: { ci: _vm.ci } })],
        1
      )
    : _c("span", {
        domProps: {
          innerHTML: _vm._s(
            _vm.__("old-main.events-of-the-period.no-parent-label")
          )
        }
      })
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-42645832", { render: render, staticRenderFns: staticRenderFns })
  }
}