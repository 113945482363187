export class MenuService {
    isRouteLocal(pathname, location) {
        const baseHref = location.pathname;
        return pathname.startsWith(baseHref);
    }

    removeBaseHrefFromUrl(pathname, location) {
        const baseHref = location.pathname;
        if (baseHref === '/') return pathname;
        const r = new RegExp(`^(${baseHref.replace('/', '\\/')})`);
        const result = pathname.replace(r, '');
        return result;
    }

    isMenuItemActive(item) {
        if (item.type !== MenuItemTypesEnum.Menu && item.type !== MenuItemTypesEnum.Link) {
            return false;
        }

        return menuItem.url === window.location.pathname;
    }

    urlNavigate(menuItem) {
        if (!menuItem || !menuItem.url) {
            return;
        }

        // TODO: Выяснить как работать со ссылками на другие интерфейсы Angular.
        if (menuItem.urlType === 'Relative') {
            window.location.href = `${window.location.origin}${menuItem.url}`;
        } else if (menuItem.urlType === 'Absolute') {
            window.open(menuItem.url, '_blank');
        }
    }
}
