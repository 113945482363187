'use strict';
import RSM_SERVICE_ICONS from './const/serviceIcons.js';

(function() {
    window.sm = window.sm || {};

    sm.isDev = sm.environment === 'development';

    /* eslint-disable no-useless-concat */
    sm['customer' + ''] = `${sm.customer}`;
    /* eslint-enable no-useless-concat */

    sm.utils = sm.utils || {};

    /**
     * Возвращает единицу измерения с правильным окончанием
     *
     * @param {Number} num      Число
     * @param {Object} cases    Варианты слова {nom: 'час', gen: 'часа', plu: 'часов'}
     * @return {String}
     */
    sm.utils.units = function(num, cases) {
        if (!cases.nom) {
            throw 'определите nom';
        }
        cases.gen = cases.gen || cases.nom;
        cases.plu = cases.plu || cases.nom;

        num = Math.abs(num);

        var word = '';

        if (num.toString().indexOf('.') > -1) {
            word = cases.gen;
        } else {
            word =
                num % 10 === 1 && num % 100 !== 11
                    ? cases.nom
                    : num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)
                        ? cases.gen
                        : cases.plu;
        }

        return word;
    };

    sm.utils.pluralize = function(num, cases, short = false) {
        return `${num === 0 ? 'нет ' : !short || num > 1 ? `${num} ` : ''}${sm.utils.units(num, cases)}`;
    };

    sm.utils.ellipsis = function(str, length) {
        return _.truncate(_.trim(str), {
            length: length + '...'.length,
            separator: /[\\\/,\.:;"?!\s]+/
        });
    };

    sm.utils.trimMultipleBlankLines = function(str) {
        return str && str.replace(/(\n|\r\n){2,}/g, '\n\n');
    };

    sm.utils.getUrlParameterByName = function(name, url) {
        if (!url) {
            url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`),
            results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };

    sm.utils.getPageName = function(name) {
        if (name == null) {
            name = location.pathname.slice(1);
        }
        if (name === '') {
            name = 'index.html';
        }
        return name.slice(0, -5);
    };

    sm.utils.isPageActive = function(href) {
        return sm.utils.getPageName() === href.slice(0, -5);
    };

    // необходимо e.preventDefault у файловой ссылки
    sm.utils.downloadFileOrBlob = function({href, blob, name}) {
        if (!name) {
            if (href) {
                name = _.last(href.split('/'));
            } else {
                console.error('необходим name');
                return;
            }
        }
        var notyManager = {close: $.noop};
        return $
            .when(
                href
                    ? $
                          .ajax({
                              url: href,
                              type: 'GET',
                              dataType: 'binary',
                              progress(percents) {
                                  percents += '%';
                                  if (notyManager.close === $.noop) {
                                      notyManager = sm.utils.showMessage({
                                          message: `<div>Cкачивание файла <b>${name}</b>:&nbsp;<span>${percents}</span></div>`,
                                          timeout: false
                                      });
                                  } else {
                                      notyManager.$message
                                          .find('.noty_text')
                                          .find('span')
                                          .html(percents);
                                  }
                              },
                              processData: false
                          })
                          .fail(function(jqXHR) {
                              notyManager.close();
                              sm.utils.showError(_.get(jqXHR, 'responseJSON.message', 'Файл не найден'));
                          })
                    : blob
            )
            .done(function(blob) {
                if (navigator && navigator.msSaveBlob) {
                    navigator.msSaveBlob(blob, name);
                } else {
                    var url = window.URL.createObjectURL(blob);
                    var anchor = document.createElement('a');
                    anchor.href = url;
                    anchor.download = name;
                    document.body.appendChild(anchor);
                    anchor.click();
                    document.body.removeChild(anchor);
                    window.URL.revokeObjectURL(url);
                }
                notyManager.close();
                sm.utils.showSuccess(`Файл <b>${name}</b> успешно скачан`);
            })
            .fail(function(jqXHR) {
                notyManager.close();
                sm.utils.showError(_.get(jqXHR, 'responseJSON.message', 'Файл не найден'));
            });
    };

    sm.utils.emptyXhr = {abort: _.noop};

    // @see http://stackoverflow.com/questions/2648293/javascript-get-function-name
    sm.utils.getFunctionName = function(func) {
        var realFunc;
        if (_.isFunction(func) === true) {
            realFunc = func;
        } else if (_.isFunction(func.constructor) === true) {
            realFunc = func.constructor;
        }
        var result = /^function\s+([\w\$]+)\s*\(/.exec(realFunc.toString());
        return result ? result[1] : '';
    };

    sm.utils.showMessage = function(opts) {
        if (_.isObject(opts) === false) {
            opts = {
                message: opts
            };
        }
        if (opts.textWasCopied === true) {
            opts.callback = {
                afterShow() {
                    $('.noty_text').on('click.notyAfterShow', function(e) {
                        e.stopPropagation();
                    });
                },
                afterClose() {
                    $('.noty_text').off('click.notyAfterShow');
                }
            };
        }
        return noty(
            _.defaults({}, opts, {
                text: opts.message,
                type: 'alert',
                dismissQueue: true,
                timeout: 5000,
                closeWith: ['button', 'click'],
                layout: 'topRight',
                buttons: false
            })
        );
    };

    sm.utils.showSuccess = function(opts) {
        if (_.isObject(opts) === false) {
            opts = {
                message: opts
            };
        }
        opts.type = 'success';
        sm.utils.showMessage(opts);
    };

    sm.utils.showError = function(opts) {
        if (_.isObject(opts) === false) {
            opts = {
                message: opts
            };
        }
        opts.type = 'error';
        _.extend(opts, {
            timeout: 15000
        });
        sm.utils.showMessage(opts);
    };

    sm.utils.showWarning = function(opts) {
        if (_.isObject(opts) === false) {
            opts = {
                message: opts
            };
        }
        opts.type = 'warning';
        _.extend(opts, {
            timeout: 10000
        });
        sm.utils.showMessage(opts);
    };

    sm.utils.fancyboxify = function(options) {
        var defaults = {
            width: 600,
            maxWidth: 1280,
            padding: [30, 30, 30, 30],
            fitToView: false,
            autoSize: false
        };
        (options.selector ? (_.isObject(options.selector) ? options.selector : $(options.selector)) : $).fancybox(
            options.options ? _.merge(defaults, options.options) : defaults
        );
    };

    sm.utils.showPopup = function(opts) {
        if (!document.getElementById(opts.id) && !opts.template) {
            throw 'Укажите id шаблона или сам шаблон';
        }
        opts.href = `#${opts.id}`;
        var template = opts.template ? opts.template : $(opts.href).html();
        if (opts.data != null) {
            opts.content = _.template(template)(opts.data);
        }
        if (opts.template != null && opts.content == null) {
            opts.content = template;
        }
        sm.utils.fancyboxify({options: opts});
        return $('.fancybox-inner');
    };

    sm.utils.refreshPopup = function() {
        $.fancybox.update();
    };

    sm.utils.hidePopup = function() {
        $.fancybox.close();
    };

    sm.utils.isAjaxSuccess = function(status) {
        return (status >= 200 && status < 300) || status === 304;
    };

    sm.constants = sm.constants || {};
    _.merge(sm.constants, require(`../config/customers/${sm.customer}/constants.json5`));
    sm.constants.switcheryDefaultColor = '#EF5350';
    sm.constants.debounceWidgetTimeout = 20;
    sm.constants.onTypingTimeout = 500;
    sm.constants.dateFullFormat = 'DD.MM.YY HH:mm:ss';
    sm.constants.dateFormat = 'DD.MM.YYYY';
    sm.constants.formatPatternjQueryUI = 'dd.mm.y';

    sm.constants.rsmIcons = RSM_SERVICE_ICONS;

    sm.utils.showConfigItemLevelRsm = function(data) {
        if (!data.id) {
            return data.text;
        }
        var hasIcon = !!_.get(data, 'configItemType.icon.name');
        return `<span class="ci-name ci-name_triggers">${
            hasIcon ? `<i class="material-icons config-item-type-icon">${data.configItemType.icon.name}</i>` : ''
        }<span class="config-item-text">${data.name}</span></span>`;
    };

    sm.utils.defaultToSelected = function({
        datatableSrc,
        serviceDst,
        pluralObj,
        $preloader = {
            addClass: $.noop,
            removeClass: $.noop
        }
    }) {
        return function() {
            var data = datatableSrc.getSelected().data();
            if (!data || !data.length) {
                sm.utils.showWarning(`Выберите ${pluralObj} для добавления`);
                return;
            }
            $preloader.removeClass('hidden');
            return serviceDst
                .createMultiple(data)
                .done(function() {
                    datatableSrc.datatable.rows({selected: true}).deselect();
                    datatableSrc.reload();
                })
                .fail(function() {
                    sm.utils.showError(__('common.alerts.state-save-failed'));
                })
                .always(function() {
                    $preloader.addClass('hidden');
                });
        };
    };

    sm.utils.defaultFromSelected = function({
        datatableSrc,
        datatableDst,
        serviceSrc,
        pluralObj,
        $preloader = {
            addClass: $.noop,
            removeClass: $.noop
        }
    }) {
        return function() {
            var data = datatableSrc.getSelected().data();
            if (!data || !data.length) {
                sm.utils.showWarning(`Выберите ${pluralObj} для удаления`);
                return;
            }
            $preloader.removeClass('hidden');
            // удаление данных из локального сервиса для таблицы автоматом синхронно перерисует таблицу и deselect не сработает
            // (а нужен, чтобы в случае повторного добавления, запись не была чекнута автоматом)
            if (!datatableSrc.serverSide) {
                datatableSrc.datatable.rows({selected: true}).deselect();
            }
            return serviceSrc
                .deleteMultiple(data)
                .done(function() {
                    if (datatableSrc.serverSide) {
                        datatableSrc.datatable.rows({selected: true}).deselect();
                    }
                    datatableDst.reload();
                })
                .fail(function() {
                    sm.utils.showError(__('common.alerts.state-save-failed'));
                })
                .always(function() {
                    $preloader.addClass('hidden');
                });
        };
    };

    // @see https://stackoverflow.com/a/13542669
    function shadeColor2(color, percent) {
        var f = parseInt(color.slice(1), 16),
            t = percent < 0 ? 0 : 255,
            p = percent < 0 ? percent * -1 : percent,
            R = f >> 16,
            G = (f >> 8) & 0x00ff,
            B = f & 0x0000ff;
        return `#${(
            0x1000000 +
            (Math.round((t - R) * p) + R) * 0x10000 +
            (Math.round((t - G) * p) + G) * 0x100 +
            (Math.round((t - B) * p) + B)
        )
            .toString(16)
            .slice(1)}`;
    }

    sm.utils.colors = {
        hexRgba: require('hex-rgba'),
        lighten(hex, percent) {
            return shadeColor2(hex, percent / 100);
        },
        darken(hex, percent) {
            return shadeColor2(hex, -percent / 100);
        }
    };

    // Default Error Handler
    sm.deh = function() {
        console.warn('deh:');
        console.warn(arguments);
    };

    sm.utils.isDetachedNode = function(node) {
        if (node.parentNode === document) {
            return false;
        } else if (node.parentNode === null) {
            return true;
        }
        return sm.utils.isDetachedNode(node.parentNode);
    };

    // @see https://stackoverflow.com/questions/31798816/simple-mutationobserver-version-of-domnoderemovedfromdocument
    sm.utils.onDestroyNode = function(node, parentNode, callback) {
        if (callback == null) {
            callback = parentNode;
            parentNode = document;
        }
        var observer = new MutationObserver(function() {
            if (sm.utils.isDetachedNode(node)) {
                callback();
                observer.disconnect();
                observer = undefined;
            }
        });
        observer.observe(parentNode, {
            childList: true,
            subtree: true
        });
        return observer;
    };

    // @see https://www.codeproject.com/tips/893254/javascript-triggering-event-manually-in-internet-e
    sm.utils.dispatchMouseEvent = function(node, type) {
        var event;
        if (document.createEvent) {
            event = document.createEvent('MouseEvent');
            event.initMouseEvent(type, true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        } else {
            event = new MouseEvent(type, {
                view: window,
                bubbles: true,
                cancelable: true
            });
        }
        node.dispatchEvent(event);
    };

    sm.utils.parentOrSelfIs = function(node, selectorStr) {
        return !!($(node).is(selectorStr) || $(node).closest(selectorStr).length);
    };

    sm.utils.childOrSelfIs = function(node, selectorStr) {
        return !!($(node).is(selectorStr) || $(node).find(selectorStr).length);
    };

    sm.utils.isObjectContainsPrimitive = function(obj, primitive) {
        /* eslint-disable eqeqeq */
        return _.some(_.keys(obj), function(key) {
            return (
                ((_.isString(obj[key]) || _.isNumber(obj[key])) && obj[key] == primitive) ||
                new RegExp(sm.utils.escapeRegexp(primitive), 'i').test(obj[key])
            );
        });
        /* eslint-enable eqeqeq */
    };

    // источник - jquery.fancytree
    sm.utils.escapeRegexp = function(str) {
        return `${str}`.replace(/([.?*+\^\$\[\]\\(){}|-])/g, '\\$1');
    };

    var delayed = (callback) => _.delay(callback, 400);
    sm.utils.scrollTo = function({$scrollArea, $scrollTarget, $alertedElem, callback}) {
        if (!$alertedElem) {
            $alertedElem = $scrollTarget;
        }
        var attentionCallback = () => {
            _.isFunction(callback) && callback();
            $alertedElem.addClass('scroll-attention-setup');
            $alertedElem.addClass('scroll-attention');
            delayed(() => {
                $alertedElem.removeClass('scroll-attention');
                delayed(() => {
                    $alertedElem.removeClass('scroll-attention-setup');
                });
            });
        };

        $scrollArea.scrollTo($scrollTarget, {
            offset: {top: -20},
            onAfter() {
                requestAnimationFrame(attentionCallback);
            }
        });
    };

    function formatArrayResult(data) {
        var result = [];
        _.forEach(data, (value, key) => {
            result.push(value);
        });
        return result;
    }

    sm.utils.transformCamelCase = function(obj) {
        var result = {};
        var resultArrayQueue = [];
        _.forEach(obj, (value, key) => {
            if (_.isPlainObject(value) || _.isArray(value)) {
                if (_.isArray(value)) {
                    resultArrayQueue.push(key);
                }
                value = sm.utils.transformCamelCase(value);
            }
            if (_.includes(resultArrayQueue, key)) {
                var formattedResult = formatArrayResult(value);
                result[_.camelCase(key)] = formattedResult;
            } else {
                result[_.camelCase(key)] = value;
            }
        });
        return result;
    };

    sm.utils.getExportDateTimeStamp = function() {
        return moment().format('DD.MM.YY_HH-mm');
    };

    sm.utils.setCookie = function(params) {
        let name = params.name,
            value = params.value,
            expireDays = params.days,
            expireHours = params.hours,
            expireMinutes = params.minutes,
            expireSeconds = params.seconds;

        let expireDate = new Date();
        if (expireDays) {
            expireDate.setDate(expireDate.getDate() + expireDays);
        }
        if (expireHours) {
            expireDate.setHours(expireDate.getHours() + expireHours);
        }
        if (expireMinutes) {
            expireDate.setMinutes(expireDate.getMinutes() + expireMinutes);
        }
        if (expireSeconds) {
            expireDate.setSeconds(expireDate.getSeconds() + expireSeconds);
        }

        document.cookie = `${name}=${escape(value)};domain=${
            window.location.hostname
        };path=/;expires=${+expireDate.toUTCString()}`;
    };

    sm.utils.deleteCookie = function(name) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    };

    sm.constants.actionTypeIcons = {
        notification: 'notifications_active',
        regIncident: 'assignment_late',
        closeIncident: 'assignment_late',
        autoinformer: 'font_download',
        externalScript: 'code'
    };

    // https://www.regextester.com/94502
    sm.utils.isURL = function(url) {
        const simpleUrlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i;
        const match = url.match(simpleUrlRegex);
        return match ? true : false;
    };

    // @NOTE: переместить каретку (курсор ввода) в у казанных элемент на позицию caretPos
    sm.utils.setCaretPosition = (elem, caretPos) => {
        const range = document.createRange();
        const sel = window.getSelection();
        const node = elem.childNodes.length ? elem.childNodes[0] : elem;
        range.setStart(node, caretPos);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
    };

    // @NOTE: позиция каретки (курсор ввода) в указанном элементе
    sm.utils.getCaretCharOffset = (element) => {
        let caretOffset = 0;

        if (window.getSelection) {
            const range = window.getSelection().getRangeAt(0);
            const preCaretRange = range.cloneRange();
            preCaretRange.selectNodeContents(element);
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            caretOffset = preCaretRange.toString().length;
        } else if (document.selection && document.selection.type != 'Control') {
            const textRange = document.selection.createRange();
            const preCaretTextRange = document.body.createTextRange();
            preCaretTextRange.moveToElementText(element);
            preCaretTextRange.setEndPoint('EndToEnd', textRange);
            caretOffset = preCaretTextRange.text.length;
        }

        return caretOffset;
    };

    sm.utils.getSelectedNode = () => {
        var sel, range;
        if (window.getSelection) {
            sel = window.getSelection();
            if (sel.rangeCount) {
                range = sel.getRangeAt(0);
                return range.commonAncestorContainer.parentNode;
            }
        } else if (document.selection && document.selection.createRange) {
            range = document.selection.createRange();
            return range.parentElement();
        }
        return null;
    };
})();
