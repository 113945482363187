<docs lang="md">
    Summernote wysiwyg редактор
    https://summernote.org/
    https://github.com/summernote/summernote

    Известные баги:
    https://github.com/summernote/summernote/issues/1759
    https://github.com/summernote/summernote/issues/1520

    Первый поправлен, второй (focus-loss) вешает некоторые браузеры, так что пока попробую оставить компонент без него.
</docs>

<template>
  <div
    :disabled="disabled"
    class="summernote w-summernote"
    v-html="value"
    />
</template>

<script>
require('src/js/lib/summernote/dist/summernote.js');

export default Vue.component('sm-summernote', {
    props: {
        value: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        config: {
            type: Object,
            default: () => ({})
        },
        withMacros: {
            type: Boolean,
            default: false
        }
    },
    data() {
        var vm = this;

        const MacrosButton = function(context) {
            const ui = $.summernote.ui;
            // create button
            const button = ui.button({
                className: 'sm-button sm-button_add-macros',
                contents: '&lt;---&gt;',
                contents: '<span class="macros-icon"/>',
                tooltip: __('rules-actions.message-construction-dialog.add-macro-button')
            });

            const renderedButton = button.render();

            renderedButton.on('mousedown', () => vm.$emit('addMacros', context));

            return renderedButton; // return button as jquery object
        };

        return {
            DEFAULT_CONFIG: {
                lang: 'ru-RU',
                disableDragAndDrop: true,
                callbacks: {
                    lang: 'ru-RU',
                    onChange(contents, $editable) {
                        vm._selfSet = true; // emit -> value changes by parent -> watch -> focus-loss
                        vm.$emit('input', contents);
                        vm.$nextTick(() => {
                            vm._selfSet = false;
                        });
                    },
                    onBlur(event) {
                        vm.$emit('blur', event);
                    },
                    onFocus(event) {
                        vm.$emit('focus', event);
                    },
                    onKeydown(event) {
                        vm.$emit('keydown', event);
                    },
                    onKeyup(event) {
                        vm.$emit('keyup', event);
                    },
                    onMousedown(event) {
                        vm.$emit('mousedown', event);
                    },
                    onMouseup(event) {
                        vm.$emit('mouseup', event);
                    }
                },
                toolbar: [
                    ['style', ['style']],
                    ['font', ['bold', 'underline', 'clear']],
                    ['fontname', ['fontname']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['table', ['table']],
                    ['insert', ['link', 'picture', 'video']],
                    ['view', ['fullscreen' /*, 'codeview'*/ /*, 'help'*/]]
                    // ...vm.withMacros ? [['customButtons', ['macrosButton']]] : []
                ].concat(vm.withMacros ? ['custom-buttons', ['macros-button']] : []),
                buttons: {
                    'macros-button': MacrosButton
                }
            }
        };
    },

    watch: {
        value(html) {
            if (!this.theSummernote) {
                return;
            }
            if (this._selfSet) {
                return;
            }
            this.theSummernote.summernote('code', html);
        },
        disabled(val) {
            if (!this.theSummernote) {
                return;
            }
            return this.theSummernote.summernote(val ? 'disable' : 'enable');
        }
    },

    mounted() {
        var vm = this;
        vm.theSummernote = $(this.$el).summernote(_.defaultsDeep({}, vm.DEFAULT_CONFIG, vm.config));

        // due to known focus-loss bug:
        // https://github.com/summernote/summernote/issues/1520
        vm.theSummernote.on('summernote.blur', function(we, e) {
            vm.theSummernote.summernote('saveRange');
        });
        vm.theSummernote.on('summernote.focus', function(we, e) {
            vm.theSummernote.summernote('restoreRange');
        });

        // due to known bug:
        // https://github.com/summernote/summernote/issues/1759
        vm.theSummernote.summernote('removeModule', 'autoLink');

        this.theSummernote.summernote(this.disabled ? 'disable' : 'enable');
    },

    destroyed() {
        // скорее всего, модалька успела закрыться => можно чистить память после "тяжелых" редакторов
        requestIdleCallback(() => {
            this.theSummernote.summernote('destroy');
        });
    },

    methods: {
        insert(html) {
            const element = $(html)[0];
            this.theSummernote.summernote('insertNode', element);
            return element;
        },
        focus() {
            this.theSummernote.summernote('focus');
        },
        isEmpty() {
            return this.value === '<p><br></p>' || !this.value;
        },
        setBackColor(backColor) {
            this.theSummernote.summernote('backColor', backColor);
        },
        setForeColor(foreColor) {
            this.theSummernote.summernote('foreColor', foreColor);
        }
    }
});
</script>

<style lang="less">
.summernote[disabled] + .note-editor {
    .note-toolbar {
        display: none;
    }
    .panel-body {
        background: #eee;
    }
}

.create-notification-form .note-toolbar {
    & > .note-btn-group.btn-group {
        margin-top: 0 !important;
        margin-right: 0 !important;
    }
}
</style>
