<template>
    <div
        ref="panel"
        :class="{'panel-collapsed': collapsed, 'is-loading': loading, 'sm-widget': hasContainerClass}"
        class="panel panel-default">
        <div
            v-if="!$slots['custom-heading']"
            class="panel-heading">
            <template v-if="title || $slots.title">
                <h6 class="panel-title">
                    <slot name="title">
                        {{ title }}
                    </slot>
                    <i class="material-icons">{{ icon }}</i>
                </h6>
                <i
                    v-show="loading === true"
                    class="icon-spinner spinner position-right preloader"/>
                <div class="heading-elements">
                    <ul class="icons-list">
                        <li v-if="collapsible">
                            <a
                                :class="{'rotate-180': collapsed}"
                                data-action="collapse"
                                @click.stop="collapsed = !collapsed"/>
                        </li>
                    </ul>
                </div>
                <sm-switchery
                    v-if="showRefreshButton"
                    :title="[checked ? 'Включить автообновление событий' : 'Выключить автообновление событий']"
                    v-model="refreshStop"
                    class="auto-update"
                    @input="refreshButtonChange">
                    <input type="checkbox">
                </sm-switchery>
            </template>
            <template v-else>
                <slot name="custom-title"/>
            </template>
        </div>
        <slot
            v-else
            name="custom-heading"/>
                <div
                    :class="[bodyClass, {'hidden': collapsed && hiddenOnStart}]"
                    class="panel-body">
            <slot/>
        </div>
    </div>
</template>

<script>
export default Vue.component('SmPanel', {
    props: {
        collapsible: {
            type: Boolean,
            default: true
        },
        initialCollapsed: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        title: {
            type: String
        },
        icon: {
            type: String
        },
        bodyClass: {
            type: String
        },
        hasContainerClass: {
            type: Boolean,
            default: true
        },
        showRefreshButton: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            refreshStop: true,
            hiddenOnStart: this.initialCollapsed || false,
            collapsed: this.initialCollapsed || false
        };
    },
    watch: {
        collapsed() {
            if (this.hiddenOnStart === true) {
                this.hiddenOnStart = false;
            }
            $(this.$el)
                .find(':first')
                .nextAll()
                .slideToggle(150, function() {
                    $(window).trigger('resize');
                });
            this.$emit('app-panelCollapsed', {
                isCollapsed: this.collapsed
            });
            $(window).trigger('resize');
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$slots['custom-title'] && !$(this.$el).find('.preloader').length) {
                var unwatch = this.$watch('loading', () => {
                    console.debug('для custom-title, как правило, нужен прелоадер');
                    unwatch();
                });
            }
        });
    },
    methods: {
        refreshButtonChange(value) {
            this.$emit('refreshButtonChange', value);
        }
    }
});
</script>

<style lang="less">
.panel .panel-heading:empty {
    height: 0;
    border-bottom: none;
}

.auto-update {
    .switchery {
        background-color: #b0b5bd !important;
        border-color: #b0b5bd !important;

        small {
            box-shadow: 0 0 0 1px #fff, 0 0 0 2px #b0b5bd;

            &:before {
                position: absolute;
                content: '';
                top: 50%;
                left: 50%;
                width: 12px;
                height: 12px;
                transform: translate(-50%, -50%);
                mask-image: url('../../img/update.svg');
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: 12px 12px;
                background-color: #b0b5bd;
            }
        }
    }

    input:checked + .switchery {
        box-shadow: #2a75db 0px 0px 0px 8px inset !important;
        border-color: #205eb1 !important;

        small {
            box-shadow: 0 0 0 1px #fff, 0 0 0 2px #205eb1;

            &:before {
                background-color: #205eb1;
            }
        }
    }
}
</style>
