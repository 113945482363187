<template>
    <ul
        v-if="items"
        :id="getId()"
        :class="{
        'menu-dropdown--expand': menuExpanded === true,
        }"
        class="menu-dropdown aim-menu"
        @mouseenter="expandOnMouseEnter()"
        @mouseleave="collapseOnMouseLeave()"
        >
        <template v-for="(item, idx) in items">
        <li
            :key="idx"
            :id="getItemId(item)">
            <template>
            <SmSidebarItem
                v-if="item"
                :index="0"
                :menuItem="item"
                @onMouseClick="mouseMenuItemClick" />
            </template>
        </li>
        </template>
    </ul>
</template>

<script>
export default Vue.component('SmSidebarMenu', {
    components: {cSidebarItem: () => import('./sidebar-item.vue')},
    props: {
        items: {
            type: Array,
            default: undefined
        },
        parent: {
            type: Object,
            default: undefined
        },
        menuExpanded: {
            type: Boolean,
            default: false
        },
        expandOnMouseEvents: {
            type: Boolean,
            default: false
        }
    },
    setup() {},
    mounted() {
        this.$emit('onMenuInit');
    },
    methods: {
        getId() {
            return this.parent ? `sidebar-menu_${this.parent.name.replace('.', '_')}` : 'sidebar-menu_root';
        },

        getItemId(menuItem) {
            return `menu-item_${menuItem.name.replace('.', '_')}_block`;
        },

        focusItem(isFocus) {},

        expandOnMouseEnter() {},

        collapseOnMouseLeave() {},

        mouseMenuItemClick(menuItem) {
            this.$emit('onMouseItemClick', menuItem);
        },

        executeScript(menuItem) {
            this.$emit('onExecuteScript', menuItem);
        }
    }
});
</script>

<style lang="scss" scoped>
.menu-dropdown {
    position: fixed;
    z-index: 2;
    top: 0;
    left: calc(var(--submenu-width) * -1);
    width: var(--submenu-width);
    min-height: var(--submenu-min-height);
    margin: 0;
    // Костыль
    margin-left: -10px;
    padding: var(--submenu-left-offset);
    // Костыль
    padding-left: calc(4px + 10px);

    &:before {
        position: absolute;
        top: 50%;
        left: calc(var(--menu-size) * 0.25 * -1);
        width: 0;
        height: calc(100% - 32px);
        content: '';
        cursor: pointer;
        transition-delay: 1s;
        transition-property: width;
        transform: translateY(-50%);
        clip-path: polygon(100% 0, 0 calc(100% - 56px), 100% 100%);
    }

    .menu__item--opened & {
        left: var(--menu-size);
    }

    li {
        list-style: none;

        &:first-child {
            /deep/ {
                .menu__link {
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                }
            }

            & + li {
                /deep/ {
                    .menu__link {
                        padding-top: 12px;
                    }
                }
            }
        }

        &:last-child {
            /deep/ {
                .menu__link {
                    padding-bottom: 12px;
                    border-bottom-right-radius: 8px;
                    border-bottom-left-radius: 8px;
                }
            }
        }
    }

    /deep/ {
        .menu__link:not(.menu__link--label) {
            height: auto;
            max-height: none;
            padding: 4px 16px;

            .menu__info {
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: flex-start;
                width: 100%;
                padding-top: 16px;
                padding-right: 4px;
                padding-bottom: 16px;
                padding-left: 8px;
                border-radius: 6px;
                background-color: #3e3e3e;
            }

            &:hover,
            &:focus {
                .menu__info {
                    background-color: #222222;
                }

                .menu__icon {
                    filter: brightness(1);
                }
            }
            &:focus {
                box-shadow: none !important;

                .menu__info {
                    box-shadow: 0 0 0 3px #95baed inset;
                }
            }

            &.active {
                &:before {
                    content: none;
                }

                .menu__info {
                    border: 1px solid #4793e9;
                    border: 1px solid #ffffff;
                    background-color: #4f5051;
                }

                .menu__title {
                    color: #d4e6fd;
                }
            }

            .menu__icon {
                width: var(--submenu-icon-size);
                height: var(--submenu-icon-size);
                filter: brightness(0.95);
            }

            .menu__text {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: flex-start;
                padding-left: 4px;
                text-align: left;
            }

            .menu__title {
                font-size: 14px;
            }

            .menu__description {
                display: inline-block;
                width: 100%;
                padding-top: 4px;
                color: rgba(255, 255, 255, 0.8);
                font-size: 11px;
                font-weight: 300;
                line-height: 13px;
            }
        }
    }
}

/deep/ {
    .menu__link--label {
        display: flex;
        flex-direction: row;
        width: 100%;

        .menu__text {
            width: 100%;
            padding-top: 16px;
            padding-right: 20px;
            padding-bottom: 4px;
            padding-left: 20px;
            color: #ffffff;
            font-size: 16px;
            font-weight: 500;
            line-height: 1;
        }

        .menu__description {
            display: none;
        }

        .menu__icon {
            display: none;
        }
    }
}
</style>
