var showdown = require('showdown');

showdown.extension('mailLink', function() {
    const mailRgx = /(^|\s)(?:mailto:)?([A-Za-z0-9!#$%&'*+-/=?^_`{|}~.]+@[-a-z0-9]+(\.[-a-z0-9]+)*\.[a-z]+)(?=$|\s)/gim;
    // const mailRgx = /asd/gim;
    return [
        {
            type: 'html',
            filter(text) {
                return `${text}`.replace(mailRgx, '$1<a href="mailto:$2" target="_blank">$2</a>');
            }
        }
    ];
});

showdown.extension('urlLink', function() {
    const urlRgx = /([_*~]*?)(onenote:)?(((?:https?|ftp):\/\/|www\.)[^\s<>"'`´.-][^\s<>"'`´]*?\.[a-z\d.]+[^\s<>"']*)\1/gi;
    return [
        {
            type: 'html',
            filter(text) {
                return `${text}`.replace(urlRgx, (match, p1, onenote, p3) => {
                    // если нашли onenote: в начале - не пределываем match в ссылку
                    return onenote ? match : `<a href="${p3}" target="_blank">${p3}</a>`;
                });
            }
        }
    ];
});

// @EXAMPLE: onenote:///C:\Users\user\Downloads\Разные%20доки\example\example\Новый%20раздел%201.one#Алерт%20для%20первого%20теста§ion-id={A53E8F65-A53E8F65}&FF
showdown.extension('oneNoteLink', function() {
    const uriRgx = /((onenote):(\S*))/g;
    return [
        {
            type: 'html',
            filter(text) {
                return `${text}`.replace(uriRgx, '<a href="$1" target="_blank">$1</a>');
            }
        }
    ];
});

var converter = new showdown.Converter({
    simplifiedAutoLink: false,
    extensions: ['mailLink', 'oneNoteLink', 'urlLink']
});

Vue.component('sm-markdown', {
    props: {
        markdown: {
            type: String,
            default: null
        }
    },
    computed: {
        compiledMarkdown() {
            return converter.makeHtml(this.markdown);
        }
    },
    template: '<div v-html="compiledMarkdown"></div>'
});
