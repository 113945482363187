export class UserProfileHttpService extends sm.Service {
    constructor() {
        super({
            singleton: true
        });
    }

    makeUrl() {
        return '';
    }

    async getCurrentProfile(includeThumb = true) {
        this.url = `/api/webui/pl/v1/profile?perPage=-1&includeThumb=${includeThumb}`;
        return await this.fetch();
    }
}
