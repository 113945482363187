var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.typeCls
    ? _c(
        "div",
        {
          staticClass: "datatable-header__control",
          class: ["datatable-header__control--" + _vm.typeCls]
        },
        [
          !_vm.$slots["custom-content"]
            ? _c(
                "button",
                { staticClass: "btn btn-default", attrs: { type: "button" } },
                [
                  _c(
                    "span",
                    [
                      !_vm.$slots.default
                        ? [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  (_vm._.isString(_vm.type)
                                    ? _vm.types[_vm.type]
                                    : _vm.type
                                  ).title
                                ) +
                                "\n            "
                            )
                          ]
                        : [_vm._t("default")]
                    ],
                    2
                  )
                ]
              )
            : _vm._t("custom-content")
        ],
        2
      )
    : _vm.type === "search"
      ? _c("div", { staticClass: "dataTables_filter" }, [
          _c("div", { staticClass: "input-group" }, [
            _c("div", { staticClass: "has-feedback has-feedback-left" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("input", {
                ref: "searchInput",
                attrs: {
                  placeholder: !_vm.$slots.default
                    ? _vm.__("old-main.events-of-the-period.search-placeholder")
                    : _vm.$slots.default[0].text,
                  type: "text"
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group-btn" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default clear",
                  attrs: {
                    title: _vm.__(
                      "workgroups.notifications.creation-dialog.clear-button"
                    )
                  },
                  on: { click: _vm.clearSearch }
                },
                [_c("i", { staticClass: "icon-eraser" })]
              )
            ])
          ])
        ])
      : _vm.isStandardType
        ? _c("portal-target", { attrs: { name: _vm.uid } })
        : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-control-feedback" }, [
      _c("i", { staticClass: "icon-search4 text-muted text-size-base" })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7054f204", { render: render, staticRenderFns: staticRenderFns })
  }
}