<template>
    <div v-show="isActive" :class="{disabled: disabled}">
        <slot/>
        <portal v-if="$slots.title" :to="tabIndex">
            <slot name="title"/>
        </portal>
    </div>
</template>

<script>
export default Vue.component('SmTab', {
    props: {
        title: {
            type: String
        },
        order: {
            type: Number
        }
    },
    data() {
        return {
            tabIndex: _.uniqueId('tab-'),
            renderedContent: false,
            disabled: false
        };
    },
    computed: {
        isActive() {
            return this.$parent.activeTabIndex === this.tabIndex;
        }
    },
    watch: {
        isActive: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.$emit('active', this.title);
                }
            }
        }
    },
    created() {
        this.$parent.tabs.push(this);
    },
    beforeDestroy() {
        this.$parent.tabs.splice(this.index, 1);
    }
});
</script>
