var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.links.length > 1,
          expression: "links.length > 1"
        }
      ],
      class: _vm.cls
    },
    [
      _c(
        "ul",
        { staticClass: "nav nav-tabs" },
        _vm._l(_vm.links, function(link) {
          return _c("li", [
            _c("a", { attrs: { href: "#" + link.href } }, [
              _vm._v(_vm._s(link.title))
            ])
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-59c2455a", { render: render, staticRenderFns: staticRenderFns })
  }
}