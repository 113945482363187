<template>
    <div :class="{dropdown: !externalParent}" class="dropdown-container">
        <button v-if="title || $slots.title" class="btn btn-default" data-toggle="dropdown">
            <template>
            <span>
                <slot v-if="$slots.title" name="title"/>
                <template v-else>{{ title }}</template>
            </span>
            &nbsp;
            <span class="caret"/>
            </template>
        </button>
        <template v-else>
            <slot name="custom-title"/>
        </template>
        <slot/>
    </div>
</template>

<script>
export default Vue.component('SmDropdown', {
    props: {
        title: {
            type: String
        },
        externalParent: {
            type: Boolean,
            default: false
        },
        orientation: {
            type: String
        }
    },
    mounted() {
        // @see https://github.com/vuejs/vue/issues/8132
        if (!this.$slots.default.length || _.find(this.$slots.default, 'tag').tag !== 'ul') {
            var error = 'нужен список';
            console.error(error, this.$el);
            throw error;
        } else {
            this.$refs.ul = $(this.$el).find('ul')[0];
            $(this.$refs.ul).addClass('dropdown-menu');
        }
    }
});
</script>
<style lang="less">
.dropdown-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    height: 100%;
}
</style>
