export class UserService extends sm.NoCacheService {
    constructor() {
        super(...arguments);
        this.Model = User;
    }

    makeUrl() {
        return '/api/webui/pl/v1/users';
    }

    create(data) {
        data.name = data.fullName;
        return super.create(data);
    }

    update(source) {
        source.name = source.fullName;
        return super.update(source);
    }

    createPhoto(model, photo) {
        var url = model.getPhotoUrl();
        return $
            .ajax({
                url,
                type: 'POST',
                data: photo,
                cache: false,
                contentType: false,
                processData: false
            })
            .done(function() {
                // т.к. фото пользователя независимо от контента имеет фиксированное название, "приходится моргать"
                model.hasPhoto = false;
                model.hasPhoto = true;
            })
            .fail(function() {
                console.warn('фото пользователя не добавлено', arguments);
            });
    }

    deletePhoto(model) {
        return $
            .ajax({
                url: model.getPhotoUrl(),
                type: 'DELETE',
                data: {
                    id: model.id
                }
            })
            .done(function() {
                model.hasPhoto = false;
            })
            .fail(function() {
                console.warn('фото пользователя не удалено', arguments);
            });
    }
}

export class User extends sm.Model {
    constructor(data = {}) {
        super(...arguments);

        this.id = data.id;

        this.email = data.email || '';
        this.fullName = data.name || data.fullName || '';
        this.company = data.company || '';
        this.companyPosition = data.companyPosition || '';
        this.phone = data.phone || '';
        this.password = data.password || '';

        this.workgroups = data.workgroups || [];
        this.grants = data.grants || [];

        this.hasPhoto = !!data.photo;
        this.enabled = data.enabled || false;
        this.isPasswordRequired = false;
    }

    getPhotoUrl() {
        return `/api/webui/pl/v1/users/${this.id}/photo`;
    }
}
