<template>
  <div class="sm-network"/>
</template>

<script>
// import {Network} from 'vis';
import 'vis';
// require('vis');
import 'vis/dist/vis.css';

export default Vue.component('SmNetwork', {
    props: {
        // хак - чтобы перерисовать ноду в графе - я кладу ее айдишник в "массив для перерисовки"
        // граф мониторит этот массив, если видит там айдишники - перерисовывает эти ноды,
        // после чего говорит, что они перерисованы
        nodesToRedraw: {
            type: Array
        },
        nodes: {
            type: Array,
            required: true
        },
        edges: {
            type: Array,
            required: true
        },
        selectedNode: {
            type: Object
        },
        disableHighlight: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            DEFAULT_CONFIG: {
                edges: {
                    width: 0.15,
                    scaling: {
                        min: 1,
                        max: 5,
                        customScalingFunction(min, max, total, value) {
                            if (max === min) {
                                return 0;
                            }
                            var scale = 1 / (max - min);
                            return Math.max(0, (value - min) * scale);
                        }
                    },
                    color: {inherit: 'from'},
                    smooth: {
                        forceDirection: 'none'
                    }
                },
                physics: {
                    forceAtlas2Based: {
                        gravitationalConstant: -100
                    },
                    solver: 'forceAtlas2Based',
                    minVelocity: 1,
                    stabilization: {
                        updateInterval: 50,
                        iterations: 500
                    }
                },
                layout: {
                    improvedLayout: false,
                    randomSeed: 2
                }
            },
            //
            isReady: false,
            isClickedOnNetwork: false
        };
    },
    computed: {
        CONFIG() {
            return _.extend({}, this.DEFAULT_CONFIG);
        }
    },
    watch: {
        // @NOTE: в проверках на "одинаковость" происходит много мутаций данных, что может привести к проблемам с производительностью
        edges(newEdges, oldEdges) {
            // особенности первоначальной инициализации
            if (this.isReady) {
                // https://github.com/lodash/lodash/issues/2490
                let isEqual = _.isEqualWith(
                    newEdges,
                    oldEdges,
                    _.after(2, (a, b) => {
                        return _.isEqual(_.pick(a, ['from', 'to', 'type']), _.pick(b, ['from', 'to', 'type']));
                    })
                );
                if (isEqual) {
                    // ничего не поменялось (вотчер МОЖЕТ сработать, т.к. меняется reference)
                    // грани не удалялись и не добавлялись - можно просто обновить существующие (уже отрисованные)
                    let fixedNewEdges = _.map(newEdges, (edge) => {
                        let oldEdge = _.find(oldEdges, {
                            from: edge.from,
                            to: edge.to,
                            type: edge.type
                        });
                        return _.extend(edge, {id: oldEdge.id});
                    });
                    this.theNetwork.body.data.edges.getDataSet().update(fixedNewEdges);
                    return;
                }
            }
            // полноценная обновляшка графа
            this.setData();
        },
        nodes(newNodes, oldNodes) {
            // особенности первоначальной инициализации
            if (this.isReady) {
                if (_.isEqual(newNodes, oldNodes)) {
                    // ничего не поменялось (вотчер МОЖЕТ сработать, т.к. меняется reference)
                    return;
                }
                // https://github.com/lodash/lodash/issues/2490
                if (_.isEqualWith(newNodes, oldNodes, _.after(2, (a, b) => a.id === b.id))) {
                    // ноды не удалялись и не добавлялись - можно просто обновить существующие (уже отрисованные)
                    this.theNetwork.body.data.nodes.getDataSet().update(newNodes);
                    return;
                }
            }
            // полноценная обновляшка графа
            this.setData();
        },
        isReady(val) {
            this.$emit('readyStateChanged', val);
        },
        selectedNode(node) {
            if (!this.theNetwork) {
                return;
            }
            // сбрасываю выделение
            this.theNetwork.selectNodes([]);
            if (!this.isClickedOnNetwork && node) {
                this.theNetwork.focus(node.id, {
                    scale: 1,
                    offset: {x: 0, y: 0},
                    animation: {
                        duration: 300
                    }
                });
            }
            if (!this.disableHighlight) {
                this.neighbourhoodHighlight(node && {nodes: [node.id]});
            }
        },
        nodesToRedraw(ids) {
            let nodesToUpdate = _.map(ids, (id) => {
                return _.find(this.nodes, {id});
            });
            this.theNetwork.body.data.nodes.getDataSet().update(nodesToUpdate);
            this.$emit('nodesRedrawn', ids);
        }
    },
    mounted() {
        this.isReady = false;
        this.theNetwork = new vis.Network(
            this.$el,
            {
                nodes: this.nodes,
                edges: this.edges
            },
            this.CONFIG
        );
        // this.setData(this.nodes, this.edges);
        this.theNetwork.on('click', (params) => {
            let node = this.getNodeById(_.first(params.nodes));
            let edge = this.getEdgeById(_.first(params.edges));
            this.isClickedOnNetwork = true;
            this.$emit('nodeSelected', node);
            this.$emit('edgeSelected', edge);
            this.$nextTick(() => {
                this.isClickedOnNetwork = false;
            });
        });

        // visjs не триггерит события стабилизации при отсутствии нод,
        // поэтому мы наблюдаем пустой прелоадер.
        if (!this.nodes.length) {
            this.isReady = true;
        }

        this.theNetwork.on('beforeDrawing', function(ctx) {
            // save current translate/zoom
            ctx.save();
            // reset transform to identity
            ctx.setTransform(1, 0, 0, 1, 0, 0);
            // fill background with solid white
            ctx.fillStyle = '#ffffff';
            ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            // restore old transform
            ctx.restore();
        });

        this.theNetwork.on('afterDrawing', () => {
            this.$emit('afterDrawing', this.theNetwork);
        });

        this.theNetwork.on('select', (params) => {
            let selectedNodeId = _.first(params.nodes);
            this.$emit('');
        });

        this.theNetwork.on('selectNode', (params) => {
            this.$emit('selectNode', params);
        });
    },
    methods: {
        setData: _.debounce(function() {
            let vm = this;
            if (!this.theNetwork) {
                return;
            }
            this.isReady = false;
            let savedNodes = _.map(vm.theNetwork.getPositions(), (value, key) => {
                return _.extend({id: key}, value);
            });
            let savedPosition = null;
            if (savedNodes && savedNodes.length) {
                // есть сохраненные ноды, значит это - не первая отрисовка,
                // значит граф может быть "не фит", значит нужно сохранять позицию,
                savedPosition = {
                    scale: this.theNetwork.getScale(),
                    position: this.theNetwork.getViewPosition()
                };
            } else {
                // сохраненных (отрисованных) нод нет. Значит попробую взять состояние из ls
                // очевидно, в таком случае не нужно восстанавливать позицию
                savedNodes = JSON.parse(localStorage.getItem('rsmNetwork')) || [];
            }
            let resultNodes = _.map(this.nodes, (node) => {
                let savedNode = _.find(savedNodes, {id: String(node.id)});
                return _.extend({}, savedNode, node);
            });
            this.theNetwork.setData({nodes: resultNodes, edges: this.edges});
            this.theNetwork.on('afterDrawing', restorePosition);
            function restorePosition() {
                vm.theNetwork.off('afterDrawing', restorePosition);
                let dataset = vm.theNetwork.body.data.nodes.getDataSet();
                _.mapValues(dataset._data, (item) => {
                    delete item.x;
                    delete item.y;
                    return item;
                });
                if (savedPosition) {
                    vm.theNetwork.moveTo(savedPosition);
                }
                vm.isReady = true;
                requestIdleCallback(() => {
                    let nodes = _.map(vm.theNetwork.getPositions(), (value, key) => {
                        return _.extend({id: key}, value);
                    });
                    localStorage.setItem('rsmNetwork', JSON.stringify(nodes));
                    // console.log('saved in ls', nodes);
                });
            }
        }),
        //
        getNodeEdges(nodeId) {
            let allEdges = _.values(this.getAllEdges());
            return _.uniqBy(_.concat(_.filter(allEdges, {to: nodeId}), _.filter(allEdges, {from: nodeId})), 'id');
        },
        getNodesDataSet() {
            return this.theNetwork.body.data.nodes.getDataSet();
        },
        getAllNodes() {
            return this.getNodesDataSet()._data;
        },
        getNodeById(nodeId) {
            return this.getAllNodes()[nodeId];
        },
        getEdgesDataSet() {
            return this.theNetwork.body.data.edges.getDataSet();
        },
        getAllEdges() {
            return this.getEdgesDataSet()._data;
        },
        getEdgeById(id) {
            return this.getAllEdges()[id];
        },
        // заполняет edge нодами from/to вместо сухих айдишников
        fillEdge(edge) {
            return _.extend({}, edge, {
                from: this.getNodeById(edge.from),
                to: this.getNodeById(edge.to)
            });
        },

        // Подсветка соседей при клике на ноду
        neighbourhoodHighlight(params) {
            let vm = this;

            const DEFAULT_BORDER_WIDTH = 4;
            const DEFAULT_SIZE = 30;
            function updateNodeWeight(node, weight) {
                node = _.cloneDeep(node);
                if (node.borderWidth) {
                    node.borderWidth = DEFAULT_BORDER_WIDTH * weight;
                }
                node.size = DEFAULT_SIZE * weight;
                return node;
            }

            const DEFAULT_EDGE_VALUE = 1;
            let allEdges = this.getAllEdges();
            function updateNodeEdgesWeigth(node, weight) {
                let edges = vm.getNodeEdges(node.id);
                for (let i = 0; i < edges.length; i++) {
                    allEdges[edges[i].id].value = DEFAULT_EDGE_VALUE * weight;
                    // allEdges[edges[i].id].value = _.random(1, 100);
                    // allEdges[edges[i].id].color.color = '#ff00ff';
                }
            }

            let allNodes = vm.getAllNodes();
            let nodeId;
            // if something is selected:
            if (params && params.nodes.length > 0) {
                vm.highlightActive = true;
                let i, j;
                let selectedNodeId = params.nodes[0];
                let degrees = 2;

                // mark all nodes as hard to read.
                for (nodeId in allNodes) {
                    allNodes[nodeId] = updateNodeWeight(allNodes[nodeId], 1);
                }
                let connectedNodes = vm.theNetwork.getConnectedNodes(selectedNodeId);
                let allConnectedNodes = [];

                // get the second degree nodes
                for (i = 1; i < degrees; i++) {
                    for (j = 0; j < connectedNodes.length; j++) {
                        allConnectedNodes = allConnectedNodes.concat(
                            vm.theNetwork.getConnectedNodes(connectedNodes[j])
                        );
                    }
                }

                // all second degree nodes get a different color and their label back
                for (i = 0; i < allConnectedNodes.length; i++) {
                    allNodes[allConnectedNodes[i]] = updateNodeWeight(allNodes[allConnectedNodes[i]], 1.1);
                    updateNodeEdgesWeigth(allNodes[allConnectedNodes[i]], 1.1);
                }

                // all first degree nodes get their own color and their label back
                for (i = 0; i < connectedNodes.length; i++) {
                    allNodes[connectedNodes[i]] = updateNodeWeight(allNodes[connectedNodes[i]], 1.2);
                    updateNodeEdgesWeigth(allNodes[connectedNodes[i]], 1.2);
                }

                // the main node gets its own color and its label back.
                allNodes[selectedNodeId] = updateNodeWeight(allNodes[selectedNodeId], 1.6);
                updateNodeEdgesWeigth(allNodes[selectedNodeId], 1.6);
            } else if (vm.highlightActive === true) {
                // reset all nodes
                for (nodeId in allNodes) {
                    allNodes[nodeId] = updateNodeWeight(allNodes[nodeId], 1);
                    updateNodeEdgesWeigth(allNodes[nodeId], 1);
                }
                vm.highlightActive = false;
            }

            // transform the object into an array
            let updateArray = [];
            for (nodeId in allNodes) {
                if (allNodes.hasOwnProperty(nodeId)) {
                    updateArray.push(allNodes[nodeId]);
                }
            }
            vm.theNetwork.body.data.nodes.getDataSet().update(updateArray);
            // function updateEdgesForIds(ids, config) {
            //     var arr = [];
            //     for (var n in ids) {
            //         var obj = {};
            //         Object.assign(obj, config);
            //         obj.id = ids[n];
            //         arr.push(obj);
            //     }
            //
            //     vm.theNetwork.body.data.edges.update(arr);
            // }
            // updateEdgesForIds(_.map(allEdges, 'id'), {color: {color: '#00ff00'}});
            vm.theNetwork.body.data.edges.update(_.map(allEdges, (e) => ({id: e.id, value: e.value})));
            // _.each(allEdges, (edge) => {
            //     vm.theNetwork.clustering.updateEdge(edge.id, edge);
            // });
        }
    }
});
</script>

<style lang="less" scoped>
.sm-network {
    width: 100%;
    height: 100%;
    /deep/ .vis-tooltip {
        display: none;
    }
}
</style>
