export default class CiCachedService extends sm.Service {
    constructor() {
        super({
            singleton: true
        });
    }

    fetch() {
        this.loaded = false;
        return super.fetch();
    }

    makeUrl() {
        return '/api/webui/rsm/services?perPage=-1&includeStates=true';
    }

    _processData(data) {
        this.models.push(..._.map(this.parse(data), this.make));
        this.trigger(`${this.uuid}.afterFetch`, [this.models]);
    }

    onAfterFetch(callback, immediateIfLoaded) {
        this.on(`${this.uuid}.afterFetch`, callback);
        if (immediateIfLoaded && this.loaded) {
            callback.apply(this, [{}, this.models]);
        }
    }
}

sm.RsmEntityService = CiCachedService;
