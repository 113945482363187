<template>
    <Promised :promise="getResources" tag="div">
        <template slot="pending">
            <div class="show" :class="{content: comp.wrapContentWhenLoading}">
                <i class="icon-spinner spinner position-right preloader" v-show="!component || showPreloader"/>
            </div>
        </template>
        <template>
            <slot/>
        </template>
        <template slot="rejected" slot-scope="error">
            <div class="p-20" v-sm-mounted="errorHandler">
                При загрузке ресурсов произошла ошибка — для отображения попробуйте обновить страницу
            </div>
        </template>
    </Promised>
</template>

<script>
import {NotIdle} from 'idlejs';
export default Vue.component('SmPage', {
    props: {
        component: {
            type: Object
        },
        showPreloader: {
            type: Boolean
        }
    },
    data() {
        var comp = this.component || this.$parent;
        return {
            comp,
            getResources: comp.getResources().then(() => {
                _.isFunction(this.comp.init) && this.comp.init();
            })
        };
    },
    created() {
        this.detectUserActivity();
        if (this.comp.$options.mounted) {
            this.$options.render = $.noop;
            throw 'нельзя использовать хук mounted совместно с getResources';
        }
        if (!_.isFunction(this.comp.getResources)) {
            throw 'в компоненте должен быть определен метод getResources';
        }
    },
    methods: {
        detectUserActivity() {
            const idle = new NotIdle()
                .whenInteractive()
                .within(5)
                .do(() =>
                    $.ajax({
                        type: 'POST',
                        url: '/api/webui/pl/v1/profile/activity'
                    })
                )
                .start();
        },
        errorHandler() {
            _.get(this.comp, 'onFailGetResources', sm.Page.onDefaultFailGetResources)();
        }
    }
});
</script>

<style>
.resources-loaded {
    width: 100%;
    height: 100%;
}
</style>
