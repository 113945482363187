var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Promised",
    {
      attrs: { promise: _vm.getResources, tag: "div" },
      scopedSlots: _vm._u([
        {
          key: "rejected",
          fn: function(error) {
            return [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "sm-mounted",
                      rawName: "v-sm-mounted",
                      value: _vm.errorHandler,
                      expression: "errorHandler"
                    }
                  ],
                  staticClass: "p-20"
                },
                [
                  _vm._v(
                    "\n            При загрузке ресурсов произошла ошибка — для отображения попробуйте обновить страницу\n        "
                  )
                ]
              )
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "pending" }, [
        _c(
          "div",
          {
            staticClass: "show",
            class: { content: _vm.comp.wrapContentWhenLoading }
          },
          [
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.component || _vm.showPreloader,
                  expression: "!component || showPreloader"
                }
              ],
              staticClass: "icon-spinner spinner position-right preloader"
            })
          ]
        )
      ]),
      _vm._v(" "),
      [_vm._t("default")]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2144194c", { render: render, staticRenderFns: staticRenderFns })
  }
}