var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sm-rsm-select", class: { clearable: !_vm.noClearButton } },
    [
      _c("sm-select", {
        attrs: {
          data: _vm.data,
          value: !_vm.isPrimitive
            ? _vm.value
            : _vm._.find(_vm.data, function(el) {
                return el[_vm.dataId] === _vm.value
              }),
          name: "service-group",
          placeholder: _vm.__("workgroups.rsm.select-ci-combo"),
          multiple: false
        },
        on: {
          input: function($event) {
            return _vm.$emit(
              "input",
              !_vm.isPrimitive ? $event : $event[_vm.dataId]
            )
          }
        },
        scopedSlots: _vm._u([
          {
            key: "option",
            fn: function(props) {
              return [
                _c("sm-ci-icon", { attrs: { ci: props.option } }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n                " +
                      _vm._s(props.option.name) +
                      "\n            "
                  )
                ])
              ]
            }
          },
          {
            key: "singleLabel",
            fn: function(props) {
              return [
                _c("sm-ci-icon", { attrs: { ci: props.option } }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n                " +
                      _vm._s(props.option.name) +
                      "\n            "
                  )
                ])
              ]
            }
          },
          {
            key: "tag",
            fn: function(ref) {
              var option = ref.option
              var remove = ref.remove
              return [
                _c(
                  "span",
                  { staticClass: "multiselect__tag" },
                  [
                    _c("sm-ci-icon", { attrs: { ci: option } }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(option.name) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "multiselect__tag-icon",
                      on: {
                        click: function($event) {
                          return remove(option)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      !_vm.noClearButton
        ? _c(
            "span",
            {
              staticClass: "input-group-addon btn btn-default",
              attrs: {
                title: _vm.__(
                  "workgroups.notifications.creation-dialog.clear-button"
                )
              },
              on: {
                click: function($event) {
                  return _vm.$emit("input", null)
                }
              }
            },
            [_c("i", { staticClass: "icon-eraser" })]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-52d593ca", { render: render, staticRenderFns: staticRenderFns })
  }
}