<template>
    <div class="monq-sidebar-wrapper">
        <nav
            id="main-sidebar-menu"
            class="monq-sidebar">
            <a
                class="monq-sidebar__logo logo"
                href="/">
                <picture class="logo__icon">
                <img
                    alt="Логотип компании"
                    width="64"
                    height="64"
                    src="/api/webui/pl/v1/userspaces/current/logo?size=1"
                    srcset="/api/webui/pl/v1/userspaces/current/logo?size=2 2x, /api/webui/pl/v1/userspaces/current/logo?size=3 3x`">
                </picture>
            </a>
            <div class="monq-sidebar__menu">
                <perfect-scrollbar class="ps">
                    <div class="ps-content">
                        <ul class="menu-list menu-list--top">
                            <template v-for="(item, idx) in menuItemsTop">
                                <li :key="idx" class="aim-item">
                                    <SmSidebarItem
                                    :index="idx"
                                    :menuItem="item"
                                    @onMenuInit="childMenuInit()"
                                    @onMouseEnter="mouseItemEnter"
                                    />
                                </li>
                            </template>
                        </ul>
                        <ul class="menu-list menu-list--bottom">
                            <template v-for="(item, idx) in menuItemsBottom">
                                <li :key="idx" class="aim-item">
                                    <SmSidebarItem
                                    :index="idx + menuItemsTop.length"
                                    :menuItem="item"
                                    @onMenuInit="childMenuInit"
                                    @onMouseEnter="mouseItemEnter"
                                    />
                                </li>
                            </template>
                        </ul>
                    </div>
                </perfect-scrollbar>
            </div>
            <template>
                <div class="aim-item">
                    <div class="monq-sidebar__user">
                    <SmSidebarItem
                        v-if="menuItemProfile"
                        :index="menuItemsTop.length + menuItemsBottom.length"
                        :menuItem="menuItemProfile"
                        @onMenuInit="childMenuInit"
                        @onMouseEnter="mouseItemEnter"
                        />
                    </div>
                </div>
            </template>
        </nav>
    </div>
</template>
<script>
import _ from 'lodash';
import {MainMenuService} from 'src/js/services/mainMenuService.js';
import {PerfectScrollbar} from 'vue2-perfect-scrollbar';
export default Vue.component('SmAppSidebar', {
    components: {
        PerfectScrollbar,
        filterWrapper: require('./sidebar-item.vue').default
    },
    props: {
        // @EXAMPLE: "https://smon.monq.ru/index.html"
        homeLink: {
            type: String,
            default: null
        },
        expanded: {
            type: Boolean,
            default: false
        },
        expandDisabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            menuTitle: '',
            menuItems: [],
            menuItemsTop: [],
            menuItemsBottom: [],
            menuItemProfile: undefined,
            menuItemMouseHover: undefined,
            profile: undefined,
            mainMenuService: undefined,
            usesScripts: new Map()
        };
    },
    mounted() {
        this.mainMenuService = new MainMenuService();
        this.mainMenuService.getMenu().then(([menuTitle, menuItems, profile]) => {
            this.menuTitle = menuTitle;
            this.menuItems = menuItems;
            this.profile = profile;

            const menuItemScripts = menuItems.filter((item) => item.type === 'Script');
            for (const item of menuItemScripts) {
                this.addScript(item);
            }

            this.menuItemsTop = this.sortMenuItems(this.menuItems.filter((item) => item.position === 'Top'));
            this.menuItemsBottom = this.sortMenuItems(this.menuItems.filter((item) => item.position === 'Bottom'));
            this.menuItemProfile = this.menuItems.find((item) => item.name === 'side-menu.profile');
        });
    },
    methods: {
        mouseItemEnter(menuItem) {
            this.menuItemMouseHover = menuItem;
        },

        mouseItemLeave() {
            this.menuItemMouseHover = undefined;
        },

        sortMenuItems(menuItems) {
            const sortItems = menuItems.sort((a, b) => a.order - b.order);

            for (const item of sortItems) {
                if (item.items) item.items = this.sortMenuItems(item.items);
            }
            return sortItems;
        },

        childMenuInit() {
            if (!this.menuItemMouseHover) return;

            const itemHoverName = this.menuItemMouseHover.name.replace('.', '_');
            const itemHtml = document.getElementById(`menu-item_${itemHoverName}`);
            const menuHtml = document.getElementById(`sidebar-menu_${itemHoverName}`);

            if (!itemHtml || !menuHtml) return;
            const minTopPx = 0;
            const maxBottomPx = window.innerHeight;
            const activeItemRect = itemHtml.getBoundingClientRect();
            const menuRect = menuHtml.getBoundingClientRect();
            const activeItemTopCenter = activeItemRect.top + activeItemRect.height / 2;

            let menuTop = activeItemTopCenter - menuRect.height / 2;
            if (this.menuItemMouseHover.items && this.menuItemMouseHover.items.length > 1) {
                const itemLabel = this.menuItemMouseHover.items[0];
                const itemLabelHtml = document.getElementById(`menu-item_${itemLabel.name.replace('.', '_')}`);
                if (itemLabelHtml) {
                    const itemLabelHtmlText = itemLabelHtml.getElementsByClassName('menu__text')[0];
                    menuTop -= itemLabelHtml ? itemLabelHtml.getBoundingClientRect().height : 0;

                    if (itemLabelHtmlText) {
                        const styles = window.getComputedStyle(itemLabelHtmlText);
                        menuTop += parseFloat(styles.paddingTop) * 2;
                    }
                }
            }

            if (menuTop < minTopPx) menuTop = minTopPx;
            if (menuTop + menuRect.height > maxBottomPx) menuTop = maxBottomPx - menuRect.height;
            menuHtml.style.marginTop = `${menuTop}px`;
        },

        addScript(menuItem) {
            if (!menuItem || menuItem.type !== 'Script' || !menuItem.handler) {
                return;
            }

            const handlerObj = menuItem.handler;
            if (!handlerObj || !handlerObj.src) {
                return;
            }

            const script = document.createElement('script');
            script.async = true;
            script.src = handlerObj.src;
            const head = document.getElementsByTagName('script')[0];
            if (head && head.parentNode) head.parentNode.insertBefore(script, head);
        }
    }
});
</script>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css">
</style>

<style lang="scss" scoped>
.monq-sidebar-wrapper {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: var(--menu-size);
    height: 100vh;
    background-color: var(--menu-background);
}

.monq-sidebar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.monq-sidebar__menu {
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 0;
    max-height: 100%;

    /deep/ {
        .ps {
            // position: static;
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            width: 100%;
            max-width: 100%;
            height: 100%;
            min-height: 0;
            max-height: 100%;

            .ps__rail-y {
                width: 10px;
            }

            &.ps--active-y {
                &:before,
                &:after {
                    position: fixed;
                    z-index: 100;
                    top: var(--menu-item-height);
                    left: 0;
                    width: var(--menu-size);
                    height: 4px;
                    content: '';
                    pointer-events: none;
                    // TODO: Переписать цвета на rgba от черного
                    background: linear-gradient(180deg, #282828 0%, rgba(62, 62, 62, 0) 100%);
                }

                &:after {
                    top: unset;
                    bottom: var(--menu-item-height);
                    background: linear-gradient(360deg, #282828 0%, rgba(62, 62, 62, 0) 100%);
                }
            }

            // стилизация скроллбара
            &.ps--scrolling-y > .ps__rail-y {
                width: 10px;
                background-color: transparent;
            }

            .ps__rail-x,
            .ps__rail-y {
                &.ps--clicking,
                &:hover,
                &:focus {
                    background-color: transparent;
                }
            }

            .ps__thumb-y {
                width: 4px;
            }

            .ps__rail-y:hover > .ps__thumb-y,
            .ps__rail-y:focus > .ps__thumb-y,
            .ps__rail-y.ps--clicking .ps__thumb-y {
                width: 6px;
            }
        }
    }

    .ps-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-shrink: 0;
        justify-content: space-between;
        height: 100%;
        min-height: 0;
    }
}

.logo {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    width: var(--menu-size);
    height: var(--menu-size);
    outline: none;
    background-image: none;

    &:hover,
    &:focus {
        background-image: none;

        img {
            filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.25));
        }

        .logo__title {
            left: 100%;
        }
    }

    &:focus {
        box-shadow: 0 0 0 3px #95baed inset;
    }
}

.logo__icon {
    width: 40px;
    height: 40px;

    img {
        width: 100%;
        height: 100%;
        transition: filter 0.3s ease;
    }
}

.logo__title {
    position: absolute;
    top: 50%;
    left: calc(var(--submenu-width) * -1);
    width: var(--submenu-width);
    min-height: var(--submenu-min-height);
    max-height: var(--menu-item-height);
    margin-left: var(--submenu-left-offset);
    padding-top: 8px;
    padding-right: 24px;
    padding-bottom: 8px;
    padding-left: 24px;
    transform: translateY(-50%);
    color: #ffffff;
    border-radius: var(--submenu-radius);
    background-color: var(--submenu-background);
    box-shadow: var(--submenu-shadow);
    font-size: 16px;
    font-weight: 500;
}

.menu-list {
    width: 100%;
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

.menu-list--top {
    /deep/ {
        .menu-dropdown {
            &:before {
                clip-path: polygon(100% 0, 0 50%, 100% 100%);
            }
        }
    }
}

.monq-sidebar__user {
    background-color: #474747;

    /deep/ {
        & > .menu__item > .menu__link {
            height: var(--menu-item-height);
            padding-top: 12px;
            padding-bottom: 12px;

            .menu__icon {
                --main-menu-icon-size: --user-avatar-icon-size;

                overflow: hidden;
                border-radius: 50%;
                background-color: #242424;
                filter: none;
            }
        }

        .menu__link--label {
            padding-top: 16px;
            padding-bottom: 8px;

            .menu__info {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                width: 100%;
            }

            .menu__text {
                padding-top: 8px;
                text-align: center;
            }

            .menu__icon {
                display: inline-flex;
                overflow: hidden;
                flex-grow: 0;
                flex-shrink: 0;
                // width: 64px;
                // height: 64px;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background-color: #242424;
                filter: none;
            }
        }
    }
}
</style>
