import i18next from 'i18next';

(function($) {
    window.sm = window.sm || {};

    function Language() {
        var data = sm.localeMetaData;
        return {
            get() {
                return data.lang;
            },
            getLocale({isShort} = {}) {
                if (isShort) {
                    return _.get(data, 'mainDialect', data.lang);
                }
                return data.locale;
            }
        };
    }
    sm.systemUser = {
        lang: new Language()
    };

    if ($.mockjaxSettings) {
        $.mockjaxSettings.logging = 0;
    }

    window.URL = window.URL || window.webkitURL;

    _.templateSettings.evaluate = /{{([\s\S]+?)}}/g;
    _.templateSettings.interpolate = /{{=([\s\S]+?)}}/g;
    _.templateSettings.escape = /{{-([\s\S]+?)}}/g;

    if ($.ui && $.ui.dateRangeSlider) {
        $.widget('ui.dateRangeSlider', $.ui.dateRangeSlider, {
            _continueScrolling(action, timeout) {
                if (!this.options.enabled) {
                    return false;
                }

                timeout = 1000 / 8;
                var values = this.values();
                if (action === 'scrollRight') {
                    this.values(
                        moment(values.min)
                            .add(this.options.step)
                            .toDate(),
                        moment(values.max)
                            .add(this.options.step)
                            .toDate()
                    );
                } else if (action === 'scrollLeft') {
                    this.values(
                        moment(values.min)
                            .subtract(this.options.step)
                            .toDate(),
                        moment(values.max)
                            .subtract(this.options.step)
                            .toDate()
                    );
                }

                var that = this;
                this._scrollTimeout = setTimeout(function() {
                    that._continueScrolling(action, timeout);
                }, timeout);
            },

            _stopScroll() {
                $(document).unbind('mouseup touchend', this._stopScrollHandle);
                this._stopScrollHandle = null;
                this._bar('stopScroll');
                clearTimeout(this._scrollTimeout);
                this._valuesChanged = true;
                this._changed();
            }
        });
    }

    if (window.Switchery) {
        window.Switchery.prototype.setChecked = function(isChecked) {
            if ((isChecked && !this.isChecked()) || (!isChecked && this.isChecked())) {
                this.setPosition(true);
                this.handleOnchange(true);
            }
        };
    }

    // вырубаем bootstrap-tooltips
    if (_.get(window.sm, 'isDev')) {
        console.warn('bootstrap-tooltips are disabled');
    }
    $(document).on('show.bs.tooltip', '*', function(e) {
        var $el = $(this);
        $el.attr('title', $el.data('original-title'));
        return false;
    });
})(jQuery);

if ($.fn.dataTable && _.size($.fn.dataTableExt.buttons) > 0) {
    (function() {
        var datatable = $.fn.dataTable;
        $.extend(true, datatable.Buttons.defaults, {
            dom: {
                container: {className: 'dt-buttons btn-group hidden'},
                button: {className: 'btn btn-default'},
                collection: {
                    tag: 'ul',
                    className: 'dt-button-collection dropdown-menu',
                    button: {tag: 'li', className: 'dt-button'},
                    buttonLiner: {tag: 'a', className: ''}
                }
            }
        });
        datatable.ext.buttons.collection.text = function(a) {
            return a.i18n('buttons.collection', 'Collection <span class="caret"/>');
        };
        return datatable.Buttons;
    })();
}

if ($.fn.select2) {
    require(`src/js/lib/select2.i18n.${sm.systemUser.lang.get()}.js`);
    $.fn.select2.amd.require([`select2/i18n/${sm.systemUser.lang.get()}`], function(lang) {
        $.fn.select2.defaults.set('language', lang);
    });

    // @see https://github.com/select2/select2/issues/3354
    $.fn.select2.amd.require(['select2/selection/search'], function(Search) {
        var oldRemoveChoice = Search.prototype.searchRemoveChoice;

        Search.prototype.searchRemoveChoice = function() {
            oldRemoveChoice.apply(this, arguments);
            this.$search.val('');
        };
    });
}

if ($.fn.scrollTo) {
    $.extend($.scrollTo.defaults, {
        axis: 'y',
        duration: 400
    });
}

$.ajaxSetup({cache: false});

// расширение лодаша
_.mixin({
    // compactMap
    cMap(arr, fn) {
        return _.compact(_.map(arr, fn));
    }
});

$(document).on('change', '.fancytree-checkbox-wrapper [type=checkbox]', function(e) {
    if ($(this).closest('.dt-checkboxes-select-all').length) {
        return;
    }
    if ($(this).closest('td').length && $.fn.DataTable.isDataTable($(this).closest('table'))) {
        var datatable = $(this)
            .closest('table')
            .DataTable();
        if (
            datatable.settings()[0].aoColumns[datatable.cell($(this).closest('td')).index().column].checkboxes != null
        ) {
            return;
        }
    }
    if (
        $(this)
            .closest('.fancytree-checkbox-wrapper')
            .is('.vue-fancytree-checkbox-wrapper')
    ) {
        return;
    }

    var isSelected = !!$(this).prop('checked');
    $(this)
        .closest('.fancytree-checkbox-wrapper')
        .toggleClass('fancytree-selected', isSelected);
});

$('body').on('click', function(e) {
    if (
        $('.dropdown.open.stay-opened-after-click').has(e.target).length > 0 &&
        $(e.target).is('[data-toggle="dropdown"]') === false
    ) {
        e.stopPropagation();
    }
});

jQuery.Deferred.exceptionHook = function() {
    if (_.get(arguments, '[0].statusText')) {
        return;
    }
    console.warn(arguments);
};

$.fn.toggleAttr = function(attr, bool) {
    if (bool) {
        return this.attr(attr, attr);
    }
    if (typeof bool !== 'undefined') {
        return this.removeAttr(attr);
    }
    return this.each(function() {
        var $e = $(this);
        if (typeof $e.attr(attr) === 'undefined') {
            $e.attr(attr, attr);
        } else {
            $e.removeAttr(attr);
        }
    });
};

// для sm-*btn*
$(document).off('click.bs.button.data-api', '[data-toggle^="button"]');
